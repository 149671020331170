import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInviteGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log('AuthInviteGuard currentUser..', currentUser);
    if (currentUser && currentUser.jwtToken) {
      if (currentUser.roleName === 'SYSTEM_ADMIN') {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      }
      else if (currentUser.roleName === 'RECEPTIONIST') {
        this.router.navigateByUrl('/receptionist/appointments/appointment-list');
      }
      else if (currentUser.roleName === 'FINANCIAL_COUNSELOR') {
        this.router.navigateByUrl('/financial-counselor');
      }
      else if (currentUser.roleName === 'PATIENT') {
        this.router.navigateByUrl('/patient');
      } else if (currentUser.roleName === 'SPECIALIST_DOCTOR') {
        this.router.navigateByUrl('/doctor');
      }
      else if (currentUser.roleName === 'JUNIOR_DOCTOR') {
        this.router.navigateByUrl('/junior-doctor');
      }
      else if (currentUser.roleName === 'CALL_CENTER_AGENT') {
        this.router.navigateByUrl('/call-center-agent');
      } else {
        const permissionsData = JSON.parse(localStorage.getItem('permissionsData'));
        const permissions = permissionsData.menu;
        const checkRoleExistence = roleParam => permissions.some(({ enumName }) => enumName === roleParam);
        if (checkRoleExistence('ADMIN_MASTERS')) {
          const adminMasterObj = permissions.find(item => item.enumName === 'ADMIN_MASTERS');
          const subMenu = adminMasterObj.subMenu;
          const checkSubMenuExistence = roleParam => subMenu.some(({ enumName }) => enumName === roleParam);
          if (checkSubMenuExistence('DEPARTMENT_MANAGEMENT')) {
            // this.router.navigateByUrl('/admin-master/department');
            this.router.navigateByUrl('/dashboard');
          } else if (checkSubMenuExistence('ROLE_MANAGEMENT')) {
            this.router.navigateByUrl('/system-admin/admin-master/role');
          } else if (checkSubMenuExistence('BRANCH_MANAGEMENT')) {
            this.router.navigateByUrl('/system-admin/admin-master/branch');
          } else if (checkSubMenuExistence('USER_MANAGEMENT')) {
            this.router.navigateByUrl('/system-admin/admin-master/staff');
          }
        } else if (checkRoleExistence('GENERAL_MASTERS')) {
          this.router.navigateByUrl('/system-admin/general-master');
        } else if (checkRoleExistence('DOCTOR_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('SLOT_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('TARIFF_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('SMS_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('APPOINTMENTS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('PATIENTS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('DOCTORS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('SERVICES')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('TELE-CONSULTATION')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('FINANCIAL_FORM')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('RATE_PLANS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('DOCTOR_SCHEDULE')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('SETTINGS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkRoleExistence('MEDICAL_DIAGNOSIS')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        }
        else if (currentUser.roleName === "RECEPTIONIST") {
          this.router.navigateByUrl('/receptionist/appointments/appointment-list');
        }
        else if (currentUser.roleName === "FINANCIAL_COUNSELOR") {
          this.router.navigateByUrl('/financial-counselor');
        }
        else if (currentUser.roleName === 'PATIENT') {
          this.router.navigateByUrl('/patient');
        }else if (currentUser.roleName === "SPECIALIST_DOCTOR") {
          if (currentUser.doctorDepartment.name === "Fertility") {
                this.router.navigateByUrl('/emr-doctor');
            }else{
                    this.router.navigateByUrl('/doctor');
              }
        }
        else if (currentUser.roleName === "JUNIOR_DOCTOR") {
          this.router.navigateByUrl('/junior-doctor');
        } 
        else if (currentUser.roleName === "CALL_CENTER_AGENT") {
          this.router.navigateByUrl('/call-center-agent');
        }
        return false;
      }
      return false;
    }

    return true;
  }

}
