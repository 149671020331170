<app-loader *ngIf="isLoading"></app-loader>
<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form autocomplete="off" #otpForm="ngForm" class="form-signin" (ngSubmit)="onSubmit(otpForm)">
                    <div class="account-logo">
                        <a class="cursor-pointer"><img src="assets/img/logo-hegde.png" alt=""></a>
                    </div>
                    <div class="form-group">
                        <label for="otp">Please enter OTP here</label>
                        <input autocomplete="off" type="text" name="otp" [(ngModel)]="otp" required #otpVal="ngModel" id="otp"
                        [ngClass]="{ 'is-invalid' : forSubmit && otpVal.errors }"
                          class="form-control">
                          <div class="invalid-feedback" *ngIf="forSubmit && otpVal.errors && otpVal.errors.required">
                            <div class="text-danger">
                                OTP is required
                            </div>
                          </div>

                    </div>
                    <div class="form-group text-right">
                        <a class="cursor-pointer optLink" (click)="resendOtp()">
                            <u>Resend OTP</u></a>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Save</button>
                    </div>
                    <div class="text-center register-link">
                        <a (click)="back()">Back to Login page</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>