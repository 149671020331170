import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthTokenInterceptor } from './Interceptors/auth-token.interceptor';
import { LoginComponent } from './login/login.component';
import { SystemAdminComponent } from './system-admin/system-admin.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { OtpComponent } from './otp/otp.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MobileNumberComponent } from './mobile-number/mobile-number.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './service/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { ForgetOtpComponent } from './forget-otp/forget-otp.component';
import { PatientLoginComponent } from './patient-login/patient-login.component';
import { PatientRegisterComponent } from './patient-register/patient-register.component';
import { PatientRegisterOtpComponent } from './patient-register-otp/patient-register-otp.component';
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';
import { GuiestUserDetailsComponent } from './guiest-user-details/guiest-user-details.component';
import { ChatComponent } from './chat/chat.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { LabTechnicianComponent } from './lab-technician/lab-technician.component';

const agoraConfig: AgoraConfig = {
    AppID: 'be36ba52f034478da476a86aa3a39f00',
};

@NgModule({


  declarations: [
    AppComponent,
    LoginComponent,
    OtpComponent,
    ChangePasswordComponent,
    MobileNumberComponent,
    PageNotFoundComponent,
    ForgetOtpComponent,
    PatientLoginComponent,
    PatientRegisterComponent,
    PatientRegisterOtpComponent,
    GuiestUserDetailsComponent,
    ChatComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    LabTechnicianComponent,
    
    
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    CalendarModule,
    HttpClientModule,
    PdfViewerModule,
    NgxIntlTelInputModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxAgoraModule.forRoot(agoraConfig),
    ],
    providers: [MessagingService,AsyncPipe,{ provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true }],
    bootstrap: [AppComponent]
  })
export class AppModule { }
