import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { TopnavbarComponent } from './topnavbar/topnavbar.component';
import { ApplyLeaveComponent } from './apply-leave/apply-leave.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddHolidayComponent } from './add-holiday/add-holiday.component';
import { GlobalChatComponent } from './global-chat/global-chat.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { SafePipe } from 'src/app/Utils/common/safe.pipe';
import { ReplacePipe } from '../Utils/common/replace.pipe';
import { TimeFormatChangePipe } from 'src/app/Utils/common/time-format-change.pipe'
import { WaitedTimeFormatChangePipe } from '../Utils/common/waited-time-format.pipe';
import { MonthFormatChangePipe } from '../Utils/common/month-format-change.pipe'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { StringToDateChangePipe } from '../Utils/common/string-to-date.pipe';

@NgModule({
  declarations: [LoaderComponent, 
    TopnavbarComponent, ApplyLeaveComponent, 
    AddHolidayComponent, GlobalChatComponent, 
    NumbersOnlyDirective, CurrencyFormatDirective,
    SafePipe,ReplacePipe,TimeFormatChangePipe
    ,MonthFormatChangePipe,WaitedTimeFormatChangePipe,StringToDateChangePipe
    
     ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    MultiSelectModule,
    NgxPaginationModule,
    NgxIntlTelInputModule,
  ],
  exports:[LoaderComponent,TopnavbarComponent, RouterModule, NumbersOnlyDirective, CurrencyFormatDirective, SafePipe,StringToDateChangePipe,
  TimeFormatChangePipe,ReplacePipe,MonthFormatChangePipe,WaitedTimeFormatChangePipe,NgMultiSelectDropDownModule,MultiSelectModule,NgxPaginationModule,NgxIntlTelInputModule]
})
export class SharedModule { }
