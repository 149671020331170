import { Component, OnInit } from '@angular/core';
import { UserDetails } from '../modals/user.modal';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

enum UserRole {
  Patient = "PATIENT",
  SystemAdmin = "SYSTEM_ADMIN",
  Doctor = "SPECIALIST_DOCTOR",
  Receptionist = "RECEPTIONIST",
  JuniorDoctor = "JUNIOR_DOCTOR",
  FinancialCounselor = "FINANCIAL_COUNSELOR"
}

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
})
export class PatientLoginComponent implements OnInit {


  userDetails: UserDetails = new UserDetails();

  forSubmit: boolean = false;

  forOtpSubmit: boolean = false;

  /**
   * ---> For Mobile number input with country codes configuration.
   */
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  
  preferredCountries: CountryISO[] = [
    CountryISO.India,
  ];

  /**
   * --> For Loader
   */
  isLoading: boolean = false;

  constructor(private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService) {
    this.userDetails = new UserDetails();
  }

  ngOnInit(): void {
    $('.intl-tel-input').removeClass('border-red');
    $(document).ready(function () { $(document).on('focus', ':input', function () { $(this).attr('autocomplete', 'off'); }); })
  }

  onEnterEmail(event) {
    let enteredEmail = event.target.value;
    this.userDetails.j_username = enteredEmail ? enteredEmail.trim() : '';
  }

  onEnterPassword(event) {

  }

  onSubmit(form) {
    this.forSubmit = true;
    if (this.userDetails.j_username == null || this.userDetails.j_username == '' || this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
      return;
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }

    if (form.form.controls.password.valid) {
      if (this.userDetails.j_username && this.userDetails.j_password) {
        let spacesObj: any = { ...this.userDetails };

        let code = spacesObj.j_username.dialCode;
        let numberFlag: string = spacesObj.j_username.number.trim();
        numberFlag = numberFlag.replace(/\s/g, "");

        let data = {
          j_username: code + ' ' + numberFlag,
          j_password: this.userDetails.j_password,
          registeredToken: localStorage.getItem('registeredToken') ? localStorage.getItem('registeredToken') : "",
          deviceType: "WEB"
        }

        this.isLoading = true;
        this.apiService.postService(this.apiService.apiUrls.login, data).subscribe((response: any) => {
          console.log("response is logi n :", response.body.data.otp);

          if (response && response.statusCodeValue == 200) {
            this.forSubmit = false;

            if (JSON.parse(localStorage.getItem('otpVerified'))) {
              localStorage.removeItem('otpVerified');
            }
            if (JSON.parse(localStorage.getItem('userId'))) {
              localStorage.removeItem('userId');
            }

            let branches = [];
            if (response.body.data.branches && response.body.data.branches.length > 0) {
              for (let i = 0; i < response.body.data.branches.length; i++) {
                let obj = {
                  id: response.body.data.branches[i],
                  value: response.body.data.branchNames[i]
                }
                branches.push(obj);
              }
            }
            let data = {
              username: response.body.data.email,
              jwtToken: response.body.data.jwtToken,
              id: response.body.data.id,
              role: response.body.data.role,
              roleName: response.body.data.roleName,
              primaryNumber: response.body.data.primaryNumber,
              hasPartnerDetails: response.body.data.hasPartnerDetails,
              branches: branches,
              primaryBranch: response.body.data.primaryBranch,
              branchIdForReception: response.body.data.primaryBranch ? response.body.data.primaryBranch : null,
              firstName: response.body.data.firstName || '',
              lastName: response.body.data.lastName || ''
            }
            localStorage.setItem('currentUser', JSON.stringify(data));

            // get permissions
            this.apiService.getService(`${this.apiService.apiUrls.getPermissions}/${response.body.data.role}`).subscribe(response => {
              //  console.log('********************************** ',response.data);
              localStorage.setItem('permissionsData', JSON.stringify(response.data));
            }, (error) => {
              console.log("error", error);
              this.isLoading = false;
              this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
            });


            this.apiService.getService(this.apiService.apiUrls.getBrandFile).subscribe((resp: any) => {
              // console.log("---->file re : ",resp);
              this.isLoading = false;
              if (resp && resp.statusCode === 200) {
                let brandImageUrl = this.apiService.baseUrl + resp.data.filePerviewUrl
                localStorage.setItem('brand', JSON.stringify(brandImageUrl));
              }
            }, (error) => {
              console.log("fil error", error);
              this.isLoading = false;
              this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
            });

            if (response.body.data.roleName === UserRole.SystemAdmin) {
              this.router.navigateByUrl('/system-admin/admin-master/branch');
            } else if (response.body.data.roleName === UserRole.Patient) {
              this.router.navigateByUrl('/patient');
            } else if (response.body.data.roleName === UserRole.Doctor) {
              this.router.navigateByUrl('/doctor');
            } else if (response.body.data.roleName === UserRole.Receptionist) {
              let modalObj = {
                forModal: true
              }
              let newObj = { ...data, ...modalObj }
              localStorage.setItem('currentUser', JSON.stringify(newObj));
              this.router.navigate(['/receptionist/appointments/appointment-list']);
            } else if (response.body.data.roleName === UserRole.JuniorDoctor) {
              this.router.navigateByUrl('/junior-doctor/appointments');
            } else if (response.body.data.roleName === UserRole.FinancialCounselor) {
              let modalObj = {
                forModal: true
              }
              let newObj = { ...data, ...modalObj }
              localStorage.setItem('currentUser', JSON.stringify(newObj));
              this.router.navigateByUrl('/financial-counselor');
            }
            else {
              this.toastr.success(response.body.data.roleName);
            }

          }
          this.isLoading = false;
        }, (error) => {
          console.log("error is : ", error);
          this.isLoading = false;
          this.toastr.error(error.error ? (error.error.body.message ? error.error.body.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
        })
      }
    }



  }

  select(event) {
    if (this.userDetails.j_username == null || this.userDetails.j_username == '' || this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }
  }

  /**
   * --> Forget password navigation
   */
  forgetPassword() {
    this.router.navigateByUrl('/mobile-number?type=forget');
  }

  /**
   * _> Login with OTP navigation
   */
  logInWithOtp() {
    this.router.navigateByUrl('/mobile-number');
  }

  /**
   * --> Patient Register navigation
   */
  register() {
    this.router.navigateByUrl('/patient-register');
  }

}
