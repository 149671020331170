import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { UserDetails } from '../modals/user.modal';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';

declare var $: any;

@Component({
  selector: 'app-mobile-number',
  templateUrl: './mobile-number.component.html',
})
export class MobileNumberComponent implements OnInit {

  mobileNumber: any;

  userDetails: UserDetails = new UserDetails();

  isLoading: boolean = false;
  forSubmit: boolean = false;

  forGetPasswordFlag: boolean = false;

  /**
   * ---> For Mobile number input with country codes configuration.
   */
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  
  preferredCountries: CountryISO[] = [
    CountryISO.India,
  ];


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    let data = this.activatedRoute.snapshot.queryParams['type'];
    console.log('data : ', data);
    if (data && data == 'forget') {
      this.forGetPasswordFlag = true;
    }
    else {
      this.forGetPasswordFlag = false;
    }

  }



  back() {
    this.router.navigateByUrl('/');
  }

  select(event) {
    // console.log("country -->",event.target.value);
    if (this.userDetails.j_username == null || this.userDetails.j_username == '' || this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }
  }


  onSubmit() {
    console.log('called');

    this.forSubmit = true;
    if (this.userDetails.j_username == null ||
      this.userDetails.j_username == '' ||
      this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
      return;
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }
    this.isLoading = true;
    if (this.userDetails.j_username) {

      let data;
      let spacesObj: any = { ...this.userDetails };

      data = spacesObj.j_username.dialCode + ' ' + spacesObj.j_username.number;
      let temp = {
        mobileNumber: data
      }

      this.apiService.postService(this.apiService.apiUrls.sendOtp, temp).subscribe((response: any) => {
        if (response) {
          this.forSubmit = false;
          console.log('resposne otp : ', response);
          this.toastr.success(response.message);
          let tempData = (btoa(JSON.stringify(spacesObj.j_username.dialCode + ' ' + spacesObj.j_username.number)));
          if (JSON.parse(localStorage.getItem('userId'))) {
            localStorage.removeItem('userId');
            localStorage.setItem('userId', JSON.stringify(tempData));
          }
          else {
            localStorage.setItem('userId', JSON.stringify(tempData));
          }
          if (!this.forGetPasswordFlag) {
            this.router.navigateByUrl('/otp');
          }
          else {
            this.router.navigateByUrl('/forget/otp');
          }
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })
    }

  }

  loginWithPassword() {
    this.router.navigateByUrl('/');
  }

}
