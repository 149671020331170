<div class="content">
    <div class="row">
        <div class="col-lg-12 text-left">
            <a class="back-button text-grey" [routerLink]="['/receptionist/doctors/holiday']" >
                <i class="fa fa-chevron-left"></i> Back</a>
        </div>
        <div class="col-lg-8 offset-lg-2">
            <h4 class="page-title">Add Holiday</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <form>
                <div class="form-group">
                    <label>Holiday Name <span class="text-danger">*</span></label>
                    <input class="form-control" type="text">
                </div>
                <div class="form-group">
                    <label>Holiday Date <span class="text-danger">*</span></label>
                    <div class="cal-icon">
                        <div class="cal-icon">
                            <p-calendar  dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="{{fromFromYear}}:{{lastFromYear}}"></p-calendar>
                        </div>
                    </div>
                </div>
                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn">Create Holiday</button>
                </div>
            </form>
        </div>
    </div>
</div>