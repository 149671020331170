import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
   transform(value: string): any {
     if(!value){
      return "";
     }
    return value.replace(/_/g, " ");
  }
  }
