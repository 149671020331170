import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'convertWaitedTimeFormat'})
export class WaitedTimeFormatChangePipe implements PipeTransform {
  transform(value: any,status:any): any {
    if (!value) {
      return '--';
    }

    if(status==='ON_GOING'){
      return '--';
    }
    let format="";
    let hours = parseInt((value.split(':'))[0])
    let minutes = parseInt((value.split(':'))[1])
    
    if(hours==0){
      if(minutes<10){
        format="0"+minutes+ " Mins";
      }else{
        format=minutes+ " Mins";
      }
    }else if(hours<10){
      if(minutes<10){
        format="0"+hours+":0"+minutes+ " Hrs";
      }else{
        format="0"+hours+":"+minutes+ " Hrs";
      }
    }else{
      if(minutes<10){
        format=hours+":0"+minutes+ " Hrs";
      }else{
        format=hours+":"+minutes+ " Hrs";
      }
    }
    return format;
  }
}