import { Component, OnInit } from '@angular/core';
import { UserDetails } from '../modals/user.modal';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { FileDto } from '../modals/AdminMaster/FileDto/file.modal';

declare var $: any;

enum UserRole {
  Patient = "PATIENT",
  SystemAdmin = "SYSTEM_ADMIN",
  Doctor = "SPECIALIST_DOCTOR",
  EmrDoctor = "Fertility",
  Receptionist = "RECEPTIONIST",
  JuniorDoctor = "JUNIOR_DOCTOR",
  FinancialCounselor = "FINANCIAL_COUNSELOR",
  CallCenterAgent = 'CALL_CENTER_AGENT',
  OtManager = 'OT_MANAGER',
  Nurse = 'NURSE',
  EmrJuniorDoctor = 'JUNIOR_DOCTOR',
  LabTechAndrology = 'LABTECH_ANDROLOGY',
  LabTechAmbryology = 'LABTECH_EMBRYOLOGY',
  RefDoctor = 'REFERRAL_DOCTOR',
  LabTechnician = 'LAB_TECHNICIAN'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  userDetails: UserDetails = new UserDetails();

  forSubmit: boolean = false;
  fileDataDTO = new FileDto();

  forOtpSubmit: boolean = false;
  permissionsData: any;


  /**
   * ---> For Mobile number input with country codes configuration.
   */
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;


  preferredCountries: CountryISO[] = [
    CountryISO.India,
  ];

  /**
   * --> For Loader
   */
  isLoading: boolean = false;

  constructor(private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService) {
    this.userDetails = new UserDetails();
  }

  ngOnInit(): void {
    $('.intl-tel-input').removeClass('border-red');
    $(document).ready(function () { $(document).on('focus', ':input', function () { $(this).attr('autocomplete', 'off'); }); })
  }

  onEnterEmail(event) {
    let enteredEmail = event.target.value;
    this.userDetails.j_username = enteredEmail ? enteredEmail.trim() : '';
  }

  onEnterPassword(event) {

  }

  onSubmit(form) {

    this.forSubmit = true;

    if (this.userDetails.j_username == null || this.userDetails.j_username == '' || this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
      return;
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }


    if (form.form.controls.password.valid) {
      if (this.userDetails.j_username && this.userDetails.j_password) {
        let spacesObj: any = { ...this.userDetails };

        let code = spacesObj.j_username.dialCode;
        let numberFlag: string = spacesObj.j_username.number.trim();
        numberFlag = numberFlag.replace(/\s/g, "");

        let data = {
          j_username: code + ' ' + numberFlag,
          j_password: this.userDetails.j_password,
          registeredToken: localStorage.getItem('registeredToken') ? localStorage.getItem('registeredToken') : "",
          deviceType: "WEB"
        }


        this.apiService.postService(this.apiService.apiUrls.login, data).subscribe((response: any) => {
          if (response && response.statusCodeValue == 200) {
            this.forSubmit = false;
            if (JSON.parse(localStorage.getItem('otpVerified'))) {
              localStorage.removeItem('otpVerified');
            }
            if (JSON.parse(localStorage.getItem('userId'))) {
              localStorage.removeItem('userId');
            }

            let branches = [];
            if (response.body.data.branches && response.body.data.branches.length > 0) {
              for (let i = 0; i < response.body.data.branches.length; i++) {
                let obj = {
                  id: response.body.data.branches[i],
                  value: response.body.data.branchNames[i]
                }
                branches.push(obj);
              }
            }
            let data = {
              username: response.body.data.email,
              jwtToken: response.body.data.jwtToken,
              id: response.body.data.id,
              role: response.body.data.role,
              roleName: response.body.data.roleName,
              primaryNumber: response.body.data.primaryNumber,
              hasPartnerDetails: response.body.data.hasPartnerDetails,
              branches: branches,
              primaryBranch: response.body.data.primaryBranch,
              branchIdForReception: response.body.data.primaryBranch ? response.body.data.primaryBranch : null,
              firstName: response.body.data.firstName || '',
              lastName: response.body.data.lastName || '',
              filePerviewUrl: (response.body.data && response.body.data.fileDataDTO && response.body.data.fileDataDTO.filePerviewUrl) ? this.apiService.baseUrl + response.body.data.fileDataDTO.filePerviewUrl : '',
              doctorDepartment: response.body.data.doctorDepartment || ''
            }
            localStorage.setItem('currentUser', JSON.stringify(data));


            // get permissions
            this.apiService.getService(`${this.apiService.apiUrls.getPermissions}/${response.body.data.role}`).subscribe(response => {
              if (response.statusCode === 200) {

                localStorage.setItem('permissionsData', JSON.stringify(response.data));
                this.permissionsData = response.data;
                this.forNavigation(response, data);
                this.getBrandFile();

              }
            }, (error) => {
              this.isLoading = false;
              this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
            });
          }
        }, (error) => {
          this.toastr.error(error.error ? (error.error.body.message ? error.error.body.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
        })
      }
    }

  }

  getBrandFile() {
    this.apiService.getService(this.apiService.apiUrls.getBrandFile).subscribe((resp: any) => {
      this.isLoading = false;
      if (resp && resp.statusCode === 200) {
        const brandImageUrl = this.apiService.baseUrl + resp.data.filePerviewUrl;
        localStorage.setItem('brand', JSON.stringify(brandImageUrl));
      }
    }, (error) => {
      this.isLoading = false;
      this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
    });
  }


  forNavigation(response, data) {
    if (response.data.roleName === UserRole.SystemAdmin) {
      this.router.navigateByUrl('/system-admin/admin-master/branch');
    } else if (response.data.roleName === UserRole.Patient) {
      this.router.navigateByUrl('/patient');
    } else if (response.data.roleName === UserRole.Doctor) {
      if (data.doctorDepartment.name == UserRole.EmrDoctor) {
        this.router.navigateByUrl('/emr-doctor');
      } else {
        this.router.navigateByUrl('/doctor');
      }
    } else if (response.data.roleName === UserRole.Receptionist) {
      let modalObj = {
        forModal: true
      }
      let newObj = { ...data, ...modalObj }
      localStorage.setItem('currentUser', JSON.stringify(newObj));
      this.router.navigate(['/receptionist/appointments/appointment-list']);
    } else if (response.data.roleName === UserRole.JuniorDoctor) {
      if (data.doctorDepartment.name == UserRole.EmrDoctor) {
        this.router.navigateByUrl('/emr-junior-doctor')

      } else {
        this.router.navigateByUrl('/junior-doctor/appointments');
      }

    } else if (response.data.roleName === UserRole.FinancialCounselor) {
      let modalObj = {
        forModal: true
      }
      let newObj = { ...data, ...modalObj }
      localStorage.setItem('currentUser', JSON.stringify(newObj));
      this.router.navigateByUrl('/financial-counselor');
    } else if (response.data.roleName == UserRole.CallCenterAgent) {
      this.router.navigateByUrl("/call-center-agent");
    }
    else if (response.data.roleName == UserRole.Nurse) {
      this.router.navigateByUrl("/nurse");
    }

    else if (response.data.roleName == UserRole.RefDoctor) {
      this.router.navigateByUrl("/emr-ref-doctor");
    }

    else if (response.data.roleName == UserRole.LabTechAndrology) {
      this.router.navigateByUrl("/labtech-andro");
    }
    else if (response.data.roleName == UserRole.LabTechAmbryology) {
      this.router.navigateByUrl("/labtech-embryo");
    }
    else if (response.data.roleName == UserRole.LabTechnician) {
      this.router.navigateByUrl("/labtech");
    }



    else if (response.data.roleName == UserRole.OtManager) {
      this.router.navigateByUrl("/ot-manager");
    }

    else {

      const permissions = this.permissionsData.menu;
      const checkRoleExistence = roleParam => permissions.some(({ enumName }) => enumName === roleParam);
      if (checkRoleExistence('ADMIN_MASTERS')) {
        const adminMasterObj = permissions.find(item => item.enumName === 'ADMIN_MASTERS');
        const subMenu = adminMasterObj.subMenu;
        const checkSubMenuExistence = roleParam => subMenu.some(({ enumName }) => enumName === roleParam);
        if (checkSubMenuExistence('DEPARTMENT_MANAGEMENT')) {
          // this.router.navigateByUrl('/admin-master/department');
          this.router.navigateByUrl('/dashboard');
        } else if (checkSubMenuExistence('ROLE_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/role');
        } else if (checkSubMenuExistence('BRANCH_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkSubMenuExistence('USER_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/staff');
        }
      } else if (checkRoleExistence('GENERAL_MASTERS')) {
        this.router.navigateByUrl('/system-admin/general-master');
      } else if (checkRoleExistence('DOCTOR_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SLOT_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('TARIFF_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SMS_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('APPOINTMENTS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('PATIENTS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('DOCTORS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SERVICES')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('TELE-CONSULTATION')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('FINANCIAL_FORM')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('RATE_PLANS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('DOCTOR_SCHEDULE')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SETTINGS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('MEDICAL_DIAGNOSIS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      }
      this.toastr.success(response.data.roleName);
    }


  }

  select(event) {
    if (this.userDetails.j_username == null || this.userDetails.j_username == '' || this.userDetails.j_username == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
    }
    else {
      if ($('.intl-tel-input').hasClass('border-red')) {
        $('.intl-tel-input').removeClass('border-red');
      }
    }
  }

  /**
   * --> For forget password navigation
   */
  forgetPassword() {
    this.router.navigateByUrl('/mobile-number?type=forget');
  }

  /**
   * --> log in with OTP navigation
   */
  logInWithOtp() {
    this.router.navigateByUrl('/mobile-number');
  }

  /**
   * -> Patient Register navigation
   */
  patientLogin() {
    this.router.navigateByUrl('/patient-login')
  }

  /**\
   * For Brand image logo
   */
  getFileById() {
    this.isLoading = true;
    this.apiService.getService(this.apiService.apiUrls.getBrandFile).subscribe((response: any) => {
      if (response && response.statusCode === 200) {
        let str = '';
        if (response.data) {
          this.fileDataDTO = response.data;
          str = response.data ? "data:" + response.data.contentType + ";base64," : null;
        }

        let image = response.data ? str + response.data.bytes : null;
        localStorage.setItem('brand', JSON.stringify(image));
      }
      this.isLoading = false;
    }, (error) => {

      this.isLoading = false;
    });
  }


}
