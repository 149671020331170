import { Component, OnInit } from '@angular/core';
import { ForgotPassword } from '../modals/ChangePassword/change-password.modal';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  password:any;
  confirmPassword:any;

  forSubmit: boolean = false;

  isLoading: boolean = false;
  type: boolean = false;

  forgotPasswordObj: ForgotPassword = new ForgotPassword();


  constructor(private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) { 

    }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(data => {
      console.log("data---->",data);
      
      if(data && data.type == 'call-center') {
        this.type = true;
      } else {
        this.type = false;
      }
    })
    if(localStorage.getItem("userId")) {
      let userId = JSON.parse(atob(JSON.parse(localStorage.getItem("userId"))));
      if(userId) {}
    }
  }

  onSubmit(form) {
    this.forSubmit = true;
    if(form.form.valid) {
      let userId = JSON.parse(atob(JSON.parse(localStorage.getItem("userId"))));
      this.forgotPasswordObj.mobileNumber = userId;
      let pass = this.forgotPasswordObj.newPassword.trim();
      let confirmPass = this.forgotPasswordObj.confirmPassword.trim();
      if(pass != confirmPass) {
        this.toastr.error("Password and Confirm password did not match");
        return;
      }
      else {
        this.isLoading = true;
        this.apiService.postService(this.apiService.apiUrls.forgetPassword, this.forgotPasswordObj).subscribe((response: any) => {
          console.log("response is logi n :", response);
          
          if (response && response.statusCode == 200) {
            this.forSubmit = false;
            this.toastr.success(response.message);
            this.router.navigateByUrl('/');
          }
          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;
          this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
        })
      }
    }
  }

  back() {
    this.router.navigateByUrl('/');
  }

  cancel() {
    this.router.navigateByUrl('call-center-agent');
  }
}

