<app-loader *ngIf="isLoading"></app-loader>
<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form autocomplete="off" #loginForm="ngForm" class="form-signin" (ngSubmit)="onSubmit(loginForm)">
                    <div class="account-logo">
                        <a class="cursor-pointer"><img src="assets/img/logo-hegde.png" alt=""></a>
                       
                    </div>
                    <div class="form-group ">
                        <h6>Patient Login</h6>
                        <label for="mobile">Mobile Number</label>
                        <ngx-intl-tel-input [cssClass]="'custom form-control'" [separateDialCode] = "true" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="false"  [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                            id="mobile" (keyup)="select($event)" name="phone" [(ngModel)]='userDetails.j_username'>
                        </ngx-intl-tel-input>
                        <div class="custom-invalid-feedback">
                            <div class="text-danger"
                                *ngIf="(forSubmit  && (userDetails.j_username == null || userDetails.j_username == '')) ||
                                (forOtpSubmit && (userDetails.j_username == null || userDetails.j_username == ''))">
                                Mobile number is required
                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input autocomplete="off" type="password" name="password" id="password" required minlength="6"
                            (change)="onEnterPassword($event)" #passwordValidation="ngModel"
                            [ngClass]="{ 'is-invalid': (passwordValidation.errors) && forSubmit }"
                            [(ngModel)]="userDetails.j_password" class="form-control">
                        <div class="invalid-feedback">
                            <div class="text-danger"
                                *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.required">
                                Password is required
                            </div>
                            <div class="text-danger"
                                *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.minlength">
                                Password should be atleast 6 characters minimum
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-right">
                        <a class="hyper-link" (click)="forgetPassword()">Forgot your password?</a>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Login with Password</button>
                        <p class="text-center mt-3">Or</p>
                        <button type="button" class="btn btn-primary account-btn" (click)="logInWithOtp()">Login with OTP</button>
                    </div>
                    <div class="text-center register-link">
                        Don't have an account? <a class="cursor-pointer hyper-link" (click)="register()">Register Now</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>