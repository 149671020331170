import { Component } from '@angular/core';
import { MessagingService } from './service/messaging.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Hegde-UI';
  constructor(private messagingService: MessagingService) { }
  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.messagingService.currentMessage.subscribe(
      (notification) => {
        let msg = notification?.notification?.body;
       
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }


}
