import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forget-otp',
  templateUrl: './forget-otp.component.html',
  styleUrls: ['./forget-otp.component.scss']
})
export class ForgetOtpComponent implements OnInit {

  otp:any;
  userId:any;


  isLoading: boolean = false;
  forSubmit: boolean = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private apiService: ApiService) { }

  ngOnInit(): void {
    let userId = JSON.parse(atob(JSON.parse(localStorage.getItem("userId"))));
    if(userId) {
      this.userId = userId;
    }
    // this.activatedRoute.queryParams.subscribe((data: any) => {
    //   console.log("data : ",data);
    //   if(data.userId) {
    //     this.userId = JSON.parse(atob(data.userId))
    //   }
    // })
  }

  /**
   * -> For resend OTP 
   */
  resendOtp() {
    this.isLoading = true;
    if(this.userId) {

      let temp = {
        mobileNumber: this.userId
      }
      
      this.apiService.postService(this.apiService.apiUrls.resendOtp, temp).subscribe((response: any) => {
        if(response) {
          this.forSubmit = false;
          this.toastr.success(response.message);
        }
        this.isLoading = false;
      },(error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })
    }
  }

  onSubmit(form) {
    console.log("form ",form);
    // this.router.navigateByUrl('/system-admin/admin-master/branch');

    this.forSubmit = true;
    if(form.form.valid) {

      this.isLoading = true;
      let data  = {
        userId: this.userId,
        otp: this.otp,
        fromForgotPassword: true,
        registeredToken: localStorage.getItem('registeredToken') ? localStorage.getItem('registeredToken'):"", 
        deviceType:"WEB"
      }
      this.apiService.postService(this.apiService.apiUrls.validateOtp, data).subscribe((response: any) => {
        console.log("response is logi n :", response);
        
        if (response && response.statusCodeValue == 200) {
          this.forSubmit = false;
          this.toastr.success(response.message ? response.message : 'OTP verified successfully', "Success");
          let flag:boolean = true;
          if(JSON.parse(localStorage.getItem('otpVerified'))) {
            localStorage.removeItem('otpVerified');
            localStorage.setItem('otpVerified',JSON.stringify(flag));
          } else {
            localStorage.setItem('otpVerified',JSON.stringify(flag));
          }
          this.router.navigateByUrl('forgot-password');


        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })
    }
  }

  back() {
    this.router.navigateByUrl('/')
  }


}
