import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Utils/common/common.service';

@Component({
  selector: 'app-global-chat',
  templateUrl: './global-chat.component.html',
  styleUrls: ['./global-chat.component.scss']
})
export class GlobalChatComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  logout() {
    this.commonService.logout();
   }
}
