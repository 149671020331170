import { FileDto } from '../AdminMaster/FileDto/file.modal';
import { Patient } from '../patient/patient.modal';

export class 
AppointmentViewDetailsDto {
    appointmentBookedDate?:string =  "";
    appointmentFiles?: FileDto[] = [];
    appointmentStatus?:string =  "";
    appointmentType?:string =  "";
    branchId?:any =  null;
    branchName?:string = "";
    departmentId?:any =  null;
    departmentName?:string =  "";
    doctorId?:any = null;
    doctorName?:string =  "";
    consultationPrice?:any = null;
    endTime?:string =  "";
    id?:any = null;
    patientCity?: string =  "";
    patientEmail?:string =  "";
    patientGender?:string = "";
    patientId?:any = null;
    patientMobileNumber?:string =  "";
    patientName?:string =  "";
    paymentStatus?:string =  "";
    patient?: Patient = new Patient();
    slotDate?:string =  "";
    slotId?:any = null;
    slotShift?:string =  "";
    startTime?:string = "";
    teleConsultationPrice?: any = null;
}