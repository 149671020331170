<app-loader *ngIf="isLoading"></app-loader>
<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form autocomplete="off" #mobileNumberForm="ngForm" class="form-signin" (ngSubmit)="onSubmit()">
                    <div class="account-logo">
                        <a class="cursor-pointer"><img src="assets/img/logo-hegde.png" alt=""></a>
                    </div>
                    <div class="form-group">
                        <label for="mobile">Mobile Number</label>
                        <ngx-intl-tel-input [cssClass]="'custom form-control'" [separateDialCode]="true"
                            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="false" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                            id="mobile" (keyup)="select($event)" name="phone" [(ngModel)]='userDetails.j_username'>
                        </ngx-intl-tel-input>
                        <div class="custom-invalid-feedback">
                            <div class="text-danger" *ngIf="(forSubmit  && (userDetails.j_username == null || userDetails.j_username == ''))
                                ">
                                Mobile number is required
                            </div>

                        </div>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Send OTP</button>
                        <p class="text-center mt-3">Or</p>
                        <button type="button" (click)="loginWithPassword()" class="btn btn-primary account-btn">Login
                            with Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>