export class ChangePassword {
    newPassword?: string = '';
    confirmPassword?: string = '';
}

export class ForgotPassword{
    confirmPassword: string = '';
    mobileNumber: string = '';
    newPassword: string = '';
      
}