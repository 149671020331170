import { NgModule, ɵisDefaultChangeDetectionStrategy } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemAdminModule } from './system-admin/system-admin.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { SystemAdminComponent } from './system-admin/system-admin.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { OtpComponent } from './otp/otp.component';
import { DoctorManagementModule } from './system-admin/doctor-management/doctor-management.module';
import { MobileNumberComponent } from './mobile-number/mobile-number.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { AdminGuard } from './guards/admin-guard/admin.guard';
import { AuthInviteGuard } from './guards/auth-invite-guard/auth-invite.guard';
import { PatientGuard } from './guards/patient-guard/patient.guard';
import { DoctorGuard } from './guards/doctor-guard/doctor.guard';
import { EmrDoctorGuard } from './guards/emr-doctor-guard/emr-doctor.guard';

import { ReceptionistguardService } from './guards/receptionist-guard/receptionistguard.service';
import { OtpGuardService } from './guards/otp-guard/otp-guard.service';
import { JuniorDoctorGuardService } from './guards/junior-doctor-guard/junior-doctor-guard.service';
import { ForgetOtpComponent } from './forget-otp/forget-otp.component';
import { ChangePasswordService } from './guards/change-password-guard/change-password.service';
import { PatientLoginComponent } from './patient-login/patient-login.component';
import { AuthInvitePatientGuardService } from './guards/auth-invite-patient/auth-invite-patient-guard.service';
import { PatientRegisterComponent } from './patient-register/patient-register.component';
import { PatientRegisterOtpComponent } from './patient-register-otp/patient-register-otp.component';
import { FinancialGuardService } from './guards/financial-guard/financial-guard.service';
import { CallCenterGuardService } from './guards/call-center-agent/call-center-guard.service';
import { GuiestUserDetailsComponent } from './guiest-user-details/guiest-user-details.component';
import { ChatComponent } from './chat/chat.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtManagerGuardService } from './guards/ot-manager/ot-manager-guard.service';
import { NurseGuardService } from './guards/nurse-guard/nurse-guard.service';
import { EmrJuniorDoctorGuard } from './guards/emr-junior-doctor-guard/emr-junior-doctor.guard';
import { LabtechAndrologyGuardService } from './guards/labtech-andro-guard/labtech-andro-guard.service';
import { RefDoctorGuardService } from './guards/ref-doctor-guard/ref-doctor-guard.service';
import { LabTechnicianGuardService } from './guards/lab-technician-guard/lab-technician-guard.service';
import { LabtechAmbryologyGuardService } from './guards/labtech-ambryo-guard/labtech-ambryo-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthInviteGuard],
  },
  {
    path: 'patient-login',
    component: PatientLoginComponent,
    canActivate: [AuthInvitePatientGuardService],
  },
  {
    path: 'otp',
    component: OtpComponent,
    canActivate: [OtpGuardService],
  },
  {
    path: 'forget/otp',
    component: ForgetOtpComponent,
    canActivate: [OtpGuardService],
  },
  {
    path: 'mobile-number',
    component: MobileNumberComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [ChangePasswordService],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [ChangePasswordService],
  },
  {
    path: 'patient-register',
    component: PatientRegisterComponent,
  },
  {
    path: 'register/otp',
    component: PatientRegisterOtpComponent,
  },
  {
    path: 'system-admin',
    loadChildren: () =>
      import('src/app/system-admin/system-admin.module').then(
        (m) => m.SystemAdminModule
      ),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'admin-master',
    loadChildren: () =>
      import('src/app/system-admin/admin-master/admin-master.module').then(
        (m) => m.AdminMasterModule
      ),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'receptionist',
    loadChildren: () =>
      import('src/app/receptionist/receptionist.module').then(
        (m) => m.ReceptionistModule
      ),
    canActivate: [AuthGuard, ReceptionistguardService],
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('src/app/patient/patient.module').then((m) => m.PatientModule),
    canActivate: [AuthGuard, PatientGuard],
  },
  {
    path: 'emr-doctor',
    loadChildren: () =>
      import('src/app/emr-doctor/emr-doctor.module').then(
        (m) => m.EmrDoctorModule
      ),
    canActivate: [AuthGuard, EmrDoctorGuard],
  },
  {
    path: 'nurse',
    loadChildren: () =>
      import('src/app/nurse/nurse.module').then((m) => m.NurseModule),
    canActivate: [AuthGuard, NurseGuardService],
  },

  {
    path: 'emr-ref-doctor',
    loadChildren: () =>
      import('src/app/emr-ref-doctor/emr-ref-doctor.module').then(
        (m) => m.EmrRefDoctorModule
      ),
    canActivate: [AuthGuard, RefDoctorGuardService],
  },

  {
    path: 'labtech-andro',
    loadChildren: () =>
      import('src/app/labtech-andro/labtech-andro.module').then(
        (m) => m.LabtechAndroModule
      ),
    canActivate: [AuthGuard, LabtechAndrologyGuardService],
  },
  {
    path: 'labtech',
    loadChildren: () =>
      import('src/app/lab-technician/lab-technician.module').then(
        (m) => m.LabTechnicianModule
      ),
    canActivate: [AuthGuard, LabTechnicianGuardService],
  },

  {
    path: 'labtech-embryo',
    loadChildren: () =>
      import('src/app/labtech-ambryo/labtech-ambryo.module').then(
        (m) => m.LabtechAmbryoModule
      ),
    canActivate: [AuthGuard, LabtechAmbryologyGuardService],
  },

  {
    path: 'doctor',
    loadChildren: () =>
      import('src/app/doctor/doctor.module').then((m) => m.DoctorModule),
    canActivate: [AuthGuard, DoctorGuard],
  },
  {
    path: 'junior-doctor',
    loadChildren: () =>
      import('src/app/junior-doctor/junior-doctor.module').then(
        (m) => m.JuniorDoctorModule
      ),
    canActivate: [AuthGuard, JuniorDoctorGuardService],
  },

  {
    path: 'emr-junior-doctor',
    loadChildren: () =>
      import('src/app/emr-junior-doctor/emr-junior-doctor.module').then(
        (m) => m.EmrJuniorDoctorModule
      ),
    canActivate: [AuthGuard, EmrJuniorDoctorGuard],
  },
  {
    path: 'financial-counselor',
    loadChildren: () =>
      import('src/app/financial-counselor/financial-counselor.module').then(
        (m) => m.FinancialCounselorModule
      ),
    canActivate: [AuthGuard, FinancialGuardService],
  },
  {
    path: 'call-center-agent',
    loadChildren: () =>
      import('src/app/call-center-agent/call-center-agent.module').then(
        (m) => m.CallCenterAgentModule
      ),
    canActivate: [AuthGuard, CallCenterGuardService],
  },
  {
    path: 'guest-user-details',
    component: GuiestUserDetailsComponent,
  },
  //Creating an alias for a shortened URL for the SMS template to work
  //ToDo : Fix the spelling of component
  {
    path: 'gd',
    component: GuiestUserDetailsComponent,
  },
  {
    path: 'widget',
    loadChildren: () =>
      import('src/app/widget/widget.module').then((m) => m.WidgetModule),
  },
  {
    path: 'token/:id',

    loadChildren: () =>
      import('src/app/token-display/token-display.module').then(
        (m) => m.TokenDisplaytModule
      ),
  },
  {
    path: 'chat',
    component: ChatComponent,
  },
  /** Role Management Implementation. Don't use this routes for general implementation */
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'admin',
        loadChildren: () =>
          import('src/app/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'sms-manage',
        loadChildren: () =>
          import('src/app/sms-management/sms-management.module').then(
            (m) => m.SmsManagementModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: 'ot-manager',
    loadChildren: () =>
      import('src/app/ot-manager/ot-manager.module').then(
        (m) => m.OtManagerModule
      ),
    canActivate: [AuthGuard, OtManagerGuardService],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
