import { Component, OnInit } from '@angular/core';
import { PatientRegister } from '../modals/patient-register/patient-register.modal';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
})
export class PatientRegisterComponent implements OnInit {

  patientObj: PatientRegister = new PatientRegister();

  forMatch: boolean = false;
  forSubmit: boolean = false;
  isLoading: boolean = false;
  validateNumber: boolean = false;

  mobileNumber = null;

  /**
   * ---> For Mobile number input with country codes configuration.
   */
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  
  preferredCountries: CountryISO[] = [
    CountryISO.India,
  ];

  constructor(private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    $('.intl-tel-input').removeClass('border-red');
    $(document).ready(function () { $(document).on('focus', ':input', function () { $(this).attr('autocomplete', 'off'); }); })
  }

  /**
   * --> For Login navigation
   */
  login() {
    this.router.navigateByUrl('/')
  }

  /**
   * 
   * --> For email spaces trim
   */
  onEnterEmail(event) {
    if(event) {
      let enteredEmail = event.target.value;
      this.patientObj.email = enteredEmail ? enteredEmail.trim() : '';
      this.patientObj.email = this.patientObj.email ? this.patientObj.email.replace(/\s/g,"") : '';
    }
  }

  /**
   * --> onChange password enter event
   */
  onEnterPassword(event) {
    if(this.patientObj.password && this.patientObj.confirmPassword) {
      let pass1 = this.patientObj.password.trim();
      let pass2 = this.patientObj.confirmPassword.trim();
      if(pass1 == pass2) {
        this.forMatch = false;
      }
      else {
        this.forMatch = true;
      }
    }
  }


  /**
   * --> onChange confirm password enter evnet
   */
  onEnterConfirmPassword(event) {
    if(this.patientObj.password && this.patientObj.confirmPassword) {
      let pass1 = this.patientObj.password.trim();
      let pass2 = this.patientObj.confirmPassword.trim();
      if(pass1 == pass2) {
        this.forMatch = false;
      }
      else {
        this.forMatch = true;
      }
    }
  }

  onSubmit(form) {
    this.forSubmit = true;
    
    if (this.mobileNumber == null || 
      this.mobileNumber == '' || 
      this.mobileNumber == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
    }
    else {
        if ($('.intl-tel-input').hasClass('border-red')) {
          $('.intl-tel-input').removeClass('border-red');
        }
    }

    if(this.patientObj.password && this.patientObj.confirmPassword) {
      let pass1 = this.patientObj.password.trim();
      let pass2 = this.patientObj.confirmPassword.trim();
      if(pass1 == pass2) {
        this.forMatch = false;
      }
      else {
        this.forMatch = true;
      }
    }
    
      if(this.mobileNumber  && !this.forMatch && form.form.valid) {
        console.log("in if",this.mobileNumber);
        let code = this.mobileNumber.dialCode;
        let numberFlag:string = this.mobileNumber.number.trim();
        numberFlag = numberFlag.replace(/\s/g,"");
        this.patientObj.primaryISOCode = this.mobileNumber.countryCode.toLowerCase();
        this.patientObj.primaryNumber = code+' '+numberFlag;
        if(this.patientObj.email) {
          this.patientObj.email = this.patientObj.email.toLowerCase();
        }
          this.isLoading = true;
          this.apiService.postService(this.apiService.apiUrls.patientRegister, this.patientObj).subscribe((response: any) => {
            console.log("response is logi n :", response);
            
            if (response && response.statusCode == 200) {
              this.forSubmit = false;
              
              this.toastr.success(response.message);
              let flag = btoa(JSON.stringify(this.patientObj.primaryNumber));
              if(JSON.parse(localStorage.getItem('registerId'))) {
                localStorage.removeItem('registerId');
                localStorage.setItem('registerId',JSON.stringify(flag));
              } else {
                localStorage.setItem('registerId',JSON.stringify(flag));
              }
              this.router.navigateByUrl('/otp');
            }
            this.isLoading = false;
          }, (error) => {
            this.isLoading = false;
            this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
          })
      }

  }

  select(event,form) {
    if (this.mobileNumber == null || 
      this.mobileNumber == '' || 
      this.mobileNumber == undefined) {
      if ($('.intl-tel-input').hasClass('border-red') == false) {
        $('.intl-tel-input').addClass('border-red');
      }
    }
    else {
        if ($('.intl-tel-input').hasClass('border-red')) {
          $('.intl-tel-input').removeClass('border-red');
        }
    }
  }

}
