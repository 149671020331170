
<div class="main-wrapper">
  <div class="header">
    <div class="header-left">
      <a href="index.html" class="logo">
        <img [src]="imageURL" class="logo-size" width="35" height="35" alt="">
      </a>
    </div>
    <a id="toggle_btn" (click)="toggle()" href="javascript:void(0);"><i class="fa fa-bars"></i></a>
    <a id="mobile_btn" class="mobile_btn float-left" href="#sidebar"><i class="fa fa-bars"></i></a>
    <ul class="nav user-menu float-right">
      <li class="nav-item dropdown d-none d-sm-block">
        <a class="cursor-pointer" class="dropdown-toggle nav-link" data-toggle="dropdown"><i class="fa fa-bell-o"></i>
          <span class="badge badge-pill bg-danger float-right">3</span></a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span>Notifications</span>
          </div>
          <div class="drop-scroll">
            <ul class="notification-list">
              <li class="notification-message">
                <a id="text-black" href="activities.html">
                  <div class="media">
                    <span class="avatar">
                      <img alt="John Doe" src="assets/img/user.jpg" class="img-fluid">
                    </span>
                    <div class="media-body">
                      <p class="noti-details"><span class="noti-title">John Doe</span> added new task <span
                          class="noti-title">Patient appointment booking</span></p>
                      <p class="noti-time"><span class="notification-time">4 mins ago</span></p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">V</span>
                    <div class="media-body">
                      <p class="noti-details"><span class="noti-title">Tarah Shropshire</span> changed the task name
                        <span class="noti-title">Appointment booking with payment gateway</span>
                      </p>
                      <p class="noti-time"><span class="notification-time">6 mins ago</span></p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">L</span>
                    <div class="media-body">
                      <p class="noti-details"><span class="noti-title">Misty Tison</span> added <span
                          class="noti-title">Domenic Houston</span> and <span class="noti-title">Claire Mapes</span> to
                        project <span class="noti-title">Doctor available module</span></p>
                      <p class="noti-time"><span class="notification-time">8 mins ago</span></p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">G</span>
                    <div class="media-body">
                      <p class="noti-details"><span class="noti-title">Rolland Webber</span> completed task <span
                          class="noti-title">Patient and Doctor video conferencing</span></p>
                      <p class="noti-time"><span class="notification-time">12 mins ago</span></p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">V</span>
                    <div class="media-body">
                      <p class="noti-details"><span class="noti-title">Bernardo Galaviz</span> added new task <span
                          class="noti-title">Private chat module</span></p>
                      <p class="noti-time"><span class="notification-time">2 days ago</span></p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="#">View all Notifications</a>
          </div>
        </div>
      </li>
      
      <li class="nav-item dropdown has-arrow">
        <a class="cursor-pointer" class="dropdown-toggle nav-link user-link wid-200" data-toggle="dropdown">
          <span class="user-img">
            <img class="rounded-circle" [src]="userImage ? userImage : 'assets/img/user.jpg'" width="24" alt="Admin">
            <span class="status online"></span>
          </span>

          <span class="mr-l-10">{{ userName }}
          </span>
        </a>
        <small class="role-login">{{ userRole }}</small>
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="changePassword()">Change Password</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
    <div class="dropdown mobile-user-menu float-right">
      <a class="cursor-pointer" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
          class="fa fa-ellipsis-v"></i></a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="profile.html">My Profile</a>
        <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
        <a class="dropdown-item" href="settings.html">Settings</a>
        <a class="dropdown-item" (click)="logout()">Logout</a>
      </div>
    </div>
  </div>
  <div class="sidebar wid-275" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <div id="sidebar-menu" class="sidebar-menu">
        <ul>

          <ng-container *ngFor=" let permissionForAdminMaster of permissions">


            <li class=" submenu"
              *ngIf="permissionForAdminMaster.isActive && permissionForAdminMaster.enumName === 'ADMIN_MASTERS' ">
              <a><i class="fa fa-user"></i>
                <span> Admin Master </span> <span class="menu-arrow"></span></a>
              <ul style="display: none;" *ngIf="permissionForAdminMaster.subMenu.length > 0">

                <ng-container *ngFor="let subMenu of permissionForAdminMaster.subMenu">
                  <li *ngIf="subMenu.isActive && subMenu.enumName === 'BRANCH_MANAGEMENT'"><a href=""
                      [routerLink]="['/dashboard/admin/branch']" routerLinkActive="active">Branch
                      Management</a></li>

                  <li *ngIf="subMenu.isActive && subMenu.enumName === 'DEPARTMENT_MANAGEMENT' "><a href=""
                      [routerLink]="['/dashboard/admin/department']" routerLinkActive="active">Department
                      Management</a></li>
                  <li *ngIf="subMenu.isActive && subMenu.enumName === 'ROLE_MANAGEMENT' "><a href=""
                      [routerLink]="['/dashboard/admin/role']" routerLinkActive="active">Role
                      Management</a>
                  </li>

                  <li *ngIf="subMenu.isActive && subMenu.enumName === 'USER_MANAGEMENT' "><a href=""
                      [routerLink]="['/dashboard/admin/staff']" routerLinkActive="active">Staff
                      Management</a>
                  </li>

                </ng-container>

              </ul>
            </li>
            <li *ngIf="permissionForAdminMaster.isActive && permissionForAdminMaster.enumName === 'SMS_MANAGEMENT' "
              routerLinkActive="active">
              <a [routerLink]="['/dashboard/sms-manage']"><i class="fa fa-calendar-check-o"></i>
                <span>SMS Management</span></a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-wrapper ml-275 bg-gray">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="notification-box">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sidebar-overlay" data-reff=""></div>