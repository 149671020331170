import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { HttpEvent, HttpRequest, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  baseUrl: string = '';

  branchSubject = new Subject();
  firstNameSubject = new Subject();
  isProfileUpdate = new Subject();

  constructor(private apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService) { }

  /**
   * --> For logout functionality
   */
  logout() {
    this.apiService.postService(this.apiService.apiUrls.logout, {}).subscribe((response: any) => {
      // console.log("resposne  :", response);



      if (response.statusCodeValue == 200) {
        this.router.navigateByUrl('/');
        localStorage.clear()
      }
    }, (error) => {
      this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
    })
  }

  /**
   * ----> Numbers with commas
   */
  numberWithCommas(pricePerUnit) {
    var result = pricePerUnit.toString().split('.');
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (result.length > 1) {
      output += "." + result[1];
    }
    pricePerUnit = output;
    return pricePerUnit;
    // return pricePerUnit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  /**
   * ----> File upload
   */
  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', file);

    // console.log("formDate : ", formdata);

    this.baseUrl = environment.baseUrl;


    const req = new HttpRequest('POST', this.baseUrl + '/api/v1/mongo/file/save', formdata, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.http.request(req);
  }

  convertDateToDDMMYYYYFormat(date: Date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  getTimeFromDate(date: Date) {
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    return hours + ':' + minutes
  }

  getFromAndToYears() {
    let currentYear = (new Date()).getFullYear();
    let lastYear = currentYear - 80;
    let obj = {
      fromYear: lastYear,
      toYear: currentYear + 10
    }
    return obj;
  }

  getDateOfBirthYears() {
    let currentYear = (new Date()).getFullYear();
    let lastYear = currentYear - 80;
    let obj = {
      fromYear: lastYear,
      toYear: currentYear
    }
    return obj;
  }

  getDateFromStringData(data) {
    let tempArr = data.split("-");
    let day = tempArr[0];
    let month = tempArr[1] - 1;
    let year = tempArr[2];
    return new Date(year, month, day);
  }

  printPrescription(id:any){

    this.apiService.getService(this.apiService.apiUrls.printPrescription + '?userId=' + id).subscribe((response: any) => {
      if (response && response.statusCode === 200) {
        if (response.data) {
          const linkSource = 'data:application/pdf;base64,' + response.data;
          const downloadLink = document.createElement("a");
          const fileName = "Prescription.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.toastr.success(response.message ? response.message : '', 'Success');
        }
      } else {
        this.toastr.warning(response.message ? response.message : '', 'Warning');
      }
    }, (error) => {
      this.toastr.error(error.error ? error.error.message : error.message);
    });
  }

}
