import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabTechnicianGuardService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.jwtToken&&currentUser.roleName === 'LAB_TECHNICIAN') {
        return true;
      }
    
    this.router.navigateByUrl('/');
    return false;
  }
}
