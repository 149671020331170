import { Directive, Renderer2, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective {

  data: any;

  constructor(private render: Renderer2, private elRef: ElementRef) {
  }


  @HostListener('input') Input(){

    let d = this.elRef.nativeElement.value;

    let s = d.split(',');
    let n = s.join('')
    d = n;
    if(d==0){
      d=""
    }
    if (d != '') {  

    //  this.data = this.cookieservice.get("savedformat")
      this.data="India"
      if (this.data == "India") {       
        var result = d.toString().split('.');
        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (result.length > 1) {
          output += "." + result[1];
        }      
        d = output;
        this.render.setProperty(this.elRef.nativeElement, 'value', d);
      }
      else {      
        var result = d.toString().split('.');
        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
          lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
        if (result.length > 1) {
          output += "." + result[1];
        }      
        d = output;
        this.render.setProperty(this.elRef.nativeElement, 'value', d);
      }

    }

  }


}
