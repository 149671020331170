import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';

enum UserRole {
  Patient = "PATIENT",
  SystemAdmin = "SYSTEM_ADMIN",
  Doctor = "SPECIALIST_DOCTOR",
  EmrDoctor = "Fertility",
  Receptionist = "RECEPTIONIST",
  JuniorDoctor = "JUNIOR_DOCTOR",
  FinancialCounselor = "FINANCIAL_COUNSELOR",
  CallCenterAgent = 'CALL_CENTER_AGENT',
  OtManager = 'OT_MANAGER',
  Nurse = 'NURSE',
  EmrJuniorDoctor = 'JUNIOR_DOCTOR',
  LabTechAndrology = 'LABTECH_ANDROLOGY',
  LabTechAmbryology = 'LABTECH_EMBRYOLOGY',
  RefDoctor = 'REFERRAL_DOCTOR',
  LabTechnician = 'LAB_TECHNICIAN'

}

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  otp: any;
  userId: any = null;
  registerId: any = null;

  isLoading: boolean = false;
  forSubmit: boolean = false;
  permissionsData: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    if (localStorage.getItem('userId')) {
      let userId = JSON.parse(atob(JSON.parse(localStorage.getItem('userId'))));
      if (userId) {
        this.userId = userId;
      }
    }
    if (localStorage.getItem('registerId')) {
      let regId = JSON.parse(atob(JSON.parse(localStorage.getItem('registerId'))));
      if (regId) {
        this.registerId = regId;
      }
    }

  }

  /**
   * -> For resend OTP
   */
  resendOtp() {
    this.isLoading = true;
    if (this.userId || this.registerId) {

      let temp = {
        mobileNumber: this.userId ? this.userId : this.registerId
      };

      this.apiService.postService(this.apiService.apiUrls.resendOtp, temp).subscribe((response: any) => {
        if (response) {
          this.forSubmit = false;
          this.toastr.success(response.message);
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      });
    }
  }

  onSubmit(form) {
    this.forSubmit = true;
    if (form.form.valid) {

      this.isLoading = true;
      let data = {
        userId: this.userId ? this.userId : this.registerId,
        otp: this.otp,
        fromForgotPassword: false,
        registeredToken: localStorage.getItem('registeredToken') ? localStorage.getItem('registeredToken') : '',
        deviceType: 'WEB'
      };
      this.apiService.postService(this.apiService.apiUrls.validateOtp, data).subscribe((response: any) => {
        if (response && response.statusCodeValue === 200) {
          this.forSubmit = false;
          this.toastr.success(response.message ? response.message : 'OTP verified successfully', 'Success');

          let branches = [];
          if (response.body.data.branches && response.body.data.branches.length > 0) {
            for (let i = 0; i < response.body.data.branches.length; i++) {
              const obj = {
                id: response.body.data.branches[i],
                value: response.body.data.branchNames[i]
              };
              branches.push(obj);
            }
          }

          /*
          let data = {
            username: response.body.data.email,
            jwtToken: response.body.data.jwtToken,
            id: response.body.data.id,
            role: response.body.data.role,
            roleName: response.body.data.roleName,
            primaryNumber: response.body.data.primaryNumber,
            hasPartnerDetails: response.body.data.hasPartnerDetails,
            branches: branches,
            primaryBranch: response.body.data.primaryBranch,
            branchIdForReception: response.body.data.primaryBranch ? response.body.data.primaryBranch : null,
            firstName: response.body.data.firstName || '',
            lastName: response.body.data.lastName || ''
          };

          localStorage.setItem('currentUser', JSON.stringify(data));
          */

          let data = {
            username: response.body.data.email,
            jwtToken: response.body.data.jwtToken,
            id: response.body.data.id,
            role: response.body.data.role,
            roleName: response.body.data.roleName,
            primaryNumber: response.body.data.primaryNumber,
            hasPartnerDetails: response.body.data.hasPartnerDetails,
            branches: branches,
            primaryBranch: response.body.data.primaryBranch,
            branchIdForReception: response.body.data.primaryBranch ? response.body.data.primaryBranch : null,
            firstName: response.body.data.firstName || '',
            lastName: response.body.data.lastName || '',
            filePerviewUrl: (response.body.data && response.body.data.fileDataDTO && response.body.data.fileDataDTO.filePerviewUrl)
              ?
              this.apiService.baseUrl + response.body.data.fileDataDTO.filePerviewUrl :
              '',
            doctorDepartment: response.body.data.doctorDepartment || ''

          };
          localStorage.setItem('currentUser', JSON.stringify(data));

          // get permissions
          this.apiService.getService(`${this.apiService.apiUrls.getPermissions}/${response.body.data.role}`).subscribe(response => {
            if (response.statusCode === 200) {
              localStorage.setItem('permissionsData', JSON.stringify(response.data));
              this.permissionsData = response.data;
              this.getBrandFile(response, data);
            }
          }, (error) => {
            // this.isLoading = false;
            this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
          });
          /*
          if (response.body.data.roleName === UserRole.SystemAdmin) {
            this.router.navigateByUrl('/system-admin/admin-master/branch');
          } else if (response.body.data.roleName === UserRole.Patient) {
            if (!this.registerId) {
              this.router.navigateByUrl('/patient');
            }
            else {
              this.router.navigateByUrl('/patient/settings');
            }
          } else if (response.body.data.roleName === UserRole.Doctor) {
            this.router.navigateByUrl('/doctor');
          } else if (response.body.data.roleName === UserRole.Receptionist) {
            let modalObj = {
              forModal: true
            };
            let newObj = { ...data, ...modalObj };
            localStorage.setItem('currentUser', JSON.stringify(newObj));
            this.router.navigate(['/receptionist/appointments/appointment-list'], { queryParams: { forModal: 'modal' } });
          } else if (response.body.data.roleName === UserRole.JuniorDoctor) {
            this.router.navigateByUrl('/junior-doctor/appointments');
          } else if (response.body.data.roleName === UserRole.FinancialCounselor) {
            let modalObj = {
              forModal: true
            };
            let newObj = { ...data, ...modalObj };
            localStorage.setItem('currentUser', JSON.stringify(newObj));
            this.router.navigateByUrl('/financial-counselor');
          }
          else {
            this.toastr.success(response.body.data.roleName);
          }
          */

        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.body.message ? error.error.body.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      });
    }
  }

  getBrandFile(response, data) {
    this.apiService.getService(this.apiService.apiUrls.getBrandFile).subscribe((resp: any) => {
      this.isLoading = false;
      if (resp && resp.statusCode === 200) {
        let brandImageUrl = this.apiService.baseUrl + resp.data.filePerviewUrl;
        localStorage.setItem('brand', JSON.stringify(brandImageUrl));
        this.forNavigation(response, data);
      }
    }, (error) => {
      this.isLoading = false;
      this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      this.forNavigation(response, data);
    });
  }


  forNavigation(response, data) {
    if (response.data.roleName === UserRole.SystemAdmin) {
      this.router.navigateByUrl('/system-admin/admin-master/branch');
    } else if (response.data.roleName === UserRole.Patient) {
      this.router.navigateByUrl('/patient');
    } else if (response.data.roleName === UserRole.Doctor) {
      if (data.doctorDepartment.name == UserRole.EmrDoctor) {
        this.router.navigateByUrl('/emr-doctor');
      } else {
        this.router.navigateByUrl('/doctor');
      }
    } else if (response.data.roleName === UserRole.Receptionist) {
      let modalObj = {
        forModal: true
      }
      let newObj = { ...data, ...modalObj }
      localStorage.setItem('currentUser', JSON.stringify(newObj));
      this.router.navigate(['/receptionist/appointments/appointment-list']);
    } else if (response.data.roleName === UserRole.JuniorDoctor) {
      if (data.doctorDepartment.name == UserRole.EmrDoctor) {
        this.router.navigateByUrl('/emr-junior-doctor')

      } else {
        this.router.navigateByUrl('/junior-doctor/appointments');
      }

    } else if (response.data.roleName === UserRole.FinancialCounselor) {
      let modalObj = {
        forModal: true
      }
      let newObj = { ...data, ...modalObj }
      localStorage.setItem('currentUser', JSON.stringify(newObj));
      this.router.navigateByUrl('/financial-counselor');
    } else if (response.data.roleName == UserRole.CallCenterAgent) {
      this.router.navigateByUrl("/call-center-agent");
    }
    else if (response.data.roleName == UserRole.Nurse) {
      this.router.navigateByUrl("/nurse");
    }

    else if (response.data.roleName == UserRole.RefDoctor) {
      this.router.navigateByUrl("/emr-ref-doctor");
    }

    else if (response.data.roleName == UserRole.LabTechAndrology) {
      this.router.navigateByUrl("/labtech-andro");
    }
    else if (response.data.roleName == UserRole.LabTechAmbryology) {
      this.router.navigateByUrl("/labtech-embryo");
    }
    else if (response.data.roleName == UserRole.LabTechnician) {
      this.router.navigateByUrl("/labtech");
    }



    else if (response.data.roleName == UserRole.OtManager) {
      this.router.navigateByUrl("/ot-manager");
    }

    else {

      const permissions = this.permissionsData.menu;
      const checkRoleExistence = roleParam => permissions.some(({ enumName }) => enumName === roleParam);
      if (checkRoleExistence('ADMIN_MASTERS')) {
        const adminMasterObj = permissions.find(item => item.enumName === 'ADMIN_MASTERS');
        const subMenu = adminMasterObj.subMenu;
        const checkSubMenuExistence = roleParam => subMenu.some(({ enumName }) => enumName === roleParam);
        if (checkSubMenuExistence('DEPARTMENT_MANAGEMENT')) {
          // this.router.navigateByUrl('/admin-master/department');
          this.router.navigateByUrl('/dashboard');
        } else if (checkSubMenuExistence('ROLE_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/role');
        } else if (checkSubMenuExistence('BRANCH_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/branch');
        } else if (checkSubMenuExistence('USER_MANAGEMENT')) {
          this.router.navigateByUrl('/system-admin/admin-master/staff');
        }
      } else if (checkRoleExistence('GENERAL_MASTERS')) {
        this.router.navigateByUrl('/system-admin/general-master');
      } else if (checkRoleExistence('DOCTOR_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SLOT_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('TARIFF_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SMS_MANAGEMENT')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('APPOINTMENTS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('PATIENTS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('DOCTORS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SERVICES')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('TELE-CONSULTATION')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('FINANCIAL_FORM')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('RATE_PLANS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('DOCTOR_SCHEDULE')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('SETTINGS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      } else if (checkRoleExistence('MEDICAL_DIAGNOSIS')) {
        this.router.navigateByUrl('/system-admin/admin-master/branch');
      }
      this.toastr.success(response.data.roleName);
    }


  }

  back() {
    this.router.navigateByUrl('/mobile-number');
  }

  /**
   * --> Patient register navigation
   */
  backReg() {
    this.router.navigateByUrl('/patient-register');
  }
}
