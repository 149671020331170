import { BranchDeptVersionDto } from './branchDeptVersionDto.modal';
import { Address } from '../Address/address.modal';

export class PatientPersonalDetails {
    addressDto: Address = new Address();
    age?: any = 0;
    // branchDeptVersionDto?: BranchDeptVersionDto = new BranchDeptVersionDto();
    branchDeptVersionDto?: BranchDeptVersionDto = null;
    branchNames?: [];
    branches?: [];
    dob?: Date;
    email?: string = '';
    firstName?: string = '';
    gender?: string = '';
    id?: number = 0;
    isActive?: boolean = true;
    jwtToken?: string = '';
    lastName?: string = '';
    fullName?: string = '';
    maritalStatus?: string = '';
    otp?: number = 0;
    primaryNumber?: any = '';
    primaryISOCode?: string = '';
    religion?: string = '';
    role?: number = 0;
    roleName?: string = '';
    secondaryNumber?: any = '';
    secondaryISOCode?: string = '';
    userType?: string = '';
    salutationId?: any;
    occupation?: string;
}