import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmrDoctorGuard implements CanActivate {

  constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // console.log("currentUser..",currentUser);
    if (currentUser && currentUser.jwtToken) {

      if (currentUser.roleName === "SPECIALIST_DOCTOR" && currentUser.doctorDepartment.name =="Fertility") {

           return true;
      }
    }
    this.router.navigateByUrl('/');
    return false;
  }
  
}

