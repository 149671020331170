export class Pagination {
    appointmentDate?: string = '';

    searchKey?: string = '';

    pageSize?: number = 5;

    pageNumber?: number = 0;

    filterKey?: any = null;

    roleType?: any = null;

    order?: string = '';

    field?: string = '';

    status?: any = null;

    consultationType?: string = ''
}

export class Labtech {
    appointmentDate?: string = '';

    searchKey?: string = '';

    pageSize?: number = 5;

    pageNumber?: number = 0;

    filterKey?: any = null;

    roleType?: any = null;

    order?: string = '';

    field?: string = '';

    status?: any = null;

    consultationType?: string = ''
}