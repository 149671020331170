<!-- Main Wrapper -->
<div class="main-wrapper">
  <!-- Header -->
  <header class="header">
    <nav class="navbar navbar-expand-lg fixed-top header-nav">
      <div class="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
          <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <a href="index.html" class="navbar-brand logo">
          <img
            src="assets/img/new-hegde-logo.png"
            class="img-fluid"
            alt="Logo"
          />
        </a>
      </div>
      <div class="main-menu-wrapper">
        <div class="menu-header">
          <a href="index.html" class="menu-logo">
            <img
              src="assets/img/new-hegde-logo.png"
              class="img-fluid"
              alt="Logo"
            />
          </a>
          <a id="menu_close" class="menu-close" href="javascript:void(0);">
            <i class="fas fa-times">|||</i>
          </a>
        </div>
        <ul class="main-nav"></ul>
      </div>
      <ul class="nav header-navbar-rht">
        <li class="nav-item contact-item">
          <div class="header-contact-img">
            <a class=""
              ><img
                src="assets/img/Icons/Notification Normal State.svg"
                alt=""
                class=""
              />
            </a>
          </div>
          <div class="header-contact-img">
            <a class="">
              <img
                src="assets/img/Icons/Help-Normal-State.svg"
                alt=""
                class=""
              />
            </a>
          </div>
        </li>

        <!-- User Menu -->
        <li class="nav-item dropdown has-arrow logged-item">
          <a class="dropdown-toggle nav-link" data-toggle="dropdown">
            <span class="user-img">
              <img
                class="rounded-circle"
                [src]="userImage ? userImage : 'assets/img/user.jpg'"
                width="31"
                alt=""
              />
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="user-header">
              <div class="">
                <h6>
                  {{ currentUser.firstName | replace | titlecase }}
                  {{ currentUser.lastName | replace | titlecase }}
                </h6>
                <p class="text-muted mb-0">Lab Technician</p>
              </div>
            </div>
            <a class="dropdown-item" (click)="changePassword()"
              >Change Password</a
            >
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
        <!-- /User Menu -->
      </ul>
    </nav>
  </header>
  <!-- /Header -->

  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <!-- Profile Sidebar -->
          <div class="profile-sidebar">
            <div class="widget-profile pro-widget-content">
              <div class="profile-info-widget">
                <a class="booking-doc-img">
                  <img
                    src="assets\img\Doctor Profile place holder\PNG\Doctor Place holder 88x88.png"
                    alt="User Image"
                  />
                </a>
                <div class="profile-det-info">
                  <h3>
                    {{ currentUser.firstName | replace | titlecase }}
                    {{ currentUser.lastName | replace | titlecase }}
                  </h3>

                  <div class="patient-details">
                    <h5 class="mb-0">Lab Technician</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="dashboard-widget">
              <nav class="dashboard-menu">
                <ul>
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a routerLink="/labtech/">
                      <i class="fas fa-calendar-plus"></i>
                      <span>Reports Upload</span>
                    </a>
                  </li>

                  <li routerLinkActive="active">
                    <a (click)="logout()">
                      <i class="fas fa-sign-out-alt"></i>
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <!-- /Profile Sidebar -->
        </div>

        <div class="col-md-7 col-lg-8 col-xl-9">
          <router-outlet> </router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
