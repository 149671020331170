export class Address {
    addressLine1?: string = '';

    addressLine2?:string = '';

    country?: any = '';

    countryName?: string = '';

    state?: any = '';

    stateName?: string = '';

    city?: any = '';

    cityName?: string = '';

    area?: string = '';

    areaName?: string = '';

    region?:string = '';

    regionName?: string = '';

    pincode?: string = '';
}