import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'convertStringToDate'
})
export class StringToDateChangePipe implements PipeTransform {
    transform(date: any): any {     
        if (date) {
            let day = (date.split('-'))[0]
            let month = (date.split('-'))[1]
            let year = (date.split('-'))[2] 
            let stringDate=year+"-"+month+"-"+day;
            return new Date(stringDate);
        }
        return new Date();
    }
}
