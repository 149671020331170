import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplyLeaves } from 'src/app/modals/Leaves/apply-leaves.modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Utils/common/common.service';
import { ApiService } from 'src/app/api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-apply-leave',
  templateUrl: './apply-leave.component.html',
})
export class ApplyLeaveComponent implements OnInit {

  dateType: string = 'date';
  doctorLeaveData: ApplyLeaves = new ApplyLeaves();
  selectedDate: any;
  startTime: any;
  endTime: any;
  rangeDates: any;
  fromFromYear = null;
  lastFromYear = null;

  fromToYear = null;
  lastToYear = null;

  isLoading = false;

  leaveId : any = null;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private commonService: CommonService,
    private apiService: ApiService,
    private location : Location
  ) { }

  ngOnInit(): void {
    // date
    // Date Range
    let obj = this.commonService.getFromAndToYears();
    if(obj) {
      this.fromFromYear = obj.fromYear;
      this.lastFromYear = obj.toYear;

      this.fromToYear = obj.fromYear;
      this.lastToYear = obj.toYear;
    }

    let id  = this.activatedRoute.snapshot.params['leaveId'];
    if(id) {
      this.leaveId = id;
      this.getLeaveById()
    }

  }


  onTypeChange = (event) => {
    this.selectedDate = null;
    this.startTime = null;
    this.endTime = null;
    this.rangeDates = null;
    this.doctorLeaveData = new ApplyLeaves();
  }

  onStartTimeSelect = (event) => {
    console.log(event);
    console.log(this.startTime);
    
    
    if (event && this.startTime && this.endTime) {
      let res = this.isValidTimes(this.startTime, this.endTime);
    }
  }

  onEndTimeSelect = (event) => {
    if (event && this.startTime && this.endTime) {
      let res = this.isValidTimes(this.startTime, this.endTime);
    }
  }


  isValidTimes = (startTime: Date, endTime: Date) : Boolean => {
    if(startTime.getTime() >= endTime.getTime() ) {
      this.toastr.error('End Time Should Be Greater Than Start Time');
      return false;
    } else {
      let start_time = this.commonService.getTimeFromDate(startTime);
      this.doctorLeaveData.startTime = start_time;

      let end_time = this.commonService.getTimeFromDate(endTime);
      this.doctorLeaveData.endTime = end_time;
      return true;
    }
  }

  onChangeRangeDate = (event) => {
    console.log(this.rangeDates);
    
  }

  onClickApplyLeave = (event) => {

    if (this.dateType && this.dateType === 'Date Range') {
      if (this.dateType && this.dateType === 'Date Range' && this.rangeDates && this.rangeDates.length == 2) {
        if (this.rangeDates[0] == null || this.rangeDates[1] == null) {
          this.toastr.error('Please Select Date Range')
          return;
        } else {
          this.doctorLeaveData.startDate = this.commonService.convertDateToDDMMYYYYFormat(this.rangeDates[0]);
          this.doctorLeaveData.endDate = this.commonService.convertDateToDDMMYYYYFormat(this.rangeDates[1]);
        }
      }
    } else if (this.dateType === 'date') {
      if (!this.selectedDate) {
        this.toastr.error('Please Select Date')
        return;
      } else {
        this.doctorLeaveData.startDate = this.commonService.convertDateToDDMMYYYYFormat(this.selectedDate);
        this.doctorLeaveData.endDate = this.commonService.convertDateToDDMMYYYYFormat(this.selectedDate);
      }
    }

    if (!this.doctorLeaveData.reason) {
      this.toastr.error('Please Enter Reason');
      return;
    }

    if(! this.startTime || !this.endTime) {
      this.toastr.error('Please Select Start And End Times');
      return;
    } 

    let isValid = this.isValidTimes(this.startTime, this.endTime);
    if(!isValid)
      return;
    

    this.doctorLeaveData.leaveType = this.dateType;

    let currentUser: any = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
    if (currentUser) {

      console.log(currentUser);
      console.log('FINAL DATA : ', this.doctorLeaveData);

      this.isLoading = true;

      this.apiService.postService(this.apiService.apiUrls.applyLeave, this.doctorLeaveData).subscribe((response) => {
        console.log(' RESPONSE ', response);
        if (response.statusCode === 200) {
          this.toastr.success(response.message);
          this.isLoading = false;
          this.router.navigateByUrl('/doctor/schedule/leaves');
        }
      }, error => {
        this.isLoading = false;
        console.log(error);
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })

    } else {
      this.toastr.error('Something went wrong!')
    }

  }



  getLeaveById = () => {
    this.isLoading = true;
    this.apiService.getService(this.apiService.apiUrls.getLeaveById+'/'+this.leaveId).subscribe( (response) => {

      console.log(response);

      if(response && response.statusCode==200 && response.data) {
        this.doctorLeaveData = response.data;
        if(response.data.leaveApplicationType==="DATE_RANGE") {
          this.dateType = 'Date Range'
        } else if(response.data.leaveApplicationType==="DATE") {
          this.dateType = 'date'
        }
  
        if(response && response.data && response.data.startTime) {
          let startTimeHHMM : any = [];
          startTimeHHMM = response.data.startTime.split(':');
  
          console.log(startTimeHHMM);
          let date = new Date();
          date.setHours(startTimeHHMM[0]);
          date.setMinutes(startTimeHHMM[1]);
          this.startTime = date;
        }
  
        if(response && response.data && response.data.endTime) {
          let endTimeHHMM : any = [];
          endTimeHHMM = response.data.endTime.split(':');
  
          console.log(endTimeHHMM);
          let date = new Date();
          date.setHours(endTimeHHMM[0]);
          date.setMinutes(endTimeHHMM[1]);
          this.endTime = date;
        }
        
  
        if(response && response.data && response.data.leaveApplicationType==="DATE_RANGE" &&response.data.startDate && response.data.endDate) {
         
          let d1:any[] = response.data.startDate.split('-');
          let startDate = new Date(d1[2]+'-'+d1[1]+'-'+d1[0]);
  
          let d2:any[] = response.data.endDate.split('-');
  
          let endDate = new Date(d2[2]+'-'+d2[1]+'-'+d2[0]);
          console.log(startDate);
          
  
          this.rangeDates = [startDate,endDate];
          console.log(this.rangeDates);
          
        }
  
        if(response && response.data && response.data.leaveApplicationType==="DATE" &&response.data.startDate && response.data.endDate) {
         
          let d1:any[] = response.data.startDate.split('-');
          let startDate = new Date(d1[2]+'-'+d1[1]+'-'+d1[0]);
          
          this.selectedDate = new Date(d1[2]+'-'+d1[1]+'-'+d1[0]);
          
        }

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }


    }, error => {
      this.isLoading = false;
      this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
    })
  }

  goBack(){
    this.location.back()
  }

}
