<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form autocomplete="off" #passwordForm="ngForm" class="form-signin" (ngSubmit)="onSubmit(passwordForm)">
                    <div class="account-logo">
                        <a class="cursor-pointer"><img src="assets/img/logo-hegde.png" alt=""></a>
                    </div>
                    <div class="form-group">
                        <label>New Password</label>
                        <input autocomplete="off" required type="password" name="password" id="password"
                        [(ngModel)]="forgotPasswordObj.newPassword" #newPassVal="ngModel"
                        [ngClass]="{ 'is-invalid': forSubmit && newPassVal.errors }"
                         class="form-control">
                         <div class="invalid-feedback" *ngIf="forSubmit && newPassVal.errors && newPassVal.errors.required">
                             <span class="text-danger">
                                 Password is required
                             </span>
                         </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input autocomplete="off" required type="password" name="confirmPassword" id="confirmPassword"
                        #confirmPassVal="ngModel"
                        [ngClass]="{ 'is-invalid': forSubmit && confirmPassVal.errors }"
                        [(ngModel)]="forgotPasswordObj.confirmPassword" class="form-control">
                        <div class="invalid-feedback" *ngIf="forSubmit && confirmPassVal.errors && confirmPassVal.errors.required">
                            <span class="text-danger">
                               Confirm password is required
                            </span>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Save</button>
                        <button type="submit" *ngIf="type" (click)="cancel()" class="btn btn-primary ml-2 account-btn">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>