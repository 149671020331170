import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-patient-register-otp',
  templateUrl: './patient-register-otp.component.html',
})
export class PatientRegisterOtpComponent implements OnInit {

  otp:any;
  userId:any;


  isLoading: boolean = false;
  forSubmit: boolean = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private apiService: ApiService) { }

  ngOnInit(): void {
    let userId = JSON.parse(atob(JSON.parse(localStorage.getItem("userId"))));
    if(userId) {
      this.userId = userId;
    }
  }

  /**
   * -> For resend OTP 
   */
  resendOtp() {
    this.isLoading = true;
    if(this.userId) {

      let temp = {
        mobileNumber: this.userId
      }
      
      this.apiService.postService(this.apiService.apiUrls.resendOtp, temp).subscribe((response: any) => {
        if(response) {
          this.forSubmit = false;
          this.toastr.success(response.message);
          
        }
        this.isLoading = false;
      },(error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })
    }
  }

  onSubmit(form) {
    console.log("form ",form);
    // this.router.navigateByUrl('/system-admin/admin-master/branch');

    this.forSubmit = true;
    if(form.form.valid) {

      this.isLoading = true;
      let data  = {
        userId: this.userId,
        otp: this.otp,
        fromForgotPassword: false,
        registeredToken: localStorage.getItem('registeredToken') ? localStorage.getItem('registeredToken'):"", 
        deviceType:"WEB"
      }
      this.apiService.postService(this.apiService.apiUrls.validateOtp, data).subscribe((response: any) => {
        console.log("response is logi n :", response);
        
        if (response && response.statusCodeValue == 200) {
          this.forSubmit = false;
          let branches = [];
            if(response.body.data.branches && response.body.data.branches.length > 0) {
              for (let i = 0; i < response.body.data.branches.length; i++) {
                    let obj = {
                      id: response.body.data.branches[i],
                      value: response.body.data.branchNames[i]
                    }
                    branches.push(obj);
              }
            }
            let data = {
              username: response.body.data.email,
              jwtToken: response.body.data.jwtToken,
              id: response.body.data.id,
              role: response.body.data.role,
              roleName: response.body.data.roleName,
              primaryNumber: response.body.data.primaryNumber,
              hasPartnerDetails: response.body.data.hasPartnerDetails,
              branches: branches,
              primaryBranch: response.body.data.primaryBranch,
              branchIdForReception: response.body.data.primaryBranch ? response.body.data.primaryBranch : null,
              firstName :response.body.data.firstName || '',
              lastName :response.body.data.lastName || ''
            }
              localStorage.setItem('currentUser', JSON.stringify(data));
           this.toastr.success(response.message ? response.message : 'OTP verified successfully', "Success");

          this.router.navigateByUrl('/patient');
          localStorage.removeItem('registerId');
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
        this.toastr.error(error.error ? (error.error.message ? error.error.message : 'Something went wrong from server side. Please try again after sometime') : error.message);
      })
    }
  }

  back() {
    this.router.navigateByUrl('/patient-register')
  }

}
