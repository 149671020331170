import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (request.headers.has('Content-Type')) {

        }else if (currentUser && currentUser.jwtToken) {
            request = request.clone({
                setHeaders: {
                    'Authorization': currentUser.jwtToken,//currentUser.sessionInfo.accessToken,
                    // 'Content-Type': 'application/json',
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    // 'Content-Type': 'application/json',
                }
            });
        }
        return next.handle(request);
    }
}
