import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging) { }
    listen() {
        this.angularFireMessaging.messages
          .subscribe((message) => { console.log('test text', message); });
      }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                localStorage.setItem('registeredToken', token);
            },
            (err) => {
               
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            });
    }
}