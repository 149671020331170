import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../Utils/common/common.service';

@Component({
  selector: 'app-lab-technician',
  templateUrl: './lab-technician.component.html',
})
export class LabTechnicianComponent implements OnInit {

  constructor(private router: Router  ,   private commonService: CommonService,
    ) { }
    currentUser: any;
    userRole: any = '';
    imageURL = 'assets/img/new-hegde-logo.png';
    userImage = '';
  
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser) {
      if (this.currentUser.roleName == 'LAB_TECHNICIAN') {
        this.userRole = this.currentUser.roleName == 'LAB_TECHNICIAN' ? 'LabTechnician' : '';
        this.userImage = this.currentUser.filePerviewUrl || ''
      }
      if (localStorage.getItem('brand')) {
        let image = JSON.parse(localStorage.getItem('brand'));
        if (image) {
          this.imageURL = image;
        }
      }
    }

  }
  changePassword() {
    this.router.navigateByUrl('/labtech-andro/change-password');
  }
  logout() {
    this.commonService.logout();
  }


}
