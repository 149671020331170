import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;
declare var SockJS: any;
declare var Stomp: any;
@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
})
export class ChatComponent implements OnInit, OnDestroy {

	chatSub = null;

	constructor(private router: Router) { }

	ngOnInit(): void {
		$(document).ready(() => {
			var stompClient = null;
			var socket = new SockJS('http://ci2.thrymr.net:9066/thrymr-chatbox-websocket');
			stompClient = Stomp.over(socket);

			stompClient.connect({
				'xAuth': "1"
			}, (frame) => {
				console.log('Chat Socket Connected To: ' + frame);
				this.chatSub = stompClient.subscribe('/chatbox-subscribe/receive-direct-message/' + '1', (response) => {
					console.log("chat response", response);
					var parsedResponse = JSON.parse(response.body);
					$('#messages').append("</br>" + parsedResponse.payLoad.message);
				});
				console.log("-------> chat is --->", this.chatSub)
			
			});


			$('#send').click(() => {
				var messageObj = {
					xAuth: '',
					receipientId: '',
					message: '',
					isGroup: false,
					time: new Date().getTime()
				};
				messageObj.xAuth = "1";
				messageObj.receipientId = "2";
				messageObj.message = $('#message').val();
				$('#message').val('');

				stompClient.send("/chatbox-destination/send-direct-message", {}, JSON.stringify(messageObj));
			});
		})
	}

	back() {
		this.router.navigateByUrl("/");
	}

	ngOnDestroy() {
		console.log("in ng on  destrot=y------>", this.chatSub);

	}

}
