import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Utils/common/common.service';

@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
})
export class AddHolidayComponent implements OnInit {

  fromFromYear = null;
  lastFromYear = null;

  fromToYear = null;
  lastToYear = null;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    let obj = this.commonService.getFromAndToYears();
    if(obj) {
      this.fromFromYear = obj.fromYear;
      this.lastFromYear = obj.toYear;

      this.fromToYear = obj.fromYear;
      this.lastToYear = obj.toYear;
    }
  }

}
