export class FileDto {
    bytes?: any = null;
    contentType?: string = "";
    fileId?: string = "";
    fileType?: string = '';
    id?:number =  0;
    isActive?:boolean  =  true;
    isSuccess?:boolean = true;
    name?: string = "";
    sizeInBytes?:number = 0;
}