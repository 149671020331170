import { FileDto } from '../AdminMaster/FileDto/file.modal';
import { PatientPersonalDetails } from './patient_personal_info.modal';

export class Patient {
  fileDataDTO?: FileDto = new FileDto();
  hasPartnerDetails?: boolean = false;
  id?: number = 0;
  occupation?: string = '';
  patientType?: string = '';
  referredBy?: string = '';
  originalPartnerDetails: PartnerPatient = new PartnerPatient();
  user?: PatientPersonalDetails = new PatientPersonalDetails();
  mrNumber: any;
}

export class PartnerPatient {
  fileDataDTO?: FileDto = new FileDto();
  id?: number = 0;
  occupation?: string = '';
  patientType?: string = '';
  referredBy?: string = '';
  mrNumber: any;
  user?: PatientPersonalDetails = new PatientPersonalDetails();
}
