
  export class ApplyLeaves {
    endDate?: string = '';
    endTime?: string = '';
     id?:any = null;
    leaveType?: string = '';
    reason?: string = '';
    startDate?: string = '';
    startTime?: string = '';
    doctorId?: any = null;
  }