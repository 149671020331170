import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;
  isVideoCallOn: boolean;
  /** API URL's end points list */
  apiUrls = {
    /** Authentication */
    login: '/api/v1/user/authentication',
    logout: '/api/v1/user/logout',
    sendOtp: '/api/v1/user/send-otp',
    resendOtp: '/api/v1/user/resend-otp',
    validateOtp: '/api/v1/user/otp/validate',
    forgetPassword: '/api/v1/user/forgot-password',
    patientRegister: '/api/v1/user/patient-register',
    changePassword: '/api/v1/user/change-password',
    getPermissions: '/api/v1/user/role/permissions' /* /{roleId} */,
    getPermissionsForRoleManagement:
      '/api/v1/user/role/submenu-permissions' /* /{name} */,
    /** Admin Master */
    /* Staff Management */
    getStaffList: '/api/v1/user/all',
    addStaffUser: '/api/v1/user/register',
    enableStaffUser: '/api/v1/user/enable-user',

    getStafById: '/api/v1/user',
    /* Role Management */
    getRolesList: '/api/v1/role/all',
    role: '/api/v1/role',
    enableRole: '/api/v1/role/enable-role',
    rolePermissions: '/api/v1/user/role-management/permissions',
    saveRolePermissions: '/api/v1/user/role-management/permissions',

    getAllActiveStaffRoles: '/api/v1/role/active/staff',
    /* Department Management */
    getDepartmentList: '/api/v1/department/all',
    departments: '/api/v1/department',
    getOneDepartmentById: '/api/v1/department' /* /{id}*/,
    enableDepartment: '/api/v1/department/enable-department',
    internalActive: '/api/v1/dept-internal-inactive',
    forAllStatusForDepartment: '/api/v1/department/enable-status',
    deleteDepartmentById: '/api/v1/department',

    /**Branch Management */
    getBranchList: '/api/v1/branch/all',
    branch: '/api/v1/branch',
    getAllActiveBranchs: '/api/v1/branch/active',
    enableBranch: '/api/v1/branch/enable-branch',
    /** Doctor Management */
    /* Specialist Doctor && Junior Doctor */
    registerDoctor: '/api/v1/user/doctor-register',
    getDoctorsList: '/api/v1/user/doctor/all',
    getDoctorDetails: '/api/v1/user/doctor',
    deleteDoctor: '/api/v1/user/doctor/enable-doctor',

    getIndividualDoctor: '/api/v1/user/doctor/',
    getIndividualDoctorWidget: '/api/v1/user/doctor-details/ ',
    //reference doctor

    saveRefDoctor: '/api/v1/emrUser/referralDoctor/save',
    getRefDoctorList: '/api/v1/emrUser/referralDoctor/all',
    enableRefDoctor: '/api/v1/emrUser/referralDoctor/enable-referralDoctor',
    getRefDoctorDetails: '/api/v1/emrUser/referralDoctor/',
    printConsultForm: '/api/v1/emr/print/consult-form', //consultationId
    printHistoryFormFemale: '/api/v1/emr/print/patient-history-female-form', //patientId
    printHistoryFormMale: '/api/v1/emr/print/patient-history-male-form', //patientId

    /** Slot Management */
    createSlot: '/api/v1/slots',
    getSlots: '/api/v1/doctor/availability',
    getAllSlotsList: '/api/v1/doctor/availability',
    /** Tarrif/Service Management */
    ratePlan: '/api/v1/tariff/ratePlan',
    getRatePlansList: '/api/v1/tariff/rateplan/all',
    procedure: '/api/v1/tariff/procedure',
    getAllActiveProceduresList: '/api/v1/tariff/procedure/all',
    getAllProceduresList: '/api/v1/tariff/procedure/paged',
    package: '/api/v1/tariff/package',
    getPackagesList: '/api/v1/tariff/package/paged',
    getProceduresByDepartmentId: '/api/v1/tariff/procedure/department',
    getProceduresByBranch: '/api/v1/tariff/procedure' /**{branchId} */,

    /** SMS Management */
    /** General Master */
    /* Brand Upload */
    /* Master Data */
    getAllCountries: '/api/v1/country',
    getAllStates: '/api/v1/state/all',
    getAllCities: '/api/v1/city/all',
    activateCities: '/api/v1/city/activate-city',
    getActiveCountryAndStateForArea: '/api/v1/city/active',
    saveArea: '/api/v1/area',
    getAllAreas: '/api/v1/area/all',
    enableArea: '/api/v1/area/enable-area',
    saveRegion: '/api/v1/region',
    getAllRegions: '/api/v1/region/all',
    enableRegion: '/api/v1/region/enable-region',
    getAreaById: '/api/v1/area',
    getRegionById: '/api/v1/region',
    deleteRegionById: '/api/v1/region',
    deleteAreById: '/api/v1/area',

    /* Salutation */
    getSalutationList: '/api/v1/salutation/all',
    salutation: '/api/v1/salutation',
    enableSalutation: '/api/v1/salutation/enable-salutation',
    adminSalutation: '/api/v1/salutation/admin-all',

    /** Common APIs */
    getAllActiveCountries: '/api/v1/country/active',
    getAllActiveStates: '/api/v1/state/active',
    getAllActiveCities: '/api/v1/city/active',
    getAllActiveAreas: '/api/v1/area/active',
    getAllActiveRegions: '/api/v1/region/active',

    /** Get Files by ID */
    fileApi: '/api/v1/file',
    fileApiForBranch: '/api/v1/file/branch',

    /** Get file for Doctor Management */
    fileForDoctorManagement: '/api/v1/file/doctor', //--> before
    //fileForDoctorManagement: '/api/v1/mongo/file', //--> after {fileId}

    /** File APIs for Brand */
    saveBrandFile: '/api/v1/brand-upload',
    getBrandFile: '/api/v1/brand-upload',

    /** Get all specialist dcotors  */
    getSpecialistDoctors: '/api/v1/user/doctor/specialist-doctors',
    getJuniorDoctorsByDeptId: '/api/v1/user/doctor/junior-doctors',

    /** Patient Module */
    /** Patient Services */
    getDepartmentsByBranches: '/api/v1/department/all/branches/',

    getDepartmentsByBranch: '/api/v1/department/all/branch/',
    getAllActiveDepartments: '/api/v1/department/active',
    getDoctorsByBranchIdAndDepartmentId: '/api/v1/doctor/all/branch/',
    getSlotsForPatient: '/api/v1/slots',
    getPatientSlotFileUpload: '/api/v1/appointment-files',
    getPatientSlotCreate: '/api/v1/appointment',
    getAllAppointments: '/api/v1/patient/all/appointments/previous',
    getUpcomingAppointments: '/api/v1/patient/all/appointments',
    getPaymentForSlot: '/api/v1/patient/all/appointments/payment',
    getCancelPatientAppoitment: '/api/v1/patient/all/appointments/cancel',
    getPatientProfile: '/api/v1/user/patient/profile',
    saveOrEditPatientProfile: '/api/v1/user/patient-register',
    updatePatientProfile: '/api/v1/user/patient/profile',
    patientProfileFileUpload: '/api/v1/user/profile-image',
    getPatientProfilePic: '/api/v1/user/patient/profile',
    getMedicalReports: '/api/v1/patient/appointment-files',
    deleteAppointmentsFileData: '/api/v1/file/appointment-file',
    downloadMedicalRecord: '/api/v1/mongo/file',
    previewFile: '/api/v1/file/appointment-file',
    removeFile: '/api/v1/file/appointment-file',
    getTeleConsultationCheckInStatus:
      '/api/v1/appointment/tele-check-in' /** {id}  */,

    /** Docor Module */
    getDoctorAppointments: '/api/v1/doctor/appointments',
    getDoctorHomeData: '/api/v1/doctor/home',
    getDoctorProfileDetails: '/api/v1/user/doctor/profile',
    saveDoctorProfilePic: '/api/v1/user/doctor/profile-image',
    getDoctorShiftstsDateWise: '/api/v1/doctor/appointments/shifts',
    saveEmr: '/api/v1/doctor/emr',
    getPatientDetailsFromDoctor: '/api/v1/user/patient',
    getPatientMedicalRecordsInDoctor:
      '/api/v1/doctor/patient-appointment-files',
    applyLeave: '/api/v1/doctor/leave',
    getDoctorLeaves: '/api/v1/doctor/all/leaves' /*  /{doctorId} */,
    cancelDoctorLeave:
      '/api/v1/doctor/cancel/leave' /* /{doctorId}/{leaveId} */,
    getLeaveById: '/api/v1/doctor/leave' /* /{leaveId} */,
    getDoctorAppointmentsDateWise:
      '/api/v1/doctor/appointments' /* /{date}/{shift}/{branchId}  */,
    getDepartmentBranches: '/api/v1/department-branches', //{id}'

    // Receptionist Module
    getAllTodayAppointments: '/api/v1/receptionist/appointment/today',
    getOneAppointment: '/api/v1/appointment',
    getConsultDataByPatientId: '/api/v1/consultation/all', //{patientId}
    getTreatmentHistoryDataByPatientId:
      '/api/v1/consultation/treatments/patient/', //{patientId}
    appointmentCheckIn: '/api/v1/receptionist/appointment',
    savePatientDetails: '/api/v1/receptionist/patient-register',
    getPatientsListInReception: '/api/v1/user/patient/all',
    getPatientProfileById: '/api/v1/user/patient',
    paymentConfirmationForAppointment:
      '/api/v1/receptionist/appointments/payment',
    getAllPatients: '/api/v1/user/patient/all',
    getAllUpcomingAppointmentsExcel:
      '/api/v1/receptionist/appointment/future/excel',
    getAllHistoryAppointmentsExcel:
      '/api/v1/receptionist/appointment/previous/excel',
    historyAppointmentsInReception: '/api/v1/receptionist/appointment/previous',
    upcomingAppointmentsInReception: '/api/v1/receptionist/appointment/future',
    getAllTodayAppointmentsExcel:
      '/api/v1/receptionist/appointment/today/excel' /* /{branchId} */,

    getAllDepartmentsByBranchId:
      '/api/v1/department/all/branch' /* /{branchId} */,
    getAllDoctorsByBranchIdAndDepartmentId:
      '/api/v1/doctor/branch' /* /{branchId}/department/{departmentId} */,
    getPatientOldAppointments: '/api/v1/patient/all/appointments/previous',
    applyLeaveDoctorList: '/api/v1/doctor/branch',
    getAllTotalAppoinmentCount:
      '/api/v1/recptionist/appointment-count' /* /{branchId} */,
    getDoctorSchedule: '/api/v1/doctor/schedule' /* {date}/barnch/{branchId} */,
    forMarkLeaveInReceptionist: '/api/v1/doctor/leave/slots',
    forSaveMarkLeave: '/api/v1/receptionist/doctor-mark-leave',
    cancelAppointmentReceptionist: '/api/v1/receptionist/appointments/cancel',
    checkPartnerDetailsUpdatable:
      '/api/v1/user/receptionist/patient-partner-details-updatable' /*  /{patientId} */,
    cancelAppointmentsForGlobal:
      '/api/v1/appointment/check-out' /** {appointmentId} */,
    appointmentNotify: '/api/v1/appointment-notify',

    patientCheckIn: '/api/v1/appointment/register/',

    /**-- Junior Doctor ----***/
    getJuniorDoctorAppointments: '/api/v1/junior-doctor/appointments',
    getAppointmentDetailsOfJuniorDoctor: '/api/v1/appointment' /**{id} */,
    getPatientMedicalRecordsInJuniorDoctor:
      '/api/v1/junior-doctor/patient-appointment-files',

    /**--- Financial Counselor */
    getCounselingCompletedAppointments:
      '/api/v1/financial-counselor/completed-appointments' /**{date} */,
    getCounselingPendingAppointments:
      '/api/v1/financial-counselor/pending-appointments' /**{date} */,

    getPatientsListInFinancialCounselor:
      '/api/v1/financial-counselor/appointments' /**{date} */,
    saveFinancialForm: '/api/v1/financial-counselor/appointment/financial-form',
    getProceduresByDepartmentAndRateplan:
      '/api/v1/tariff/procedure/department' /**{departmentId}/{ratePlan} */,
    getPackagesByDepartmentAndRateplan:
      '/api/v1/tariff/package/department' /**{departmentId}/{ratePlan} */,
    patientSearchInFinancialCounselor:
      '/api/v1/financial-counselor/patient-appointment/search',
    getDoctorsBasedOnBranch:
      '/api/v1/doctor/branch' /**{branchId/department/{departmentId}} */,
    getPatientDetailsInFinancialCounselor:
      '/api/v1/financial-counselor/appointment-patient-details' /**{apptId} */,
    payApiInFinancialCounselot:
      '/api/v1/financial-counselor/appointment/payment',
    getOneAppointmentForFinancialCounselor:
      '/api/v1/financial-counselor/appointment' /* /{id} */,
    getOneAppointmentPrint:
      '/api/v1/financial-counselor/est-form/appointment' /* /{id} */,

    getAllCompletedAppointmentsExcel:
      '/api/v1/financial-counselor/completed-appointment/excel',
    getAllPendingAppointmentsExcel:
      '/api/v1/financial-counselor/pending-appointment/excel',

    /** For Payment integration */
    ordersAPIForPayment: '/payment/order',
    paymentUpdate: '/payment/update',

    /** For Call center agent APIs */
    getAllAndCreateAppointmentForCallCenterAgent:
      '/api/v1/caller-agent/appointment/branch', //{branchId},
    getAppointmentsCountForCallCenterAgent:
      '/api/v1/caller-agent/appointments/count',
    getDoctorsScheduleForCallCenterAgent:
      '/api/v1/caller-agent/doctor/schedule',
    getAllSpecilistDoctorsForCallCenter:
      '/api/v1/user/doctor/specialist-doctors',
    getSlotsInCallCenterAgent:
      '/api/v1/slots/doctor' /** {doctorId}/department/{deptId}/branch/{branchId}/date/{date}/{appointmentType} */,
    createAppointmentInCallCenter: '/api/v1/caller-agent/appointment',
    createAppointmentInWidget: '/api/v1/widget/appointment',

    getCallCenterHistoryData:
      '/api/v1/caller-agent/appointment/history' /**{appointmentId} */,

    /** Guest user APIs */
    getGuestUserDetails:
      '/api/v1/guest/user-registration/patientId' /**{patientId}/sequenceId/{sequenceId} */,
    saveGuestUserPatient: '/api/v1/guest/save-registration/patient-register',
    ordersForGuestUser: '/api/v1/guest/order',
    afterPaymentGuestUser: '/api/v1/guest/update',
    confirmationCallCenter: '/api/v1/guest/payment-confirmation',
    redirect: '/api/v1/guest/url-expire/patientId',
    getExistingPatientDetail: '/api/v1/user/patient-details',

    /* Staff Users common APIs */
    getStaffProfile: '/api/v1/user/staff/profile',

    /** Chat - web socket APIS */
    websocketEndPoint: '/thrymr-chatbox-websocket',
    getRecentChatData: '/get-recent-chat-history',
    websocketSuscribeReceiveDirectMsg:
      '/chatbox-subscribe/receive-direct-message/',
    websocketGetDirectChatHistory:
      '/chatbox-destination/get-all-direct-message',
    websocketSendDirectMsg: '/chatbox-destination/send-direct-message',
    chatDoctors: '/api/v1/patient/appointment/doctors',
    saveFile: '/api/v1/mongo/file/save',
    getFileById: '/api/v1/mongo/file-preview/',

    getWaitTime: '/api/v1/emr/queue/get-wait-time/', //{appointmentId}

    /** Notifications */
    getAllNotifications: '/api/v1/notification/all',

    // Dahsboard
    getAllBranchesByLoggedInEmrDoctor: '/api/v1/emrDoctor/dashboard/branches',
    getAllEmrDoctorAppointmentCount:
      '/api/v1/emrDoctor/dashboard/appointmentCount/',
    getAllEmrDoctorAppointments: '/api/v1/emrDoctor/dashboard/appointments/',
    getDoctorDashboardAppointmentsByShiftCount:
      '/api/v1/emrDoctor/dashboard/appointmentByShiftCount/',
    getQueBranchData: '/api/v1/emr/queue/doctor/get-branches',
    getQueShchedueAppointmentData:
      '/api/v1/emr/queue/doctor/scheduled-appointments',
    getQueShchedueAppointmentDataBasedOnBranch:
      '/api/v1/emr/queue/doctor/appointmentCount/',
    getFertilityByAppoitmentId: '/api/v1/emr/fertility/by-appointment',
    getPatientProfileViewByPatientId: '/api/v1/emr/fertility/patient-profile/',
    getAllEmrDoctorProcedure: '/api/v1/emrDoctor/dashboard/procedure',
    getAllEmrDoctorProcedureSeeAll:
      '/api/v1/emrDoctor/dashboard/procedure/seeAll',
    getAllPatientPastAppointmentById:
      '/api/v1/emr/fertility/patient/by-appointment',
    updatePatientHistory: '/api/v1/emr/patient-history/update',
    getDoctorDashboardAppointmentsByShiftCalendar:
      '/api/v1/emrDoctor/dashboard/appointmentByShiftCount',
    rescheduleTeleAppointmentByEmrDoctor:
      '/api/v1/emr/queue/doctor/reschedule-appointment',
    updateAppointmentStatus:
      '/api/v1/emr/queue/doctor/appointment/updateConsultationType/',
    getAppointmentCheckOut: '/api/v1/appointment/check-out/',

    //Emr Consult Master data
    getEmrMasterComplaintAll: '/api/v1/complaint/all',
    getEmrMasterComplaintById: '/api/v1/complaint/',
    saveEmrMasterComplaint: '/api/v1/complaint',
    deleteEmrMasterComplaint: '/api/v1/complaint',
    enableEmrMasterComplaint: '/api/v1/complaint/enable-complaint',

    //Emr Disease Master data
    getEmrMasterDiseaseAll: '/api/v1/disease/all',
    getEmrMasterDiseaseById: '/api/v1/disease/',
    saveEmrMasterDisease: '/api/v1/disease',
    deleteEmrMasterDisease: '/api/v1/disease',
    enableEmrMasterDisease: '/api/v1/disease/enable-disease',

    //Emr Treatment Master data
    getEmrMasterTreatmentTypeAll: '/api/v1/treatmentProcedure/all',
    getEmrMasterTreatmentTypeById: '/api/v1/treatmentProcedure/',
    saveEmrMasterTreatmentType: '/api/v1/treatmentProcedure',
    deleteEmrMasterTreatmentType: '/api/v1/treatmentProcedure',
    enableEmrMasterTreatmentType:
      '/api/v1/treatmentProcedure/enable-treatmentProcedure',

    //Emr Investigation Master data
    getEmrMasterInvestigationAll: '/api/v1/investigation/all',
    getEmrMasterInvestigationById: '/api/v1/investigation/',
    saveEmrMasterInvestigation: '/api/v1/investigation',
    deleteEmrMasterInvestigation: '/api/v1/investigation',
    enableEmrMasterInvestigation: '/api/v1/investigation/enable-investigation',

    //Emr Medicine Master data
    getEmrMasterMedicineAll: '/api/v1/medicine/all',
    getEmrMasterMedicineById: '/api/v1/medicine/',
    saveEmrMasterMedicine: '/api/v1/medicine',
    deleteEmrMasterMedicine: '/api/v1/medicine',
    enableEmrMasterMedicine: '/api/v1/medicine/enable-medicine',

    //Emr SurgicalType Master data
    getEmrMasterSurgicalTypeAll: '/api/v1/surgicalType/all',
    getEmrMasterSurgicalTypeAllDropdown: '/api/v1/surgicalType/all',
    getEmrMasterSurgicalTypeById: '/api/v1/surgicalType/',
    saveEmrMasterSurgicalType: '/api/v1/surgicalType',
    deleteEmrMasterSurgicalType: '/api/v1/surgicalType',
    enableEmrMasterSurgicalType: '/api/v1/surgicalType/enable-surgicalType',
    getPatientHistoryByPatientId: '/api/v1/emr/patient-history',
    getPatientHistoryByAppointmentId:
      '/api/v1/emr/patient-history/appointment/',
    setPatientHistoryByPatientId: '/api/v1/emr/patient-history/update ',

    // Emr Partner Master Data Save and Update
    getPartnerHistoryByPatientId: '/api/v1/emr/partner-history',
    getPartnerHistoryByAppointmentId:
      '/api/v1/emr/partner-history/appointment/',
    setPartnerHistoryByPatientId: '/api/v1/emr/partner-history/update ',

    //Emr Frequency Master data
    getEmrMasterFrequencyAll: '/api/v1/frequency/all',
    getEmrMasterFrequencyById: '/api/v1/frequency/',
    saveEmrMasterFrequency: '/api/v1/frequency',
    deleteEmrMasterFrequency: '/api/v1/frequency',
    enableEmrMasterFrequency: '/api/v1/frequency/enable-frequency',

    //Emr Route Master data
    getEmrMasterRouteAll: '/api/v1/route/all',
    getEmrMasterRouteById: '/api/v1/route/',
    saveEmrMasterRoute: '/api/v1/route',
    deleteEmrMasterRoute: '/api/v1/route',
    enableEmrMasterRoute: '/api/v1/route/enable-route',

    //Emr Consultation Services
    saveEmrConsultation: '/api/v1/consultation',
    // saveEmrPartnerConsultation:'/api/v1/consultation/partner',
    saveEmrFavouriteDrugs: '/api/v1/consultation/saveFavouriteDrugs',
    saveEmrFavouriteInvestigations:
      '/api/v1/consultation/saveFavouriteInvestigations',
    getEmrFavouriteDrugs: '/api/v1/consultation/favouriteDrugs',
    getEmrFavouriteInvestigations:
      '/api/v1/consultation/favouriteInvestigations',
    getEmrConsultationDoctors: '/api/v1/consultation/referralDoctors',
    getAllConsultationHistoryByPatientIdAndAppointmentId:
      '/api/v1/consultation/history/appointment/',
    getAllergyMedicinesByPatientId:
      '/api/v1/emr/patient-history/allergyMedicines/',
    getAllConsultationHistoryByAppointmentId:
      '/api/v1/emrUser/patient/history/',

    //reference doctor
    getEmrReferralDoctorEnable:
      '/api/v1/emrUser/referralDoctor/enable-referralDoctor',

    //Emr Donor Profile Services
    saveEmrDonorProfile: '/api/v1/donorProfile',
    getEmrAllDonorProfiles: '/api/v1/donorProfile/all',
    getEmrDonorProfileById: '/api/v1/donorProfile/',
    deleteEmrDonorProfileById: '/api/v1/donorProfile/',
    getEmrAllDonorProfilesAllotedToPatient: '/api/v1/donorProfile/all/',
    enableEmrDonorProfile: '/api/v1/donorProfile/enable-donorProfile',
    linkEmrDonorProfileToPatient: '/api/v1/donorProfile/link-patient',

    clearNotificationsCount: '/api/v1/notification/all/mark-read',

    // EMR ALL PATIENT
    getAllEmrPatientReuqestData: '/api/v1/emrUser/patient/all',
    getAllEmrPatientCount: '/api/v1/emrUser/patient/count',

    // EMR MASTER DATA FOR DROPDOWNS
    getOutCome: '/api/v1/outcome/all',
    getRelationShipRequestData: '/api/v1/relationship/all',
    getTypeOfConceivingRequestData: '/api/v1/typeOfConceiving/all',
    getGenderRequestData: '/api/v1/gender/all',
    getDeliveryRequestData: '/api/v1/delivery/all',
    getDeliveryTypeRequestData: '/api/v1/deliveryType/all',
    getMarriageReuqestData: '/api/v1/marriedLife/all',
    getAllStatusrequestDataForAll: '/api/v1/statusType/all',
    getAncillaryRequestData: '/api/v1/ancillaryServices/all',

    //emr chat services
    getAllDoctorPatientsForEmrChat:
      '/api/v1/emr-chat/doctor/appointment/patients',
    getAllPatientDoctorsForEmrChat:
      '/api/v1/emr-chat/patient/appointment/doctors',
    getAllDoctorJuniorDoctorsForEmrChat:
      '/api/v1/emr-chat/doctor/appointment/junior-doctors',
    getChatUserDetails: '/api/v1/user/',
    suggestMedicineUploadByPatientUserId:
      '/api/v1/consultation/prescription/save',
    getUsgPelvicData: '/api/v1/usgPelvic/get',
    getUsgPelvicPreviousData: '/api/v1/usgPelvic/previous/get',
    savePelvicData: '/api/v1/usgPelvic/save',
    saveFollicularAddScan: '/api/v1/FollicularScan/addScan/saveFollicularScan',
    getConsultAllCompletedTreatments:
      '/api/v1/consultation/completeTreatments/patient',
    // getRecentChatProfilePics: '/api/v1/emr-chat/profile-pictures',
    emrChatDoctors: '/api/v1/emr-chat/patient/appointment/doctors',
    getConsultationDataByPatientId: '/api/v1/consultation/all',
    getConsultationMedicationViewProfile:
      '/api/v1/consultation/view/profile/medication',
    constultationProfileUploadById:
      '/api/v1/consultation/investigation/fileUpload/',
    constultationAllInvestigationsByPatientId:
      '/api/v1/consultation/investigation/all/patient/',
    constultationAllInvestigationsByPatientUserId:
      '/api/v1/consultation/investigation/all/user/',

    // Emr Ancillary Service Master Data
    getEmrAncillarySevicesForDroDown: '/api/v1/ancillaryServices/all',

    // Token Management

    getDisplayScreens: '/api/v1/display-screen/',

    saveTokenScreens: '/api/v1/screen',

    getAllScreens: '/api/v1/screen/all',

    getScreenbyId: '/api/v1/screen/',

    startConsultaion: '/api/v1/start-patient-consultation/',

    // TreatMent History Service
    getTreatMentConsultationAllInvestigationHistoryDataByPatientId:
      '/api/v1/consultation/investigation/patient/',
    getTreatMentConsultationTreatmentAllHistoryDataByPatientId:
      '/api/v1/consultation/treatments/patient/',
    updateTreatmentConsultHistory: '/api/v1/consultation/investigation/update',
    suggestedTreatmentConsultHistoryUpdate:
      '/api/v1/consultation/treatments/update',
    getReportBasedOnInvestigationId:
      '/api/v1/consultation/investigation/reports',
    investigationStatusUpdateByIdAndStatus:
      '/api/v1/consultation/investigation/',
    //Nurse Login
    allOutPatientTreatmentInjectionCount: '/api/v1/emrNurse/patient/count',
    allOutPatientTreatmentInjectionData: '/api/v1/emrNurse/patient/all',
    allOutPatientTreatmentInjectionDownload:
      '/api/v1/emrNurse/patient/download',
    administerInjectionDrugs: '/api/v1/emrNurse/patient/injection/administer',
    getDoctorDataForFilterInjectionData:
      '/api/v1/emrNurse/doctor/injection/data',

    // Posted Procedure
    getAllPostedProcedureData: '/api/v1/emrScheduler/procedures/all',
    getConsultationTreatmentForStartingCycle:
      '/api/v1/consultation/treatments/',
    closeConsultationTreatmentCycle:
      '/api/v1/consultation/treatment/close-cycle/',
    confirmConsultationTreatmentForCycle:
      '/api/v1/consultation/treatments/confirm/cycle',

    getOnePatientDetailsByUserId: '/api/v1/user/patient-details/user/',
    getOneTreatmentPlanById: '/api/v1/consultation/treatmentPlan/',

    // Treatment Schedular
    saveStimulationDrugs: '/api/v1/consultation/stimulation/drugs/save',
    saveTriggerDrugs: '/api/v1/consultation/trigger/drugs/save',
    updateStimulationDrug: '/api/v1/consultation/stimulation/drugs/update',
    updateTriggerDrug: '/api/v1/consultation/trigger/drugs/update',
    deleteStimulationDrugByTreatmentPlanIdAndStimulationId:
      '/api/v1/consultation/stimulationDrug/',
    deleteFollicularScanByTreatmentPlanIdAndStimulationId:
      '/api/v1/consultation/follicularScan/',
    deleteTriggerDrugByTreatmentPlanIdAndTriggerId:
      '/api/v1/consultation/triggerDrug/',
    getFollicularScanCycleDataByTreatmentId:
      '/api/v1/consultation/treatmentPlan/follicularScanCycle/',
    getStimulationCycleDataByTreatmentId:
      '/api/v1/consultation/treatmentPlan/stimulationDrugCycle/',
    getTriggerCycleDataByTreatmentId:
      '/api/v1/consultation/treatmentPlan/triggerDrugCycle/',
    getOpuCycleDataByTreatmentId:
      '/api/v1/consultation/treatmentPlan/inSchedulerCycle/',
    saveFollicularScan: '/api/v1/consultation/saveFollicularScan',
    saveInScedular: '/api/v1/consultation/treatmentPlan/inScheduler/save',
    updateInSchedulerStatusById:
      '/api/v1/consultation/treatmentPlan/inScheduler/statusUpdate/',
    getTreatmentAndPatientDetailsByIds:
      '/api/v1/consultation/treatmentPlan/details/',
    updateTreatmentPlanWithICU2Start:
      '/api/v1/consultation/treatmentPlan/updateIUI2Status/',
    searchsimulationDrug:
      '/api/v1/consultation/treatmentPlan/stimulation/search',
    postOpuUpdatedByTreatmentId:
      '/api/v1/consultation/treatmentPlan/triggerDrugPostOpu/',
    // Andrology Services
    getAllAndrologyLabView: '/api/v1/labTechnician/treatment/andrology/labView',
    getAllAndrologyProcedure:
      '/api/v1/labTechnician/treatment/andrology/procedure	',
    getAndrologyCountBySelectedDate:
      '/api/v1/labTechnician/treatment/andrology/procedure/count',
    getAndrologyViewProfileWithInSchedulerById:
      '/api/v1/labTechnician/treatment/andrology/view/profile/',
    saveMaleProcedure: '/api/v1/consultation/treatments/confirm/male/procedure',
    maleStartConsultation: '/api/v1/consultation/treatments/male/start/',
    saveOrUpdateAndrologyViewProfile:
      '/api/v1/labTechnician/treatment/andrology/viewProfile/save',
    getAllLabviewWeekDays: '/api/v1/labTechnician/weekdays/',
    getStaffUserBranches: '/api/v1/labTechnician/branches',
    getAllLabs: '/api/v1/lab/all',
    saveLab: '/api/v1/lab',
    enableDisableLab: '/api/v1/lab/enable-lab',
    deleteLab: '/api/v1/lab',
    getOneLab: '/api/v1/lab',
    getActiveLabBranches: '/api/v1/branch/active',

    // Ambreo Service
    getAllEmbryologyLabView:
      '/api/v1/labTechnician/treatment/embryology/labView',
    getAllEmbryologyOpuOrEtAndFet:
      '/api/v1/labTechnician/treatment/embryology/opu-et',
    getAllEmbryologyOpuByDate: '/api/v1/lab/treatments/embryology/opu',
    getAllEmbryologyetAndFetByDate:
      '/api/v1/lab/treatments/embryology/etAndFet',
    getTreatmentCycleData: '/api/v1/consultation/treatmentPlan/cycleData',
    getAllTreatmentCalendarDataByTreatmentId:
      '/api/v1/consultation/treatmentPlan/patientCalender/',
    getEmbryologyViewProfileByEmbryoTransferId:
      '/api/v1/labTechnician/treatment/embryology/viewProfile/',
    saveOrUpdateEmbryologyViewProfile:
      '/api/v1/labTechnician/treatment/embryology/viewProfile/save',
    getEmbryologyLabViewCountBySelectedDate:
      '/api/v1/labTechnician/treatment/embryology/labView/count/',
    deleteLabConsentForm: '/api/v1/labConsentForm/',
    getOneLabConsentFormById: '/api/v1/labConsentForm/',
    saveLabConsentForm: '/api/v1/labConsentForm',
    getAllLabConsentForms: '/api/v1/labConsentForm/all',
    enablLabConsentForm: '/api/v1/labConsentForm/enable-labConsentForm',
    saveEmbryoTransfer: '/api/v1/consultation/treatmentPlan/embryology/save',
    saveOutcome: '/api/v1/consultation/treatmentPlan/outcome/save',

    getAllEmbryoMonitoringDayWise:
      '/api/v1/labTechnician/treatment/embryoMonitoring/dayWise',
    saveOrUpdateEmbryoMonitoringViewProfile:
      '/api/v1/labTechnician/treatment/embryo/monitoring/save',
    getEmbryoMonitoringCountBySelectedDate:
      '/api/v1/labTechnician/treatment/embryoMonitoring/count/',

    //OT Manager Services
    getAllOtManagerDoctorsListForFilter:
      '/api/v1/otManager/inScheduler/doctors',
    getAllOtManagerLabListForFilter: '/api/v1/otManager/inScheduler/theatres',
    getAllOtManagerBranches: '/api/v1/otManager/branches',
    getAllOtManagerWeekViewDays: '/api/v1/otManager/weekdays/',
    getOtManagerDataCount: '/api/v1/otManager/manageScheduler/count',
    getOtManagerData: '/api/v1/otManager/manageScheduler/data',
    getOtManagerViewSchedulerDataDayMode:
      '/api/v1/otManager/viewScheduler/dayMode',
    getOtManagerViewSchedulerDataWeekMode:
      '/api/v1/otManager/viewScheduler/weekMode',
    getOtManagerDataDownload: '/api/v1/otManager/manageScheduler/data/download',
    getAllOtManagerRoleBasedUsers:
      '/api/v1/otManager/manageScheduler/role/users',
    postOtManagerCase: '/api/v1/otManager/scheduler/save',
    getAllOtManagerResourceRoles: '/api/v1/otManager/roles',
    uploadOtmanagerConsentForms: '/api/v1/otManager/labConsentForm/upload',
    downloadOtManagerConsentFormsByTreatmentId:
      '/api/v1/labConsentForm/by-treatmentId/',
    deleteResourceFromInScheduler: '/api/v1/otManager/scheduler/resource/',
    saveNewProcedureInScheduler: '/api/v1/otManager/procedure/save',
    getOtManagerProfileData: '/api/v1/user/staff/profile',

    // follicular scan services for specialist doctor

    getFollicularScan: '/api/v1/FollicularScan/get',
    getPreviousFollicularScan:
      '/api/v1/FollicularScan/previousFollicularScan/get',
    saveFollicularScan1: '/api/v1/FollicularScan/addScan/saveFollicularScan',
    getAllPatientInvestigationsByStatus:
      '/api/v1/labTechnician/treatment/investigation/all',
    getAllPatientInvestigationsByStatusAndDate:
      '/api/v1/labTechnician/treatment/investigation/all/dateWise',
    fileUploadForConsultationInvestigationByInvestigationId:
      '/api/v1/consultation/investigation/fileUpload/',

    // SemenDetails For Specialist Doctor
    getPreviousSemenDetails: '/api/v1/previousSemen/details/get',
    getSemenDetailsByInSchedulerId: 'api/v1/semen/details/get/ViewDetails/',
    setSemenDetailsFreeze: 'api/v1/semen/details/freeze/',

    //Embryo Monitoring
    embryoMonitoringCount:
      '/api/v1/labTechnician/treatment/embryoMonitoring/count/', //{selectedDate}
    embryoMonitoringDayWise:
      '/api/v1/labTechnician/treatment/embryoMonitoring/dayWise',
    embryoMonitoringSave:
      '/api/v1/labTechnician/treatment/embryo/monitoring/save',
    femaleProcedureCheckIn:
      '/api/v1/labTechnician/treatment/embryology/check-in/',
    getQrCodeEmbryoOpu: '/api/v1/labTechnician/embryology/qr_code/opu_fet/',
    getQrCodeEmbryoFet: '/api/v1/labTechnician/embryology/qr_code/fet/',

    // Andrology Monitoring
    androMonitoringDayWise:
      '/api/v1/labTechnician/treatment/andrology/procedure',
    maleProcedureCheckIn: '/api/v1/labTechnician/treatment/andrology/check-in/',
    eventSummary: '/api/v1/consultation/event/planner/',
    getQrCodeAndro: '/api/v1/labTechnician/andrology/procedure/qr_code/',

    updateMrNumberToPatient: '/api/v1/user/update-patient-mr-number',
    associatePartner: '/api/v1/user/update-patient-partner-relation',
// print prescription for the chat
    printPrescription: '/api/v1/patient-medicines-form',
    patientChatFilesSave:"/api/v1/save-chat-reports"
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) { }

  /**
   *
   * For getting data from Backend - to call GET method
   */

  getService(url: string, params?: any) {
    return this.http
      .get(this.baseUrl + url, { params: params })
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  getPdfService(url: string, params?: any) {
    return this.http
      .get(this.baseUrl + url, {
        params: params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * For retrieving data from Backend - to call POST method
   */
  postService(url, data) {
    return this.http
      .post(this.baseUrl + url, data)
      .pipe(
        map(
          (response: any) => {
            return response;
            // if (response.statusCode == 200) {
            // } else {
            //   this.handleError(response);
            // }
          },
          (error) => {
            console.log('in get server', error);
          }
        )
      )
      .pipe(catchError(this.handleError));
  }

  fileUploadService(url, data) {
    return this.http
      .post(this.baseUrl + url, data)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error) => {
            console.log('in get server', error);
          }
        )
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * method for calling Put Service Api
   */
  putService(url, data) {
    return this.http
      .put(this.baseUrl + url, data)
      .pipe(
        map((response: any) => {
          if (response.statusCode === 200) {
            return response;
          } else {
            this.handleError(response);
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  patchService(url, data) {
    return this.http
      .patch(this.baseUrl + url, data)
      .pipe(
        map((response: any) => {
          if (response.statusCode === 200) {
            return response;
          } else {
            this.handleError(response);
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * method for calling delete Service Api
   */ deleteService(url) {
    return this.http
      .delete(this.baseUrl + url)
      .pipe(
        map((response: any) => {
          if (response.statusCode === 200) {
            return response;
          } else {
            this.handleError(response);
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * method for handling error in api calls
   */

  private handleError(error: Response | any) {
    if (error.error && error.status == 401) {
      localStorage.clear();
      window.location.href = '';
    }
    if ((error.status && error.status === 401) || error.status === 403) {
      if (error.error) {
        error.error.message = error.error.message
          ? error.error.message
          : 'You are not able to access this request';
      } else {
        error.message = 'You are not able to access this request';
      }
    } else if (
      error.status === 0 ||
      error.status === 400 ||
      error.status === 500
    ) {
      if (error.error) {
        error.error.message = error.error.message
          ? error.error.message
          : 'Something went wrong, please try again after some time';
      } else {
        error.meassage =
          'Something went wrong, please try again after some time';
      }
    }
    return throwError(error);
  }
}
