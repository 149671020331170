export class PaymentOrder {
    amount?:any = 0;
    appointmentId?:any =  0;
    currency?:string =  "INR";
    paymentType?:string =  "";
    razorpayOrderId?:string =  "";
    razorpayPaymentId?:string =  "";
    razorpaySignature?:string =  "";
    appointmentPaymentType?:string =  "";
    slotId?:any = 0;
}