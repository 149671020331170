import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userId = JSON.parse(localStorage.getItem('userId'));
    let otp = JSON.parse(localStorage.getItem('otpVerified'));
    if (userId && otp) {
      console.log('in guard---->');
      return true;
    }
    console.log('in gurad--> else');
    this.router.navigateByUrl('/');
    return false;
  }
}
