export class UserDetails {

        j_password?: string=''
     
        // j_username?: temp = new temp();

        j_username?: string = '';
}

// export class temp {
//         countryCode ?: string = '';
//         dialCode?: string = '';
//         e164Number?: string = '';
//         internationalNumber?: string = '';
//         nationalNumber?: string = '';
//         number?: string = ''

// }