<app-loader *ngIf="isLoading"></app-loader>
<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form autocomplete="off" #registrationForm="ngForm" class="form-signin" (ngSubmit)="onSubmit(registrationForm)">
                    <div class="form-group">
                        <label for="firstName">First Name&nbsp;<span class="text-danger">*</span></label>
                        <input autocomplete="off" type="text" name="firstName" id="firstName" required 
                             #firstNameValidation="ngModel"
                            [ngClass]="{ 'is-invalid': (firstNameValidation.errors) && forSubmit }"
                            [(ngModel)]="patientObj.firstName" class="form-control">
                        <div class="invalid-feedback">
                            <div class="text-danger"
                                *ngIf="forSubmit && firstNameValidation.errors && firstNameValidation.errors.required">
                                First name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lastName">Last Name&nbsp;<span class="text-danger">*</span></label>
                        <input autocomplete="off" type="text" name="lastName" id="lastName" required 
                             #lastNameValidation="ngModel"
                            [ngClass]="{ 'is-invalid': (lastNameValidation.errors) && forSubmit }"
                            [(ngModel)]="patientObj.lastName" class="form-control">
                        <div class="invalid-feedback">
                            <div class="text-danger"
                                *ngIf="forSubmit && lastNameValidation.errors && lastNameValidation.errors.required">
                                Last name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="mobile">Telephone Number&nbsp;<span class="text-danger">*</span></label>
                        <ngx-intl-tel-input [cssClass]="'custom form-control'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                             [enablePlaceholder]="false"  
                             [searchCountryFlag]="true"
                             [separateDialCode] = "true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                             [selectedCountryISO]="CountryISO.India"
                             [maxLength]="15"
                            id="mobile" (keyup)="select($event,registrationForm)" name="phone" [(ngModel)]='mobileNumber'>
                        </ngx-intl-tel-input>
                        <div class="custom-invalid-feedback">
                            <div class="text-danger"
                                *ngIf="(forSubmit  && (mobileNumber == null || mobileNumber == ''))">
                                Telephone number is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" 
                        [(ngModel)]="patientObj.email" (change)="onEnterEmail($event)"
                        [ngClass]="{ 'is-invalid': forSubmit && emailVal.errors }"
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" name="email" id="email" #emailVal="ngModel"
                         />
                         <div class="invalid-feedback">
                           <span class="text-danger" *ngIf="forSubmit && emailVal.errors && emailVal.errors.pattern ">
                            Email is  not valid
                           </span>
                         </div>
                      </div>
                    <div class="form-group">
                        <label for="password">Password&nbsp;<span class="text-danger">*</span></label>
                        <input autocomplete="off" type="password" name="password" id="password" required minlength="6"
                            (change)="onEnterPassword($event)" #passwordValidation="ngModel"
                            [ngClass]="{ 'is-invalid': (passwordValidation.errors && forSubmit) }"
                            [(ngModel)]="patientObj.password" class="form-control">
                        <div class="invalid-feedback">
                            <div class="text-danger"
                                *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.required">
                                Password is required
                            </div>
                            <div class="text-danger"
                                *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.minlength">
                                Password should be atleast 6 characters minimum
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="confirmPassword">Confirm Password&nbsp;<span class="text-danger">*</span></label>
                        <input autocomplete="off" type="password" name="confirmPassword" id="confirmPassword" required minlength="6"
                            (keyup)="onEnterConfirmPassword($event)" #confirmPasswordValidation="ngModel"
                            [ngClass]="{ 'is-invalid': ((confirmPasswordValidation.errors && forSubmit) || forMatch) }"
                            [(ngModel)]="patientObj.confirmPassword" class="form-control">
                        <div class="invalid-feedback">
                            <div class="text-danger"
                                *ngIf="(forSubmit && confirmPasswordValidation.errors && 
                                confirmPasswordValidation.errors.required)">
                                Confirm password is required
                            </div>
                            <div class="text-danger"
                                *ngIf="(forSubmit && confirmPasswordValidation.errors && 
                                confirmPasswordValidation.errors.minlength)">
                                Confirm password should be atleast 6 characters minimum
                            </div>
                            <div class="text-danger"
                                *ngIf="(patientObj.confirmPassword &&  forMatch && forSubmit)">
                                Confirm password does not match with Password 
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Save</button>
                    </div>
                    <div class="text-right register-link">
                       <a class="cursor-pointer hyper-link" (click)="login()">Back to Login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>