import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let currentUser = JSON.parse(localStorage.getItem('currentUser')); 
      // console.log("currentUser..",currentUser);
       if(currentUser && currentUser.jwtToken){
          return true;
       }
       
       this.router.navigateByUrl('/');
       return false;
  }

}
