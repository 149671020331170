import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../Utils/common/common.service';
declare var $: any;

enum UserRole {
  PATIENT = 'Patient',
  SYSTEM_ADMIN = 'SystemAdmin',
  SPECIALIST_DOCTOR = 'Doctor',
  RECEPTIONIST = 'Receptionist',
  JUNIOR_DOCTOR = 'JuniorDoctor',
  FINANCIAL_COUNSELOR = 'FinancialCounselor',
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  width;
  userName = '';
  userRole: any = '';

  imageURL = 'assets/img/new-hegde-logo.png';
  userImage: any = '';
  permissions: any = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService
  ) { }
  isCollapse = false;

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('currentUser'));
    const permissionsData = JSON.parse(localStorage.getItem('permissionsData'));
    this.permissions = permissionsData.menu;

    if (data) {
      this.userName = data.firstName + ' ' + data.lastName;
      this.userRole = data.roleName === 'SYSTEM_ADMIN' ? 'System Admin' : '';
      this.userImage = data.filePerviewUrl || '';
    }
    if (localStorage.getItem('brand')) {
      let image = JSON.parse(localStorage.getItem('brand'));
      if (image) {
        this.imageURL = image;
      }
    }

    $.getScript('/assets/js/app.js');

    $(document).ready(() => {
      this.width = screen.width;
    });
  }

  logout() {
    this.commonService.logout();
  }

  toggle() {
    if (this.width > 992) {
      $(document).ready(() => {
        if ($('body').hasClass('mini-sidebar')) {
          $('body').removeClass('mini-sidebar');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').addClass('mini-sidebar');
          $('.subdrop + ul').slideUp();
        }
        $(document).on('mouseover', (e) => {
          e.stopPropagation();
          if (
            $('body').hasClass('mini-sidebar') &&
            $('#toggle_btn').is(':visible')
          ) {
            var targ = $(e.target).closest('.sidebar').length;
            if (targ) {
              $('body').addClass('expand-menu');
              $('.subdrop + ul').slideDown();
            } else {
              $('body').removeClass('expand-menu');
              $('.subdrop + ul').slideUp();
            }
          }
        });
      });
    }
  }

  /**
   * Change password screen navigation
   */
  changePassword() {
    
    this.router.navigateByUrl('/system-admin/change-password');
  }

}
