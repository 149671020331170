// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  // For Local code
  // baseUrl:"http://65.1.181.108:9050",


  // baseUrl: 'http://localhost:9035',

  // This is For Local
  baseUrl : '/app/',
  

  // This is For Dev
  //  baseUrl: '/projects/hegde/backend',

  // This is For Dev
  //baseUrl: '/projects/hegde/backend',

  // This is For Client
  //  baseUrl: '/projects/hegde/backend/client/',

  //  This is for AWS client port
  //  baseUrl: 'http://15.206.2.14:8080',

  firebase: {

    apiKey: 'AIzaSyBspA7z4Yi9-azmBZqLFxlRc8Pzk6yiudA',
    authDomain: 'hegde-hms-6d40f.firebaseapp.com',
    databaseURL: 'https://hegde-hms-6d40f.firebaseio.com',
    projectId: 'hegde-hms-6d40f',
    storageBucket: 'hegde-hms-6d40f.appspot.com',
    messagingSenderId: '32818373110',
    appId: '1:32818373110:web:99a6d6bbf4b9ea5523e7d7',
    measurementId: 'G-D2TKMY1SGZ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
