import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'convertMonthToNameFormat'})
export class MonthFormatChangePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }
    if(value==='N/A'){
      return 'N/A';
    }
    let date;
    let month ;
    let year ;
    
    if(value.indexOf("-")!==-1){
      date = (value.split('-'))[0];
      month = (value.split('-'))[1];
      year = (value.split('-'))[2];
    }else if(value.indexOf("/")!==-1){
      date = (value.split('/'))[0];
      month = (value.split('/'))[1];
      year = (value.split('/'))[2];
    }

    

    if(month=="01"){
      month="January";
    }else if(month=="02"){
      month="February";
    }else if(month=="03"){
      month="March";
    }else if(month=="04"){
      month="April";
    }else if(month=="05"){
      month="May";
    }else if(month=="06"){
      month="June";
    }else if(month=="07"){
      month="July";
    }else if(month=="08"){
      month="August";
    }else if(month=="09"){
      month="September";
    }else if(month=="10"){
      month="October";
    }else if(month=="11"){
      month="November";
    }else if(month=="12"){
      month="December";
    }
    return `${date} ${month} ${year}`
  }
}