<app-loader *ngIf="isLoading"></app-loader>
<div class="content">
    <div class="row">
        <div class="col-lg-12 text-left">
            <a class="back-button text-grey" [routerLink]="['/doctor/schedule/leaves']" >
                <i class="fa fa-chevron-left"></i> Back</a>  
        </div>
        <div class="col-lg-8 offset-lg-2">
            <h4 class="page-title">Apply Leave</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <select name="dateType" class="form-control" id="dateType" [(ngModel)]="dateType" name="date-type" (change)="onTypeChange($event)">
                                <label>Select Date Type <span class="text-danger">*</span></label>
                                <option value="">Select Type </option>
                                <option value="date">Date</option>
                                <option value="Date Range">Date Range</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group" *ngIf="dateType == 'date'">
                            <label>Select Date <span class="text-danger">*</span></label>
                            <div class="">
                                <p-calendar  dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="{{fromFromYear}}:{{lastFromYear}}"
                                [(ngModel)]="selectedDate"
                                name="selected-date"
                                ></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Start Time <span class="text-danger">*</span></label>
                            <div class="time-icon">
                                <p-calendar  [timeOnly]="true" class="timer" hourFormat="24"
                                [(ngModel)]="startTime"
                                (onSelect)="onStartTimeSelect($event)"
                                name="start-time"
                                ></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>End Time <span class="text-danger">*</span></label>
                            <div class="time-icon">
                                <p-calendar  [timeOnly]="true" class="timer" hourFormat="24"
                                [(ngModel)]="endTime"
                                (onSelect)="onEndTimeSelect($event)"
                                name="end-time"
                                ></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group" *ngIf="dateType == 'Date Range'">
                            <label>Select Date Range <span class="text-danger">*</span></label>
                            <div class="">
                                <p-calendar  dateFormat="dd/mm/yy" [monthNavigator]="true" selectionMode="range" [yearNavigator]="true" yearRange="{{fromFromYear}}:{{lastFromYear}}"
                                 [(ngModel)]="rangeDates"
                                 name="range-picker"
                                 (onSelect)="onChangeRangeDate($event)"
                                ></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group" >
                            <label>Reason <span class="text-danger">*</span></label>
                                <textarea type="text" class="form-control"
                                [(ngModel)]="doctorLeaveData.reason"
                                name="reason" id="reason"></textarea>
                        </div>
                      
                    </div>
                </div>

                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn capital-case" (click)="onClickApplyLeave($event)" >Apply Leave</button>
                </div>
            </form>
        </div>
    </div>
</div>