<div class="content">
    <div class="container-fluid">
        
        <div class="row">
            <div class="col-md-8 offset-md-2">
                
                <!-- Login Tab Content -->
                <div class="">
                    <div class="row align-items-center justify-content-center">

                        <div class="col-md-12 col-lg-6 login-right">
                            <div  class="account-logo">
                                <a  class="cursor-pointer">
                                <img  src="assets/img/logo-hegde.png" alt=""></a>
                            </div>              
                            <div class="login-header text-center">
                                <h3>Login <span>Hegde Hospital</span></h3>
                            </div>

                            <form autocomplete="off" #loginForm="ngForm" class="form-signin" (ngSubmit)="onSubmit(loginForm)">
                                <div class="logo">
                                </div>

                                <div class="form-group">
                                    <label for="mobile">Mobile Number</label>
                                    <ngx-intl-tel-input [cssClass]="'custom form-control'"
                                    [separateDialCode] = "true" [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true" [enablePlaceholder]="false"  [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                                        id="mobile" (keyup)="select($event)" name="phone" 
                                        [(ngModel)]='userDetails.j_username'>
                                    </ngx-intl-tel-input>
                                    <div class="custom-invalid-feedback">                                        
                                        <div class=" alert text-danger"
                                            *ngIf="(forSubmit  && (userDetails.j_username == null || userDetails.j_username == '')) ||
                                            (forOtpSubmit && (userDetails.j_username == null || userDetails.j_username == ''))">
                                            Mobile number is required
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input autocomplete="off" type="password" name="password" id="password" required minlength="6"
                                        (change)="onEnterPassword($event)" #passwordValidation="ngModel"
                                        [ngClass]="{ 'is-invalid': (passwordValidation.errors) && forSubmit }"
                                        [(ngModel)]="userDetails.j_password" class="form-control">
                                    <div class="invalid-feedback">
                                        <div class="text-danger"
                                            *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.required">
                                            Password is required
                                        </div>
                                        <div class="text-danger"
                                            *ngIf="forSubmit && passwordValidation.errors && passwordValidation.errors.minlength">
                                            Password should be atleast 6 characters minimum
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-right">
                                    <a class="hyper-link" (click)="forgetPassword()">Forgot your password?</a>
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn-primary btn">Password Login</button>
                                    <p class="text-center mt-3">Or</p>
                                    <button type="button" class="btn btn-primary btn" (click)="logInWithOtp()">OTP Login</button>
                                </div>
                                <div class="text-center register-link">
                                     <a class="alert alert-primary-light" (click)="patientLogin()"><strong>~Switch To Patient Login</strong></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- /Login Tab Content -->
                    
            </div>
        </div>

    </div>

</div>		




<div class="main-wrapper wrapper">
    <div class="page">
        <div class="center">
            <div class="box">
                      </div>
        </div>
    </div>
</div>