import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInvitePatientGuardService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log('currentUser..', currentUser);
    if (currentUser && currentUser.jwtToken) {
      if (currentUser.roleName === 'PATIENT') {
        this.router.navigateByUrl('/patient');
      }
      return false;
    }

    return true;
  }
}
