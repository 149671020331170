<app-loader *ngIf="isLoading"></app-loader>
<div class="main-wrapper">
  <div class="header">
    <div class="header-left">
      <a href="index.html" class="logo">
        <img [src]="imageURL" class="logo-size" width="35" height="35" alt="" />
      </a>
    </div>
  </div>
  <div class="content mar-top-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12 text-left">
          <a class="back-button text-grey" *ngIf="isRedirect" (click)="back()">
            <i class="fa fa-chevron-left"></i> Back</a
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 text-center pad-top-100">
        <h4 class="mb-2">
          Doctor Name : {{ doctorName ? (doctorName | titlecase) : "-" }}
        </h4>
        <p>
          <b>Appointment Date : </b> {{ slotDate }} ( {{ startTime }} -
          {{ endTime }} )
        </p>
        <p><b>Consultation Fee :</b> Rs {{ getAmount() }}</p>
      </div>
      <div
        class=""
        *ngIf="guestUser"
        [ngClass]="
          maritalStatusStr ? 'col-lg-5 offset-lg-1' : 'col-lg-8 offset-lg-2'
        "
      >
        <form #personalProfileForm="ngForm">
          <h4 class="page-title">Personal Information</h4>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  First Name :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <div class="input-group">
                    <span class="input-group-addon">
                      <select
                        class="form-control"
                        name="salutationId"
                        [(ngModel)]="patientDetails.user.salutationId"
                        #personalSalutation="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid': forPersonalSubmit && personalSalutation.errors
                        }"
                      >
                        <option disabled>Salutation</option>
                        <option
                          *ngFor="let data of salutationsList"
                          [value]="data.id"
                        >
                          {{ data.name }}
                        </option>
                      </select>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      name="personal-firstName"
                      id="personal-firstName"
                      required
                      #personalFirstName="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          forPersonalSubmit && personalFirstName.errors
                      }"
                      [(ngModel)]="patientDetails.user.firstName"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        forPersonalSubmit &&
                        personalFirstName.errors &&
                        personalFirstName.errors.required
                      "
                    >
                      <span class="text-danger"> Please enter first name with salutation </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Last Name :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <input
                    type="text"
                    class="form-control"
                    required
                    #personalLastName="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && personalLastName.errors
                    }"
                    name="personal-lastName"
                    id="personal-lastName"
                    [(ngModel)]="patientDetails.user.lastName"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      forPersonalSubmit &&
                      personalLastName.errors &&
                      personalLastName.errors.required
                    "
                  >
                    <span class="text-danger"> Please enter last name </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Date of Birth :
                </div>
                <div class="contact-name col-sm-4 pad-10" *ngIf="isEdit">
                  <p-calendar
                    dateFormat="dd/mm/yy"
                    [monthNavigator]="true"
                    name="personal-dob"
                    id="personal-dob"
                    #dob="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && dob.errors
                    }"
                    [(ngModel)]="patientDetails.user.dob"
                    (onSelect)="dobPersonalSelect($event)"
                    [maxDate]="maxDate"
                    readonlyInput="true"
                    [yearNavigator]="true"
                    yearRange="{{ fromFromYear }}:{{ lastFromYear }}"
                  >
                  </p-calendar>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit && patientDetails.user.dob == null
                      "
                    >
                      Please select date of birth
                    </div>
                  </div>
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-1 pad-10"
                  *ngIf="isEdit"
                >
                  Age :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-3 pad-10"
                  *ngIf="isEdit"
                >
                  <input
                    type="text"
                    class="form-control align-age"
                    readonly
                    name="personal-age"
                    id="personal-age"
                    [(ngModel)]="patientDetails.user.age"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Gender :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        name="personal-gender"
                        value="male"
                        [(ngModel)]="patientDetails.user.gender"
                        class="form-check-input"
                        (click)="radioSelected('male')"
                      />Male
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        name="personal-gender"
                        value="female"
                        [ngClass]="{
                          'is-invalid': forPersonalSubmit && personalGender.errors
                        }"
                        #personalGender="ngModel"
                        [(ngModel)]="patientDetails.user.gender"
                        class="form-check-input"
                        (click)="radioSelected('female')"
                     req />Female
                    </label>
                  </div>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.gender == null ||
                        patientDetails.user.gender == '')
                      "
                    >
                      Please Choose Gender
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Occupation :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <input
                    type="text"
                    class="form-control"
                    name="personal-occupation"
                    id="personal-occupation"
                    [(ngModel)]="patientDetails.occupation"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Marital Status :
                </div>

                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        name="personal-maritalStatus"
                        [(ngModel)]="patientDetails.user.maritalStatus"
                        (change)="maritalStatus('married')"
                        value="married"
                        class="form-check-input"
                      />Married
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        name="personal-maritalStatus"
                        [(ngModel)]="patientDetails.user.maritalStatus"
                        (change)="maritalStatus('single')"
                        value="single"
                        class="form-check-input"
                      />Single
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        name="personal-maritalStatus"
                        [(ngModel)]="patientDetails.user.maritalStatus"
                        (change)="maritalStatus('others')"
                        value="others"
                        class="form-check-input"
                      />Others
                    </label>
                  </div>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.maritalStatus == null ||
                        patientDetails.user.maritalStatus == '')
                      "
                    >
                      Please Choose Marital Status
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Telephone Number 1 :
                </div>
                <div class="col-sm-8 pad-10" *ngIf="isEdit">
                  <ngx-intl-tel-input
                    [cssClass]="'custom form-control'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="false"
                    [searchCountryFlag]="true"
                    required
                    [separateDialCode]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [maxLength]="15"
                    [selectedCountryISO]="primaryPersonalCountryISO"
                    id="telephonePersonalNumber1"
                    (keyup)="selectPersonalTelOne($event)"
                    [ngModelOptions]="{ standalone: true }"
                    name="primaryPersonalNumber"
                    [(ngModel)]="patientDetails.user.primaryNumber"
                  >
                  </ngx-intl-tel-input>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.primaryNumber == null ||
                          patientDetails.user.primaryNumber == '')
                      "
                    >
                      Telephone number 1 is required
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Telephone Number 2 :
                </div>
                <div class="col-sm-8 pad-10" *ngIf="isEdit">
                  <ngx-intl-tel-input
                    [cssClass]="'custom form-control'"
                    [maxLength]="15"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="false"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="secondaryPersonalCountryISO"
                    id="telephonePersonalNumber2"
                    [separateDialCode]="true"
                    name="secondaryPersonalNumber"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="patientDetails.user.secondaryNumber"
                  >
                  </ngx-intl-tel-input>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Address Line 1 :
                </div>
                <div class="contact-name col-sm-8 pad-10" *ngIf="isEdit">
                  <textarea
                    type="text"
                    class="form-control"
                    name="addressLine1"
                    id="addressLine1"
                    #address="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && address.errors
                    }"
                    [(ngModel)]="patientDetails.user.addressDto.addressLine1"
                    (keyup)="patientAddOne($event)"
                    required
                  ></textarea>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.addressDto.addressLine1 == null ||
                          patientDetails.user.addressDto.addressLine1 == '')
                      "
                    >
                      Please Fill the Address line 1
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Address Line 2 :
                </div>
                <div class="contact-name col-sm-8 pad-10" *ngIf="!isEdit">
                  {{
                    patientDetails?.user?.addressDto?.addressLine2
                      ? patientDetails?.user?.addressDto?.addressLine2
                      : "N/A"
                  }}
                </div>
                <div class="contact-name col-sm-8 pad-10" *ngIf="isEdit">
                  <textarea
                    type="text"
                    class="form-control"
                    (keyup)="patientAddTwo($event)"
                    [(ngModel)]="patientDetails.user.addressDto.addressLine2"
                    name="personal-addressLine2"
                    id="personal-addressLine2"
                    #address="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && address.errors
                    }"
                    required
                  ></textarea>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.addressDto.addressLine2 == null ||
                          patientDetails.user.addressDto.addressLine2 == '')
                      "
                    >
                      Please Fill the Address line 2
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Pincode :
                </div>

                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <input
                    class="form-control"
                    type="text"
                    appNumbersOnly
                    name="personal-pincode"
                    id="personal-pincode"
                    (keyup)="patientPincode($event)"
                    [(ngModel)]="patientDetails.user.addressDto.pincode"
                    #personalPincode="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && personalPincode.errors
                    }"
                 required />
                  <div class="invalid-feedback">
                    <div
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.addressDto.pincode == null ||
                          patientDetails.user.addressDto.pincode == '')
                      "
                      class="text-danger"
                    >
                      please fill pincode number
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Country :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <select
                    name="personal-country"
                    class="form-control"
                    [(ngModel)]="patientDetails.user.addressDto.country"
                    (change)="onCountryChangePersonal($event)"
                    id="personal-country"
                    #personalCountry="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && personalCountry.errors
                    }"
                    required
                  >
                    <option value="">Select Country</option>
                    <option
                      *ngFor="let countryItem of personalCountries"
                      [value]="countryItem.id"
                    >
                      {{ countryItem.name }}
                    </option>
                  </select>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.addressDto
                          .country == null ||
                          patientDetails.user.addressDto
                            .country == '')
                      "
                    >
                      Please select Country
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  State :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <select
                    name="personal-state"
                    class="form-control"
                    [(ngModel)]="patientDetails.user.addressDto.state"
                    (change)="onStateChangePersonal($event)"
                    id="personal-state"
                    #personalState="ngModel"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && personalState.errors
                    }"
                    required
                  >
                    <option value="">Select State</option>
                    <option
                      *ngFor="let stateItem of personalStates"
                      [value]="stateItem.id"
                    >
                      {{ stateItem.name }}
                    </option>
                  </select>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.user.addressDto
                          .state == null ||
                          patientDetails.user.addressDto
                            .state == '')
                      "
                    >
                      Please select State
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  City :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <select
                    name="personal-city"
                    class="form-control"
                    #personalCity="ngModel"
                    [(ngModel)]="patientDetails.user.addressDto.city"
                    (change)="onCityChangePersonal($event)"
                    id="personal-city"
                    [ngClass]="{
                      'is-invalid': forPersonalSubmit && personalCity.errors
                    }"
                    required
                  >
                    <option value="">Select City</option>
                    <option
                      *ngFor="let cityItem of personalCities"
                      [value]="cityItem.id"
                    >
                      {{ cityItem.name }}
                    </option>
                  </select>
                  <div class="custom-invalid-feedback">
                    <div
                      class="text-danger"
                      *ngIf="
                        forPersonalSubmit &&
                        (patientDetails.originalPartnerDetails.user.addressDto
                          .city == null ||
                          patientDetails.originalPartnerDetails.user.addressDto
                            .city == '')
                      "
                    >
                      Please select city
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="contact-name text-ellipsis col-sm-4 pad-10">
                  Referred By :
                </div>
                <div
                  class="contact-name text-ellipsis col-sm-8 pad-10"
                  *ngIf="isEdit"
                >
                  <input
                    class="form-control"
                    type="text"
                    name="personal-refer"
                    id="personal-refer"
                    [(ngModel)]="patientDetails.referredBy"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class=""
        *ngIf="guestUser"
        [ngClass]="maritalStatusStr ? 'col-lg-5' : ''"
      >
        <form #partnerProfileForm="ngForm">
          <ng-container *ngIf="maritalStatusStr">
            <h4 class="page-title">Partner Information</h4>
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    First Name :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="!isEdit"
                  >
                    {{
                      patientDetails?.originalPartnerDetails.user?.firstName
                        ? patientDetails?.originalPartnerDetails.user?.firstName
                        : "N/A"
                    }}
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <div class="input-group">
                      <span class="input-group-addon">
                        <select
                          class="form-control"
                          name="salutationId"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user
                              .salutationId
                          "
                          #partnerSalutation="ngModel"
                          required
                          [ngClass]="{
                            'is-invalid': forPersonalSubmit && partnerSalutation.errors
                          }" >
                          <option disabled>Salutation</option>
                          <option
                            *ngFor="let data of salutationsList"
                            [value]="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </select>
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        name="partner-firstName"
                        id="partner-firstName"
                        required
                        #partnerFirstName="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            forPartnerSubmit && partnerFirstName.errors
                        }"
                        [(ngModel)]="
                          patientDetails.originalPartnerDetails.user.firstName
                        "
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          forPartnerSubmit &&
                          partnerFirstName.errors &&
                          partnerFirstName.errors.required
                        "
                      >
                        <span class="text-danger">
                          Please enter first name with salutation
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Last Name :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="!isEdit"
                  >
                    {{
                      patientDetails?.originalPartnerDetails.user?.lastName
                        ? patientDetails?.originalPartnerDetails.user?.lastName
                        : "N/A"
                    }}
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="partner-lastName"
                      id="partner-lastName"
                      required
                      #partnerLastName="ngModel"
                      [ngClass]="{
                        'is-invalid': forPartnerSubmit && partnerLastName.errors
                      }"
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.lastName
                      "
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        forPartnerSubmit &&
                        partnerLastName.errors &&
                        partnerLastName.errors.required
                      "
                    >
                      <span class="text-danger"> Please enter last name </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Date of Birth :
                  </div>
                  <div class="contact-name col-sm-4 pad-10" *ngIf="isEdit">
                    <p-calendar
                      dateFormat="dd/mm/yy"
                      [monthNavigator]="true"
                      name="partner-dob"
                      id="partner-dob"
                      [maxDate]="maxDate"
                      (onSelect)="dobPartnerSelect($event)"
                      #dob="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && dob.errors
                      }"
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.dob
                      "
                      [yearNavigator]="true"
                      yearRange="{{ fromFromYear }}:{{ lastFromYear }}"
                    ></p-calendar>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit && patientDetails.originalPartnerDetails.user.dob == null
                        "
                      >
                        Please select date of birth
                      </div>
                    </div>
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-1 pad-10"
                    *ngIf="isEdit"
                  >
                    Age :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-3 pad-10"
                    *ngIf="isEdit"
                  >
                    <input
                      type="text"
                      class="form-control align-age"
                      name="partner-age"
                      id="partner-age"
                      readonly
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.age
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Gender :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          name="partner-gender"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user.gender
                          "
                          value="male"
                          disabled
                          class="form-check-input"
                        />Male
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          name="partner-gender"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user.gender
                          "
                          value="female"
                          disabled
                          class="form-check-input"
                        />Female
                      </label>
                    </div>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user
                            .gender == null ||
                            patientDetails.originalPartnerDetails.user
                              .gender == '')
                        "
                      >
                        Please Choose Gender
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Occupation :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="partner-occupation"
                      id="partner-occupation"
                      [(ngModel)]="
                        patientDetails.user.occupation
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Marital Status :
                  </div>

                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          name="personal-maritalStatus"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user
                              .maritalStatus
                          "
                          (change)="maritalStatus('married')"
                          value="married"
                          disabled
                          class="form-check-input"
                        />Married
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          name="personal-maritalStatus"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user
                              .maritalStatus
                          "
                          (change)="maritalStatus('single')"
                          value="single"
                          disabled
                          class="form-check-input"
                        />Single
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          name="personal-maritalStatus"
                          [(ngModel)]="
                            patientDetails.originalPartnerDetails.user
                              .maritalStatus
                          "
                          (change)="maritalStatus('others')"
                          value="others"
                          disabled
                          class="form-check-input"
                        />Others
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Telephone Number :
                  </div>
                  <div class="col-sm-8 pad-10" *ngIf="isEdit">
                    <ngx-intl-tel-input
                      [cssClass]="'custom form-control'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="false"
                      [searchCountryFlag]="true"
                      required
                      [separateDialCode]="true"
                      [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [selectedCountryISO]="primaryPartnerCountryISO"
                      id="telephonePartnerNumber1"
                      (keyup)="selectPartnerTelOne($event)"
                      [ngModelOptions]="{ standalone: true }"
                      name="primaryPartnerNumber"
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.primaryNumber
                      "
                    >
                    </ngx-intl-tel-input>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPartnerSubmit &&
                          (patientDetails.originalPartnerDetails.user
                            .primaryNumber == null ||
                            patientDetails.originalPartnerDetails.user
                              .primaryNumber == '')
                        "
                      >
                        Telephone number is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Address Line 1 :
                  </div>
                  <div class="contact-name col-sm-8 pad-10" *ngIf="isEdit">
                    <textarea
                      type="text"
                      class="form-control"
                      disabled
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto.addressLine1
                      "
                      name="partner-addressLine1"
                      id="partner-addressLine1"
                      #address="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && address.errors
                      }"
                      required
                    ></textarea>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user.addressDto
                            .addressLine1 == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.addressLine1 == '')
                        "
                      >
                        Please Fill the Address line 1
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Address Line 2 :
                  </div>
                  <div class="contact-name col-sm-8 pad-10" *ngIf="isEdit">
                    <textarea
                      type="text"
                      class="form-control"
                      disabled
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto
                          .addressLine2
                      "
                      name="partner-addressLine2"
                      id="partner-addressLine2"
                      #address="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && address.errors
                      }"
                      required
                    ></textarea>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user.addressDto
                            .addressLine2 == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.addressLine2 == '')
                        "
                      >
                        Please Fill the Address line 2
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Pincode :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <input
                      class="form-control"
                      type="text"
                      appNumbersOnly
                      name="partner-pin"
                      id="partner-pin"
                      disabled
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto
                          .pincode
                      "
                      #pincode="ngModel"
                      [ngClass]="{'is-invalid': forPersonalSubmit && pincode.errors}"
                  required   />
                  <div class="custom-invalid-feedback">
                      <div
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user
                            .addressDto.pincode == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.pincode == '')
                        "
                        class="text-danger"
                      >
                        Please fill pincode number
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    Country :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <select
                      name="partner-country"
                      class="form-control"
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto
                          .country
                      "
                      (change)="onCountryChangePartner($event)"
                      disabled
                      id="partner-country"
                      #partnerCountry="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && partnerCountry.errors
                      }" required >
                      <option value="">Select Country</option>
                      <option
                        *ngFor="let countryItem of partnerCountries"
                        [value]="countryItem.id"
                      >
                        {{ countryItem.name }}
                      </option>
                    </select>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user.addressDto
                            .city == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.city == '')
                        "
                      >
                        Please select Country
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    State :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <select
                      name="partner-state"
                      class="form-control"
                      disabled
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto
                          .state
                      "
                      (change)="onStateChangePartner($event)"
                      id="partner-state"
                      #partnerState="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && partnerState.errors
                      }" required>
                      <option value="">Select State</option>
                      <option
                        *ngFor="let stateItem of partnerStates"
                        [value]="stateItem.id"
                      >
                        {{ stateItem.name }}
                      </option>
                    </select>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user.addressDto
                            .city == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.city == '')
                        "
                      >
                        Please select State
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row">
                  <div class="contact-name text-ellipsis col-sm-4 pad-10">
                    City :
                  </div>
                  <div
                    class="contact-name text-ellipsis col-sm-8 pad-10"
                    *ngIf="isEdit"
                  >
                    <select
                      name="partner-city"
                      class="form-control"
                      disabled
                      [(ngModel)]="
                        patientDetails.originalPartnerDetails.user.addressDto
                          .city
                      "
                      (change)="onCityChangePartner($event)"
                      id="partner-city"
                      #partnerCity="ngModel"
                      [ngClass]="{
                        'is-invalid': forPersonalSubmit && partnerCity.errors
                      }"
                   required >
                      <option value="">Select City</option>
                      <option
                        *ngFor="let cityItem of partnerCities"
                        [value]="cityItem.id"
                      >
                        {{ cityItem.name }}
                      </option>
                    </select>
                    <div class="custom-invalid-feedback">
                      <div
                        class="text-danger"
                        *ngIf="
                          forPersonalSubmit &&
                          (patientDetails.originalPartnerDetails.user.addressDto
                            .city == null ||
                            patientDetails.originalPartnerDetails.user
                              .addressDto.city == '')
                        "
                      >
                        Please select city
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="col-lg-4"></div>
      <div *ngIf="!guestUser" class="col-lg-8">
        <div class="row">
          <div class="col-md-12 col-12">
            <h4>Patient Information</h4>
          </div>
          <div class="col-md-12 mt-3">
            <span class="for-details-head"><b>Patient Name : </b></span>
            <span class="for-details">{{
              name ? (name | titlecase) : "-"
            }}</span>
            <span class="for-details-head"><b>Telephone Number : </b></span>
            <span class="for-details">{{
              primaryNumber ? primaryNumber : "-"
            }}</span>
            <span class="for-details-head"><b>Doctor Name : </b></span>
            <span class="for-details">{{
              doctorName ? (doctorName | titlecase) : "-"
            }}</span>
            <span class="for-details-head"><b>AppointmentDate : </b></span>
            <span class="for-details">{{ slotDate ? slotDate : "-" }}</span>
            <span class="for-details-head"><b>Slot Timings : </b></span>
            <span class="for-details">{{ startTime }} - {{ endTime }}</span>
            <span class="for-details-head"><b>Referred By : </b></span>
            <span class="for-details">{{ referredBy ? referredBy : "-" }}</span>
            <span class="for-details-head"><b>Consultation Fee : </b></span>
            <span class="for-details">Rs. {{ getAmount() }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-5"></div>
      <div class="col-lg-6 mt-3 mb-3 mb-2 mx-auto"></div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12 text-center">
        <button
          *ngIf="paymentFlag && forPayBtn && showBtn"
          id="payNowBtn"
          class="btn btn-primary btn-rounded submit-btn ml-5"
          (click)="payNow()"
          type="button"
        >
          Pay Now
        </button>
        <button
          *ngIf="paymentFlag && !forPayBtn && showBtn"
          class="btn btn-outline-primary btn-rounded submit-btn text-grey"
          (click)="confirm()"
          type="button"
        >
          Confirm
        </button>

        <button
          *ngIf="!paymentFlag && !forPayBtn && showBtn"
          id="payNowBtn"
          class="btn btn-outline-primary btn-rounded mr-3 submit-btn text-grey"
          (click)="payNow()"
          type="button"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<div id="paymentModal" class="modal fade delete-modal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img src="assets/img/sent.png" alt="" width="50" height="46" />
        <h3>Are you sure want to proceed for payment?</h3>
        <div class="m-t-20">
          <button
            type="submit"
            class="btn btn-danger mr-2 btn-rounded"
            (click)="yes()"
          >
            Yes
          </button>
          <a
            class="cursor-pointer"
            class="btn mr-3 btn-rounded text-white btn-danger"
            data-dismiss="modal"
            >No</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
