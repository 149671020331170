import { Component, OnInit } from '@angular/core';
import { PartnerPatient, Patient } from '../modals/patient/patient.modal';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { PatientSlotBooking } from '../modals/slot-management/patients/patient-slot-booking.modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../Utils/common/common.service';
import { Address } from '../modals/Address/address.modal';
import { PaymentOrder } from '../modals/Payment Gateway/payment-order.modal';
import { AppointmentViewDetailsDto } from '../modals/Junior-doctor/appointment-view-details.modal';
import { Salutation } from '../modals/GeneralMaster/Salutation/salutation.modal';
import { Pagination } from 'src/app/modals/Pagination/pagination.modal';

declare var $: any;
declare var Razorpay: any;
@Component({
  selector: 'app-guiest-user-details',
  templateUrl: './guiest-user-details.component.html',
  styleUrls: ['./guiest-user-details.component.scss'],
})
export class GuiestUserDetailsComponent implements OnInit {
  imageURL = 'assets/img/new-hegde-logo.png';
  isEdit: boolean = true;
  maritalStatusStr: boolean = false;
  isLoading: boolean = false;
  forPersonalSubmit: boolean = false;
  forPartnerSubmit: boolean = false;

  forMaritalStatusFlag: boolean = false;
  patientId = null;
  forPayBtn: boolean = false;
  salutationsList: Salutation[] = [];
  pagination: Pagination = new Pagination();
  maxDate = new Date();
  primaryPersonalCountryISO = 'in';
  secondaryPersonalCountryISO = 'in';

  primaryPartnerCountryISO = 'in';
  secondaryPartnerCountryISO = 'in';
  requiredPartnerDetails: boolean;
  hasPartnerDetails: boolean;

  bookedSlotDetails: AppointmentViewDetailsDto =
    new AppointmentViewDetailsDto();

  selectedImageSrc: any = '/assets/img/PNG/User Place holder 88x88.png';
  departmentId: any = null;
  branchCode: any = 0;

  slotId = null;
  amount = null;
  appointmentId = null;
  showBtn: boolean = true;

  personalCities = [];
  personalStates = [];
  personalCountries = [];

  partnerCities = [];
  partnerStates = [];
  partnerCountries = [];

  patientDetails: Patient = new Patient();

  typeStr: string = '';
  localUrl: any = '/assets/images/download (5).png';
  /**
   * ---> For Mobile number input with country codes configuration.
   */
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  preferredCountries: CountryISO[] = [CountryISO.India];

  globalPatientId = null;
  sequenceId = null;

  isFrom;
  fromFromYear = null;
  lastFromYear = null;

  fromToYear = null;
  lastToYear = null;

  selectedYearRange: any = '';

  paymentOrder = new PaymentOrder();
  referredBy = '';
  doctorName = '';
  slotDate = '';
  startTime = '';
  endTime = '';
  slotShift = '';
  primaryNumber = '';
  guestUser = false;
  name = '';

  redirectUrl: any = 'https://ghealthsolutions.com/';
  isRedirect: boolean = false;

  rzp1: any;
  options = {
    key: '', // Enter the Key ID generated from the Dashboard
    amount: null, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'INR',
    name: '',
    description: '',
    image: 'https://example.com/your_logo',
    order_id: '', //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (response) => {
      this.getPaymentConfirmation(
        response.razorpay_payment_id,
        response.razorpay_order_id,
        response.razorpay_signature
      );
    },
    prefill: {
      name: '',
      email: '',
      contact: '',
    },
    notes: {
      address: 'Razorpay Corporate Office',
    },
    theme: {
      color: '#F37254',
    },
  };

  initPay(): void {
    this.rzp1 = new Razorpay(this.options);
    this.rzp1.open();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((response) => {
      if (response && response.p) {
        let data = response.p.split('-');
        this.globalPatientId = data[0];
        this.sequenceId = data[1];
      }
      if (response) {
        this.isRedirect = true;
      }
    });
    let obj = this.commonService.getDateOfBirthYears();
    if (obj) {
      this.fromFromYear = obj.fromYear;
      this.lastFromYear = obj.toYear;

      this.fromToYear = obj.fromYear;
      this.lastToYear = obj.toYear;
    }

    this.getCountriesList();
    this.getGuestUserDetails();
    this.getSalutationList();
  }

  maritalStatus(type) {
    if (type == 'married' && this.requiredPartnerDetails) {
      this.maritalStatusStr = true;
      this.patientDetails.hasPartnerDetails = true;
      this.patientDetails.user.maritalStatus = 'married';
      this.patientDetails.originalPartnerDetails = new PartnerPatient();
      this.patientDetails.originalPartnerDetails.user.maritalStatus = 'married';
      if (this.patientDetails.user.gender == 'male') {
        this.patientDetails.originalPartnerDetails.user.gender = 'female';
      } else {
        this.patientDetails.originalPartnerDetails.user.gender = 'male';
      }
      this.patientDetails.originalPartnerDetails.user.addressDto.addressLine1 =
        this.patientDetails.user.addressDto.addressLine1;
      this.patientDetails.originalPartnerDetails.user.addressDto.addressLine2 =
        this.patientDetails.user.addressDto.addressLine2;
      this.patientDetails.originalPartnerDetails.user.addressDto.pincode =
        this.patientDetails.user.addressDto.pincode;
      this.patientDetails.originalPartnerDetails.user.addressDto.country =
        this.patientDetails.user.addressDto.country;
      this.patientDetails.originalPartnerDetails.user.addressDto.state =
        this.patientDetails.user.addressDto.state;
      this.patientDetails.originalPartnerDetails.user.addressDto.city =
        this.patientDetails.user.addressDto.city;
    } else if (type == 'married' && !this.requiredPartnerDetails) {
      this.maritalStatusStr = false;
    } else if (type == 'others' && this.requiredPartnerDetails) {
      this.toastr.error('For selected department need partner information');
      this.maritalStatusStr = true;
      setTimeout(() => {
        this.patientDetails.user.maritalStatus = 'married';
      }, 0);

      this.patientDetails.user.gender = 'others';
    } else if (type == 'single' && this.requiredPartnerDetails) {
      this.toastr.error('For selected department need partner information');
      this.maritalStatusStr = true;

      setTimeout(() => {
        this.patientDetails.user.maritalStatus = 'married';
      }, 0);
    } else if (type == 'single' && !this.requiredPartnerDetails) {
      this.maritalStatusStr = false;

      this.patientDetails.user.gender = 'single';
      this.patientDetails.originalPartnerDetails = null;
    } else {
      if (this.requiredPartnerDetails) {
        this.toastr.error('For selected department need partner information');

        this.maritalStatusStr = true;
        setTimeout(() => {
          this.patientDetails.user.maritalStatus = 'married';
        }, 0);
        this.patientDetails.hasPartnerDetails = true;
        this.patientDetails.originalPartnerDetails = new PartnerPatient();
        return;
      } else {
        this.maritalStatusStr = false;
        this.patientDetails.hasPartnerDetails = false;
        this.patientDetails.user.maritalStatus = 'single';
        this.patientDetails.originalPartnerDetails = null;
      }
    }
  }

  dobSelect(event, relationShip) {
    var dob = event;
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (relationShip === 'SINGLE') {
      this.patientDetails.user.age = age;
    } else if (relationShip === 'MARRIED') {
      this.patientDetails.originalPartnerDetails.user.age = age;
    }
  }

  selectTelOne(event) { }

  onCountryChangePersonal(event) {
    if (event && event.target.value) {
      this.personalStates = [];
      this.personalCities = [];
      this.patientDetails.user.addressDto.state = '';
      this.patientDetails.user.addressDto.city = '';
      this.getAllStatesPersonal(event.target.value);
      if (
        this.patientDetails.originalPartnerDetails &&
        this.patientDetails.originalPartnerDetails.user.addressDto
      ) {
        this.patientDetails.originalPartnerDetails.user.addressDto.country =
          event.target.value;
        this.getAllStatesPartner(event.target.value);
      } else {
        if (this.patientDetails.originalPartnerDetails) {
          this.patientDetails.originalPartnerDetails.user.addressDto =
            new Address();
          this.patientDetails.originalPartnerDetails.user.addressDto.country =
            event.target.value;
          this.getAllStatesPartner(event.target.value);
        }
      }
    }
  }

  getAllStatesPersonal(countryId) {
    this.isLoading = true;
    this.apiService
      .getService(this.apiService.apiUrls.getAllActiveStates + '/' + countryId)
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.personalStates =
              response.data && response.data.length ? response.data : [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.error
          );
        }
      );
  }

  getAllStatesPartner(countryId) {
    this.isLoading = true;
    this.apiService
      .getService(this.apiService.apiUrls.getAllActiveStates + '/' + countryId)
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.partnerStates =
              response.data && response.data.length ? response.data : [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.error
          );
        }
      );
  }

  onStateChangePersonal(event) {
    if (event && event.target.value) {
      this.personalCities = [];
      this.patientDetails.user.addressDto.city = '';
      this.getAllCitiesPersonal(event.target.value);
      if (
        this.patientDetails.originalPartnerDetails &&
        this.patientDetails.originalPartnerDetails.user.addressDto
      ) {
        this.patientDetails.originalPartnerDetails.user.addressDto.state =
          event.target.value;
        this.getAllCitiesPartner(event.target.value);
      } else {
        if (this.patientDetails.originalPartnerDetails) {
          this.patientDetails.originalPartnerDetails.user.addressDto =
            new Address();
          this.patientDetails.originalPartnerDetails.user.addressDto.state =
            event.target.value;
          this.getAllCitiesPartner(event.target.value);
        }
      }
    }
  }

  onStateChangePartner(event) {
    if (event && event.target.value) {
      this.partnerCities = [];
      this.patientDetails.originalPartnerDetails.user.addressDto.city = '';
      this.getAllCitiesPartner(event.target.value);
    }
  }

  getAllCitiesPersonal(stateId) {
    this.isLoading = true;
    this.apiService
      .getService(this.apiService.apiUrls.getAllActiveCities + '/' + stateId)
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.personalCities =
              response.data && response.data.length ? response.data : [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.error
          );
        }
      );
  }

  getAllCitiesPartner(stateId) {
    this.isLoading = true;
    this.apiService
      .getService(this.apiService.apiUrls.getAllActiveCities + '/' + stateId)
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.partnerCities =
              response.data && response.data.length ? response.data : [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.error
          );
        }
      );
  }

  onCityChangePersonal(event) {
    if (
      this.patientDetails.originalPartnerDetails &&
      this.patientDetails.originalPartnerDetails.user.addressDto
    ) {
      this.patientDetails.originalPartnerDetails.user.addressDto.city =
        event.target.value;
    } else {
      if (this.patientDetails.originalPartnerDetails) {
        this.patientDetails.originalPartnerDetails.user.addressDto =
          new Address();
        this.patientDetails.originalPartnerDetails.user.addressDto.city =
          event.target.value;
      }
    }
  }

  onCityChangePartner(event) {
    if (event && event.target.value) {
    }
  }

  getCountriesList() {
    this.isLoading = true;
    this.apiService
      .getService(this.apiService.apiUrls.getAllActiveCountries)
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.personalCountries =
              response.data && response.data.length ? response.data : [];
            this.partnerCountries =
              response.data && response.data.length ? response.data : [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }

  onCountryChangePartner(event) {
    if (event && event.target.value) {
      this.partnerStates = [];
      this.partnerCities = [];
      this.patientDetails.originalPartnerDetails.user.addressDto.state = '';
      this.patientDetails.originalPartnerDetails.user.addressDto.city = '';
      this.getAllStatesPartner(event.target.value);
    }
  }

  onClickBack = (event) => {
    //window.history.back();
  };



  isPartnerDetailsChangeable = () => {
    if (this.patientId) {
      this.apiService
        .getService(
          this.apiService.apiUrls.checkPartnerDetailsUpdatable +
          `/${this.patientId}`
        )
        .subscribe(
          (response) => { },
          (error) => {
            this.isLoading = false;
            this.toastr.error(
              error.error ? error.error.message : error.message
            );
          }
        );
    }
  };

  selectPersonalTelOne(event) {
    if (this.forPersonalSubmit == true) {
      if (
        this.patientDetails.user.primaryNumber == null ||
        this.patientDetails.user.primaryNumber == '' ||
        this.patientDetails.user.primaryNumber == undefined
      ) {
        if (
          $('#telephonePersonalNumber1 .intl-tel-input').hasClass(
            'border-red'
          ) == false
        ) {
          $('#telephonePersonalNumber1 .intl-tel-input').addClass('border-red');
        }
      } else {
        if (
          $('#telephonePersonalNumber1 .intl-tel-input').hasClass('border-red')
        ) {
          $('#telephonePersonalNumber1 .intl-tel-input').removeClass(
            'border-red'
          );
        }
      }
    }
  }

  selectPartnerTelOne(event) {
    if (this.forPartnerSubmit == true) {
      if (this.patientDetails.originalPartnerDetails) {
        if (
          this.patientDetails.originalPartnerDetails.user.primaryNumber ==
          null ||
          this.patientDetails.originalPartnerDetails.user.primaryNumber == '' ||
          this.patientDetails.originalPartnerDetails.user.primaryNumber ==
          undefined
        ) {
          if (
            $('#telephonePartnerNumber1 .intl-tel-input').hasClass(
              'border-red'
            ) == false
          ) {
            $('#telephonePartnerNumber1 .intl-tel-input').addClass(
              'border-red'
            );
          }
        }
      } else {
        if (
          $('#telephonePartnerNumber1 .intl-tel-input').hasClass('border-red')
        ) {
          $('#telephonePartnerNumber1 .intl-tel-input').removeClass(
            'border-red'
          );
        }
      }
    }
  }

  dobPartnerSelect(event) {
    var dob = event;
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (this.patientDetails.originalPartnerDetails) {
      this.patientDetails.originalPartnerDetails.user.age = age;
    }
    if (this.forPartnerSubmit == true) {
    }
  }

  dobPersonalSelect(event) {
    var dob = event;
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.patientDetails.user.age = age;
  }

  paymentFlag = true;

  payNow() {
    if (this.guestUser) {
      if (
        this.patientDetails.user.maritalStatus == 'married' &&
        this.requiredPartnerDetails
      ) {
        this.forPersonalSubmit = true;
        this.forPartnerSubmit = true;

        if (!this.paymentFlag) {
          let data: any = JSON.parse(JSON.stringify(this.patientDetails));
          if (data && data.user && data.user.primaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.primaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.primaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.primaryNumber = temp;
          }
          if (data && data.user && data.user.secondaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.secondaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.secondaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.secondaryNumber = temp;
          }
          delete data.user.branchDeptVersionDto;
          data.originalPartnerDetails = null;
          this.isLoading = true;
          this.apiService
            .getService(
              this.apiService.apiUrls.getGuestUserDetails +
              '/' +
              this.globalPatientId +
              '/sequenceId/' +
              this.sequenceId
            )
            .subscribe(
              (response: any) => {
                if (response && response.statusCode === 200) {
                  let paymentFlag = response.data.requiredPayment;

                  this.isLoading = true;
                  this.apiService
                    .postService(
                      this.apiService.apiUrls.saveGuestUserPatient +
                      '/patientId/' +
                      this.globalPatientId +
                      '/sequenceId/' +
                      this.sequenceId,
                      data
                    )
                    .subscribe(
                      (response: any) => {
                        if (response && response.statusCode === 200) {
                          if (paymentFlag) {
                            this.getOrders();
                          } else {
                            this.showBtn = false;
                            this.toastr.success(response.message);
                          }
                        }
                        this.isLoading = false;
                      },
                      (error) => {
                        this.isLoading = false;
                        $('#paymentModal').modal('hide');
                        // this.showBtn = false;
                        this.toastr.error(
                          error.error
                            ? error.error.message
                              ? error.error.message
                              : 'Something went wrong from server side. Please try again after sometime'
                            : error.message
                        );
                      }
                    );
                }
                this.isLoading = false;
              },
              (error) => {
                this.isLoading = false;
                $('#paymentModal').modal('hide');
                // this.showBtn = false;
                this.toastr.error(
                  error.error
                    ? error.error.message
                      ? error.error.message
                      : 'Something went wrong from server side. Please try again after sometime'
                    : error.message
                );
              }
            );
        } else {
        }

        if (
          this.patientDetails.user.salutationId &&
          this.patientDetails.user.firstName &&
          this.patientDetails.user.lastName &&
          this.patientDetails.user.dob &&
          this.patientDetails.user.addressDto.addressLine1 &&
          this.patientDetails.user.addressDto.addressLine2 &&
          this.patientDetails.user.addressDto.country &&
          this.patientDetails.user.addressDto.state &&
          this.patientDetails.user.addressDto.city &&
          this.patientDetails.user.addressDto.pincode &&
          this.patientDetails.user.primaryNumber &&
          this.patientDetails.originalPartnerDetails.user.firstName &&
          this.patientDetails.originalPartnerDetails.user.salutationId &&
          this.patientDetails.originalPartnerDetails.user.lastName &&
          this.patientDetails.originalPartnerDetails.user.primaryNumber &&
          this.patientDetails.originalPartnerDetails.user.dob &&

          this.patientDetails.originalPartnerDetails.user.addressDto
            .addressLine1 &&
          this.patientDetails.originalPartnerDetails.user.addressDto
            .addressLine2 &&
          this.patientDetails.originalPartnerDetails.user.addressDto.pincode &&
          this.patientDetails.originalPartnerDetails.user.addressDto.country &&
          this.patientDetails.originalPartnerDetails.user.addressDto.state &&
          this.patientDetails.originalPartnerDetails.user.addressDto.city
        ) {
          this.forPersonalSubmit = false;
          this.forPartnerSubmit = false;
          $('#paymentModal').modal('show');
        } else {
          if (
            this.patientDetails.user.primaryNumber == null ||
            this.patientDetails.user.primaryNumber == '' ||
            this.patientDetails.user.primaryNumber == undefined
          ) {
            if (
              $('#telephonePersonalNumber1 .intl-tel-input').hasClass(
                'border-red'
              ) == false
            ) {
              $('#telephonePersonalNumber1 .intl-tel-input').addClass(
                'border-red'
              );
            }
          } else {
            if (
              $('#telephonePersonalNumber1 .intl-tel-input').hasClass(
                'border-red'
              )
            ) {
              $('#telephonePersonalNumber1 .intl-tel-input').removeClass(
                'border-red'
              );
            }
          }
          if (
            this.patientDetails.originalPartnerDetails.user.primaryNumber ==
            null ||
            this.patientDetails.originalPartnerDetails.user.primaryNumber ==
            '' ||
            this.patientDetails.originalPartnerDetails.user.primaryNumber ==
            undefined
          ) {
            if (
              $('#telephonePartnerNumber1 .intl-tel-input').hasClass(
                'border-red'
              ) == false
            ) {
              $('#telephonePartnerNumber1 .intl-tel-input').addClass(
                'border-red'
              );
            }
          } else {
            if (
              $('#telephonePartnerNumber1 .intl-tel-input').hasClass(
                'border-red'
              )
            ) {
              $('#telephonePartnerNumber1 .intl-tel-input').removeClass(
                'border-red'
              );
            }
          }
          this.toastr.error('Please enter mandatory fields');
        }
      } else {
        this.forPersonalSubmit = true;
        if (
          this.patientDetails.user.salutationId &&
          this.patientDetails.user.firstName &&
          this.patientDetails.user.lastName &&
          this.patientDetails.user.dob &&
          this.patientDetails.user.gender &&
          this.patientDetails.user.primaryNumber &&
          this.patientDetails.user.addressDto.addressLine1 &&
          this.patientDetails.user.addressDto.addressLine2 &&
          this.patientDetails.user.addressDto.country &&
          this.patientDetails.user.addressDto.state &&
          this.patientDetails.user.addressDto.city &&
          this.patientDetails.user.addressDto.pincode
        ) {
          this.forPersonalSubmit = false;

          if (!this.paymentFlag) {
            let data: any = JSON.parse(JSON.stringify(this.patientDetails));
            if (data && data.user && data.user.primaryNumber) {
              let temp = JSON.parse(JSON.stringify(data.user.primaryNumber));
              let code = temp.dialCode;
              let numberFlag: string = temp.number.trim();
              numberFlag = numberFlag.replace(/\s/g, '');
              data.user.primaryISOCode = temp.countryCode.toLowerCase();
              temp = code + ' ' + numberFlag;
              data.user.primaryNumber = temp;
            }
            if (data && data.user && data.user.secondaryNumber) {
              let temp = JSON.parse(JSON.stringify(data.user.secondaryNumber));
              let code = temp.dialCode;
              let numberFlag: string = temp.number.trim();
              numberFlag = numberFlag.replace(/\s/g, '');
              data.user.secondaryISOCode = temp.countryCode.toLowerCase();
              temp = code + ' ' + numberFlag;
              data.user.secondaryNumber = temp;
            }
            delete data.user.branchDeptVersionDto;
            data.originalPartnerDetails = null;
            this.isLoading = true;
            this.apiService
              .getService(
                this.apiService.apiUrls.getGuestUserDetails +
                '/' +
                this.globalPatientId +
                '/sequenceId/' +
                this.sequenceId
              )
              .subscribe(
                (response: any) => {
                  if (response && response.statusCode === 200) {
                    let paymentFlag = response.data.requiredPayment;

                    this.isLoading = true;
                    this.apiService
                      .postService(
                        this.apiService.apiUrls.saveGuestUserPatient +
                        '/patientId/' +
                        this.globalPatientId +
                        '/sequenceId/' +
                        this.sequenceId,
                        data
                      )
                      .subscribe(
                        (response: any) => {
                          if (response && response.statusCode === 200) {
                            if (paymentFlag) {
                              this.getOrders();
                            } else {
                              this.showBtn = false;
                              this.toastr.success(response.message);
                            }
                          }
                          this.isLoading = false;
                        },
                        (error) => {
                          this.isLoading = false;
                          // $('#paymentModal').modal('show');
                          // this.showBtn = false;
                          this.toastr.error(
                            error.error
                              ? error.error.message
                                ? error.error.message
                                : 'Something went wrong from server side. Please try again after sometime'
                              : error.message
                          );
                        }
                      );
                  }
                  this.isLoading = false;
                },
                (error) => {
                  this.isLoading = false;
                  $('#paymentModal').modal('hide');
                  // this.showBtn = false;
                  this.toastr.error(
                    error.error
                      ? error.error.message
                        ? error.error.message
                        : 'Something went wrong from server side. Please try again after sometime'
                      : error.message
                  );
                }
              );
          } else {
            $('#paymentModal').modal('show');
          }
        } else {
          if (
            this.patientDetails.user.primaryNumber == null ||
            this.patientDetails.user.primaryNumber == '' ||
            this.patientDetails.user.primaryNumber == undefined
          ) {
            if (
              $('#telephonePersonalNumber1 .intl-tel-input').hasClass(
                'border-red'
              ) == false
            ) {
              $('#telephonePersonalNumber1 .intl-tel-input').addClass(
                'border-red'
              );
            }
          } else {
            if (
              $('#telephonePersonalNumber1 .intl-tel-input').hasClass(
                'border-red'
              )
            ) {
              $('#telephonePersonalNumber1 .intl-tel-input').removeClass(
                'border-red'
              );
            }
          }
          this.toastr.error('Please enter mandatory fields');
        }
      }
    } else {
      $('#paymentModal').modal('show');
    }
  }

  /**
   * For payment update API
   */
  getPaymentConfirmation(paymentId, orderId, signature) {
    this.isLoading = true;
    this.paymentOrder.razorpayPaymentId = paymentId;
    this.paymentOrder.razorpayOrderId = orderId;
    this.paymentOrder.razorpaySignature = signature;
    this.apiService
      .postService(
        this.apiService.apiUrls.afterPaymentGuestUser +
        '/patientId/' +
        this.globalPatientId +
        '/sequence/' +
        this.sequenceId,
        this.paymentOrder
      )
      .subscribe(
        (response: any) => {
          this.patientDetails = new Patient();
          this.forPersonalSubmit = false;
          this.forPartnerSubmit = false;
          if (response && response.statusCode == 200) {
            this.showBtn = false;
            document.getElementById('payNowBtn').style.display = 'none';
            setTimeout(() => {
              if (this.isRedirect) {
                window.location.href = this.redirectUrl;
              }
            }, 5000);
          }

          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          $('#paymentModal').modal('hide');
          this.showBtn = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }

  getGuestUserDetails() {
    this.isLoading = true;
    this.apiService
      .getService(
        this.apiService.apiUrls.getGuestUserDetails +
        '/' +
        this.globalPatientId +
        '/sequenceId/' +
        this.sequenceId
      )
      .subscribe(
        (response: any) => {
          if (response && response.statusCode === 200) {
            this.patientDetails.user.firstName = response.data.name;
            this.patientDetails.user.primaryNumber =
              response.data.primaryNumber.trim();
            this.primaryPersonalCountryISO = response.data.isoCode;
            this.patientDetails.referredBy = response.data.referredBy;
            this.slotId = response.data.slotId;
            this.appointmentId = response.data.appointmentId;
            this.amount = response.data.amount;
            this.patientDetails.user.role = response.data.role;
            this.patientDetails.id = response.data.patientId;
            this.guestUser = response.data.isGuestUser;
            this.doctorName = response.data.doctorName;
            this.slotDate = response.data.slotDate;
            this.startTime = response.data.startTime;
            this.endTime = response.data.endTime;
            this.paymentFlag = response.data.requiredPayment;
            this.requiredPartnerDetails = response.data.requiredPartnerDetails;
            this.hasPartnerDetails = response.data.hasPartnerDetails;

            if (!this.hasPartnerDetails) {
              this.apiService
                .getService(
                  this.apiService.apiUrls.getExistingPatientDetail +
                  '/' +
                  this.patientDetails.id
                )
                .subscribe(
                  (response: any) => {

                    if (response) {
                      setTimeout(() => {
                        this.patientDetails.user.dob = this.patientDetails.user.dob ? new Date(this.patientDetails.user.dob) : null;
                        if (this.requiredPartnerDetails && this.patientDetails.originalPartnerDetails && this.patientDetails.originalPartnerDetails.user) {
                          this.patientDetails.originalPartnerDetails.user.dob =
                            this.patientDetails.originalPartnerDetails.user.dob ? new Date(this.patientDetails.originalPartnerDetails.user.dob) : null;
                        }

                      }, 0);
                      this.patientDetails = response.data;
                      if (this.requiredPartnerDetails == true) {
                        if (this.requiredPartnerDetails || this.patientDetails.hasPartnerDetails) {
                          this.maritalStatusStr = true;
                          this.patientDetails.hasPartnerDetails = true;
                          this.patientDetails.user.maritalStatus = 'married';
                          if (this.patientDetails.originalPartnerDetails == null) {
                            this.patientDetails.originalPartnerDetails = new PartnerPatient();
                          }
                          this.patientDetails.originalPartnerDetails.user.maritalStatus =
                            'married';
                          if (this.patientDetails.user.gender == 'male') {
                            this.patientDetails.originalPartnerDetails.user.gender =
                              'female';
                          } else {
                            this.patientDetails.originalPartnerDetails.user.gender = 'male';
                          }
                          if (this.patientDetails.user.addressDto == null) {
                            this.patientDetails.user.addressDto = new Address();
                          }

                          if (this.patientDetails.user.addressDto.country != "") {
                            this.patientDetails.originalPartnerDetails.user.addressDto.country = this.patientDetails.user.addressDto.country;
                            this.patientDetails.originalPartnerDetails.user.addressDto.state = this.patientDetails.user.addressDto.state;
                            this.patientDetails.originalPartnerDetails.user.addressDto.city = this.patientDetails.user.addressDto.city;

                            if (this.patientDetails.originalPartnerDetails && this.patientDetails.originalPartnerDetails.user) {
                              if (this.patientDetails.originalPartnerDetails && this.patientDetails.originalPartnerDetails.user.addressDto.country) {
                                this.getAllStatesPartner(this.patientDetails.originalPartnerDetails.user.addressDto.state);
                              }
                              if (this.patientDetails.user.addressDto.state) {
                                if (this.patientDetails.originalPartnerDetails && this.patientDetails.originalPartnerDetails.user) {
                                  this.getAllCitiesPartner(this.patientDetails.originalPartnerDetails.user.addressDto.city);
                                }
                              }
                              this.patientDetails.originalPartnerDetails.user.addressDto = this.patientDetails.user.addressDto;

                            }
                          }



                        } else {
                          this.maritalStatusStr = false;
                        }
                      }

                      if (
                        this.requiredPartnerDetails &&
                        this.patientDetails.originalPartnerDetails &&
                        this.patientDetails.originalPartnerDetails.user &&
                        this.patientDetails.originalPartnerDetails.user.addressDto
                      ) {
                        this.getAllStatesPartner(
                          this.patientDetails.originalPartnerDetails.user.addressDto.country
                        );
                        this.getAllCitiesPartner(
                          this.patientDetails.originalPartnerDetails.user.addressDto.state
                        );
                      } else if (
                        this.requiredPartnerDetails &&
                        this.patientDetails.originalPartnerDetails &&
                        this.patientDetails.originalPartnerDetails.user &&
                        !this.patientDetails.originalPartnerDetails.user.addressDto
                      ) {
                        this.patientDetails.originalPartnerDetails.user.addressDto =
                          new Address();
                      }
                      if (!response.data.user.addressDto) {
                        this.patientDetails.user.addressDto = new Address();
                      } else {
                        this.getAllStatesPersonal(
                          this.patientDetails.user.addressDto.country
                        );
                        this.getAllCitiesPersonal(
                          this.patientDetails.user.addressDto.state
                        );
                      }
                      response.data.patientDto.user.primaryNumber =
                        response.data.user.primaryNumber.trim();
                      this.primaryPersonalCountryISO = response.data.user.primaryISOCode;

                      let primaryTemp = JSON.parse(
                        JSON.stringify(response.data.user.primaryNumber)
                      );
                      setTimeout(() => {
                        let dataTemp = primaryTemp.toString().split(' ');

                        response.data.user.primaryNumber = dataTemp[1];
                        this.patientDetails = JSON.parse(JSON.stringify(response.data));
                      }, 0);
                      if (response.data.user.secondaryNumber) {
                        response.data.user.secondaryNumber =
                          response.data.user.secondaryNumber.trim();
                        this.secondaryPersonalCountryISO =
                          response.data.user.secondaryISOCode;
                        let secondaryTemp = JSON.parse(
                          JSON.stringify(response.data.user.secondaryNumber)
                        );
                        setTimeout(() => {
                          let dataTemp = secondaryTemp.toString().split(' ');

                          response.data.user.secondaryNumber = dataTemp[1];
                          this.patientDetails = JSON.parse(
                            JSON.stringify(response.data)
                          );
                        }, 0);
                      }
                      if (
                        response.data.originalPartnerDetails &&
                        response.data.originalPartnerDetails.user &&
                        response.data.originalPartnerDetails.user.primaryNumber
                      ) {
                        response.data.originalPartnerDetails.user.primaryNumber =
                          response.data.originalPartnerDetails.user.primaryNumber.trim();
                        this.primaryPartnerCountryISO =
                          response.data.originalPartnerDetails.user.primaryISOCode;




                        let primaryTemp = JSON.parse(
                          JSON.stringify(
                            response.data.originalPartnerDetails.user.primaryNumber
                          )
                        );
                        setTimeout(() => {
                          let dataTemp = primaryTemp.toString().split(' ');

                          response.data.originalPartnerDetails.user.primaryNumber =
                            dataTemp[1];

                          this.patientDetails = JSON.parse(
                            JSON.stringify(response.data)
                          );
                        }, 0);
                      }

                    } else {
                      return;
                    }
                  }
                  ,
                  (error) => {
                    this.isLoading = false;
                    this.toastr.error(
                      error.error ? error.error.message : error.message
                    );
                  }
                );
            }

            if (!this.hasPartnerDetails && this.requiredPartnerDetails) {
              this.guestUser = true;
            }

            if (this.requiredPartnerDetails) {
              this.patientDetails.user.maritalStatus = 'married';
              this.maritalStatusStr = true;
            }
            if (
              response.data.paymentStatus == 'PAY_NOW' ||
              response.data.paymentStatus == 'PENDING'
            ) {
              this.forPayBtn = true;
            } else {
              this.forPayBtn = false;
            }
            this.name = response.data.name;
            this.slotShift = response.data.slotShift;
            this.referredBy = response.data.referredBy;
            this.primaryNumber = response.data.primaryNumber;
            let primaryTemp = JSON.parse(
              JSON.stringify(response.data.primaryNumber)
            );
            setTimeout(() => {
              let dataTemp = primaryTemp.toString().split(' ');

              this.patientDetails.user.primaryNumber = dataTemp[1];
            }, 0);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.showBtn = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }

  yes() {
    $('#paymentModal').modal('hide');

    if (this.guestUser) {
      if (
        this.patientDetails.user.maritalStatus == 'single' ||
        !this.requiredPartnerDetails
      ) {
        this.forPersonalSubmit = true;
        if (
          this.patientDetails.user.firstName &&
          this.patientDetails.user.lastName &&
          this.patientDetails.user.primaryNumber
        ) {
          this.forPersonalSubmit = false;
          let data: any = JSON.parse(JSON.stringify(this.patientDetails));
          if (data && data.user && data.user.primaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.primaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.primaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.primaryNumber = temp;
          }
          if (data && data.user && data.user.secondaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.secondaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.secondaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.secondaryNumber = temp;
          }
          delete data.user.branchDeptVersionDto;
          data.originalPartnerDetails = null;
          this.isLoading = true;
          this.apiService
            .getService(
              this.apiService.apiUrls.getGuestUserDetails +
              '/' +
              this.globalPatientId +
              '/sequenceId/' +
              this.sequenceId
            )
            .subscribe(
              (response: any) => {
                if (response && response.statusCode === 200) {
                  let paymentFlag = response.data.requiredPayment;

                  this.isLoading = true;
                  this.apiService
                    .postService(
                      this.apiService.apiUrls.saveGuestUserPatient +
                      '/patientId/' +
                      this.globalPatientId +
                      '/sequenceId/' +
                      this.sequenceId,
                      data
                    )
                    .subscribe(
                      (response: any) => {
                        if (response && response.statusCode === 200) {
                          if (paymentFlag) {
                            this.getOrders();
                          } else {
                            this.toastr.success(response.message);
                          }
                        }
                        this.isLoading = false;
                      },
                      (error) => {
                        this.isLoading = false;
                        $('#paymentModal').modal('show');
                        // this.showBtn = false;
                        this.toastr.error(
                          error.error
                            ? error.error.message
                              ? error.error.message
                              : 'Something went wrong from server side. Please try again after sometime'
                            : error.message
                        );
                      }
                    );
                }
                this.isLoading = false;
              },
              (error) => {
                this.isLoading = false;
                $('#paymentModal').modal('hide');
                // this.showBtn = false;
                this.toastr.error(
                  error.error
                    ? error.error.message
                      ? error.error.message
                      : 'Something went wrong from server side. Please try again after sometime'
                    : error.message
                );
              }
            );
        } else {
          this.toastr.error('Please enter mandatory fields');
        }
      } else {
        this.forPersonalSubmit = true;
        this.forPartnerSubmit = true;
        if (
          this.patientDetails.user.firstName &&
          this.patientDetails.user.lastName &&
          this.patientDetails.user.primaryNumber &&
          this.patientDetails.originalPartnerDetails.user.firstName &&
          this.patientDetails.originalPartnerDetails.user.lastName &&
          this.patientDetails.originalPartnerDetails.user.primaryNumber
        ) {
          let data: any = JSON.parse(JSON.stringify(this.patientDetails));
          if (data && data.user && data.user.primaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.primaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.primaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.primaryNumber = temp;
          }
          if (data && data.user && data.user.secondaryNumber) {
            let temp = JSON.parse(JSON.stringify(data.user.secondaryNumber));
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.user.secondaryISOCode = temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.user.secondaryNumber = temp;
          }
          if (
            data &&
            data.originalPartnerDetails &&
            data.originalPartnerDetails.user.primaryNumber
          ) {
            let temp = JSON.parse(
              JSON.stringify(data.originalPartnerDetails.user.primaryNumber)
            );
            let code = temp.dialCode;
            let numberFlag: string = temp.number.trim();
            numberFlag = numberFlag.replace(/\s/g, '');
            data.originalPartnerDetails.user.isocode =
              temp.countryCode.toLowerCase();
            temp = code + ' ' + numberFlag;
            data.originalPartnerDetails.user.primaryNumber = temp;
          }
          delete data.user.branchDeptVersionDto;
          this.isLoading = true;
          this.apiService
            .getService(
              this.apiService.apiUrls.getGuestUserDetails +
              '/' +
              this.globalPatientId +
              '/sequenceId/' +
              this.sequenceId
            )
            .subscribe(
              (response: any) => {
                if (response && response.statusCode === 200) {
                  let paymentFlag = response.data.requiredPayment;
                  this.isLoading = true;
                  this.apiService
                    .postService(
                      this.apiService.apiUrls.saveGuestUserPatient +
                      '/patientId/' +
                      this.globalPatientId +
                      '/sequenceId/' +
                      this.sequenceId,
                      data
                    )
                    .subscribe(
                      (response: any) => {
                        if (response && response.statusCode === 200) {
                          if (paymentFlag) {
                            this.getOrders();
                          } else {
                            this.toastr.success(response.message);
                          }
                        }
                        this.isLoading = false;
                      },
                      (error) => {
                        this.isLoading = false;
                        $('#paymentModal').modal('hide');
                        // this.showBtn = false;
                        this.toastr.error(
                          error.error
                            ? error.error.message
                              ? error.error.message
                              : 'Something went wrong from server side. Please try again after sometime'
                            : error.message
                        );
                      }
                    );
                }
                this.isLoading = false;
              },
              (error) => {
                this.isLoading = false;
                $('#paymentModal').modal('hide');
                // this.showBtn = false;
                this.toastr.error(
                  error.error
                    ? error.error.message
                      ? error.error.message
                      : 'Something went wrong from server side. Please try again after sometime'
                    : error.message
                );
              }
            );
        }
      }
    } else {
      this.isLoading = true;
      this.apiService
        .getService(
          this.apiService.apiUrls.getGuestUserDetails +
          '/' +
          this.globalPatientId +
          '/sequenceId/' +
          this.sequenceId
        )
        .subscribe(
          (response: any) => {
            if (response && response.statusCode === 200) {
              let paymentFlag = response.data.requiredPayment;
              if (paymentFlag) {
                this.getOrders();
              } else {
                this.toastr.success(response.message);
              }
            }
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            // this.showBtn = false;
            this.toastr.error(
              error.error
                ? error.error.message
                  ? error.error.message
                  : 'Something went wrong from server side. Please try again after sometime'
                : error.message
            );
          }
        );
    }
  }

  getOrders() {
    this.isLoading = true;
    this.paymentOrder.paymentType = 'ONLINE';
    this.paymentOrder.appointmentId = this.appointmentId;

    this.paymentOrder.amount = this.amount ? this.amount * 100 : 0;
    this.paymentOrder.appointmentPaymentType = 'PayNow';
    this.paymentOrder.slotId = this.slotId;
    this.apiService
      .postService(
        this.apiService.apiUrls.ordersForGuestUser +
        '/patientId/' +
        this.globalPatientId +
        '/sequence/' +
        this.sequenceId,
        this.paymentOrder
      )
      .subscribe(
        (response: any) => {
          if (response && response.statusCode == 200) {
            this.options.amount = this.amount ? this.amount * 100 : 0;
            this.options.name = 'Hegde Hospitals';
            this.options.description = 'Appointment Fee';
            this.options.image = '/assets/img/new-hegde-logo.png';
            this.options.order_id = response.data.id;
            this.options.key = response.data.keyId;
            this.options.prefill.name =
              this.patientDetails.user.firstName +
              ' ' +
              this.patientDetails.user.lastName;
            this.options.notes.address = this.patientDetails.user.addressDto
              ? this.patientDetails.user.addressDto.addressLine1 +
              ' ' +
              this.patientDetails.user.addressDto.addressLine2 +
              ' ' +
              this.patientDetails.user.addressDto.cityName +
              ' ' +
              this.patientDetails.user.addressDto.stateName +
              ' ' +
              this.patientDetails.user.addressDto.countryName +
              ' ' +
              this.patientDetails.user.addressDto.areaName +
              ' ' +
              this.patientDetails.user.addressDto.regionName +
              ' '
              : null;
            this.initPay();
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          $('#paymentModal').modal('hide');
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }

  patientAddOne(event) {
    if (
      this.patientDetails.originalPartnerDetails &&
      this.patientDetails.originalPartnerDetails.user.addressDto
    ) {
      this.patientDetails.originalPartnerDetails.user.addressDto.addressLine1 =
        event.target.value;
    } else {
      if (this.patientDetails.originalPartnerDetails) {
        this.patientDetails.originalPartnerDetails.user.addressDto =
          new Address();
        this.patientDetails.originalPartnerDetails.user.addressDto.addressLine1 =
          event.target.value;
      }
    }
  }

  patientAddTwo(event) {
    if (
      this.patientDetails.originalPartnerDetails &&
      this.patientDetails.originalPartnerDetails.user.addressDto
    ) {
      this.patientDetails.originalPartnerDetails.user.addressDto.addressLine2 =
        event.target.value;
    } else {
      if (this.patientDetails.originalPartnerDetails) {
        this.patientDetails.originalPartnerDetails.user.addressDto =
          new Address();
        this.patientDetails.originalPartnerDetails.user.addressDto.addressLine2 =
          event.target.value;
      }
    }
  }

  patientPincode(event) {
    if (
      this.patientDetails.originalPartnerDetails &&
      this.patientDetails.originalPartnerDetails.user.addressDto
    ) {
      this.patientDetails.originalPartnerDetails.user.addressDto.pincode =
        event.target.value;
    } else {
      if (this.patientDetails.originalPartnerDetails) {
        this.patientDetails.originalPartnerDetails.user.addressDto =
          new Address();
        this.patientDetails.originalPartnerDetails.user.addressDto.pincode =
          event.target.value;
      }
    }
  }

  getAmount() {
    return this.amount ? this.commonService.numberWithCommas(this.amount) : 0;
  }

  confirm() {
    this.isLoading = true;
    this.apiService
      .getService(
        this.apiService.apiUrls.getGuestUserDetails +
        '/' +
        this.globalPatientId +
        '/sequenceId/' +
        this.sequenceId
      )
      .subscribe(
        (response: any) => {
          if (response && response.statusCode == 200) {
            let data = {
              appointmentId: response.data.appointmentId,
              paymentType: response.data.paymentStatus,
              slotId: response.data.slotId,
            };

            this.apiService
              .postService(this.apiService.apiUrls.confirmationCallCenter, data)
              .subscribe(
                (response: any) => {
                  if (response && response.statusCode == 200) {
                    this.toastr.success(response.message);
                    this.showBtn = false;
                  }
                  this.isLoading = false;
                },
                (error) => {
                  this.isLoading = false;
                  this.toastr.error(
                    error.error
                      ? error.error.message
                        ? error.error.message
                        : 'Something went wrong from server side. Please try again after sometime'
                      : error.message
                  );
                }
              );
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }
  back() {
    this.apiService
      .getService(
        this.apiService.apiUrls.redirect +
        '/' +
        this.globalPatientId +
        '/sequenceId/' +
        this.sequenceId
      )
      .subscribe((response: any) => {
        this.router.navigateByUrl('/widget');
      });
  }

  getSalutationList() {
    this.apiService
      .postService(this.apiService.apiUrls.getSalutationList, this.pagination)
      .subscribe(
        (response: any) => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length
          ) {
            this.salutationsList = response.data.content;
          } else {
            this.salutationsList = [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            error.error
              ? error.error.message
                ? error.error.message
                : 'Something went wrong from server side. Please try again after sometime'
              : error.message
          );
        }
      );
  }

  radioSelected(value) {
    if (value == 'male' && this.maritalStatusStr == true) {
      this.patientDetails.originalPartnerDetails.user.gender = 'female';
    } else if (value == 'female' && this.maritalStatusStr == true) {
      this.patientDetails.originalPartnerDetails.user.gender = 'male';
    }
  }
}
