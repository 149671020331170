import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private render: Renderer2, private elRef: ElementRef) { }
  
  @HostListener('keypress', ['$event']) keyEvent(event) {

    const charCode = (event.which) ? event.which : event.keyCode;
    
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
